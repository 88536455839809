import { Select } from "antd";
import tags from "../utils/library";
import { useEffect, useState } from "react";

const { Option, OptGroup } = Select;

const libraryConvert = (input) => {
  const output = input.reduce((acc, curr) => {
    const { name, category, id } = curr;
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push({ name, id });
    return acc;
  }, {});

  console.log(output);
  return Object.entries(output);
};

export default ({ cloudUtils, selected, setSelected, style = {} }) => {
  const [options, setOptions] = useState();
  const [tags, setTags] = useState();

  useEffect(() => {
    cloudUtils.getAllDocuments("library", setTags);
  }, []);

  useEffect(() => {
    if (tags) {
      setOptions(
        <Select
          style={{ width: "100%", ...style }}
          value={selected}
          onChange={setSelected}
        >
          {libraryConvert(tags).map(([category, values]) => (
            <OptGroup label={category}>
              {values.map(({ name, id }) => (
                <Option value={id}>{name}</Option>
              ))}
            </OptGroup>
          ))}
        </Select>
      );
    }
  }, [tags, selected]);

  return options ? options : <></>;
};
