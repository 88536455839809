import React from "react";

interface GuideTextProps {
  index: number;
  className: string;
}
function GuideText(props: GuideTextProps) {
  interface details {
    title: string;
    text: React.ReactNode;
  }

  const guideDetails: details[] = [
    {
      title: "Create Profile",
      text: (
        <>
          <p className="text-xl text-SocoWhite mb-5">
            Create an account{" "}
            <a href="/login" target="_blank">
              here
            </a>
            . Follow the onboarding process to add the key information needed
            when singing new Social Contracts.
          </p>
          <p className="text-xl text-SocoWhite">
            Explore your settings to add billing and booking details. Fill out
            your SoCo homepage with links, socials and even your portfolio!
          </p>
        </>
      ),
    },
    {
      title: "Add Your Services",
      text: (
        <>
          <p className="text-xl text-SocoWhite mb-5">
            Add services to your profile to showcase your skillset to your
            future customers. Use industry standard pricing presets, or create
            your own from scratch.
          </p>
          <p className="text-xl text-SocoWhite">
            When clients visit your SoCo homepage, they can select the services
            they're interested in. As clients book your services, they will be
            prompted to fill out your intake questions and book a discovery
            appointment with you.
          </p>
        </>
      ),
    },
    {
      title: "Book Projects",
      text: (
        <>
          <p className="text-xl text-SocoWhite mb-5">
            During your appointment, you can discover your client's needs and
            build out your contract together. Your discovery agenda
            automatically created based on your services, and is designed to
            enable guided value-based pricing.
          </p>
          <p className="text-xl text-SocoWhite">
            Your Master Services Agreement, IP agreement and project order are
            automatically created and ready to sign, and your deposit is ready
            to be collected.
          </p>
        </>
      ),
    },
    {
      title: "Get Paid!",
      text: (
        <>
          <p className="text-xl text-SocoWhite mb-5">
            Your project link will be used to track the full lifecycle of the
            project. Your customer can sign, pay their deposit, and track each
            deliverable all on the platform.
          </p>
          <p className="text-xl text-SocoWhite">
            As you complete each service, your customer will be charged for the
            remaining amount, with adjustments and revisions included. Once all
            services are paid, your customer can leave a review, and your
            project's complete!
          </p>
        </>
      ),
    },
  ];

  return (
    <div className={`${props.className}`}>
      <h1 className="my-5 px-2 pl-10 capitalize text-3xl font-bold text-SocoOrangeLight">
        {guideDetails[props.index].title}
      </h1>

      <p className="text-left pl-10 my-5 pr-1">
        {guideDetails[props.index].text}
      </p>
    </div>
  );
}

export default GuideText;
