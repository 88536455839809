import { Form, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Layout from "../elements/Layout";
import { Label, SubHeader, Text } from "../customComponents";
import ClauseCard from "../cards/ClauseCard";
import BigTextInput from "../inputs/BigTextInput";

export default ({ cloudUtils }) => {
  const { profileId, clauseId } = useParams();
  console.log(clauseId);

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });

  const [clauses, setClauses] = useState([]);
  const [edit, setEdit] = useState({});
  let [active, setActive] = useState({});

  let [form] = Form.useForm();
  const [root, setRoot] = useState();
  const navigate = useNavigate();

  const goBack = () => navigate(`/${profileId}/clauses`);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("clauses", "root", clauseId, setClauses);
    cloudUtils.getDocument("clauses", clauseId, setRoot);
  }, [profileId]);

  return (
    <Layout title={root?.title} onBack={goBack} theme={profile.theme}  requiredMark={false}>
      <Form align="left" layout="vertical" form={form}>
        <Row gutter={[0, 8]}>
          {clauses.length ? (
            clauses
              .sort((a, b) => {
                if (a.order < b.order) return -1;
                if (a.order > b.order) return 1;
                return 0;
              })
              .map(({ id, title, text, help, default: included }) => {
                return (
                  <ClauseCard
                    title={title}
                    desc={
                      help ? (
                        <>
                          <Label>Summary</Label>
                          <Text>{help}</Text>
                          <br />
                          {edit[id] ? (
                            <BigTextInput
                              displayText="Clause"
                              label={id}
                              initialValue={text}
                            />
                          ) : (
                            <>
                              <Label>Clause</Label>
                              <Text>{form.getFieldValue(id) || text}</Text>
                            </>
                          )}
                        </>
                      ) : (
                        <Text>{text}</Text>
                      )
                    }
                    onClick={
                      text
                        ? () =>
                            setEdit((prev) => {
                              if (prev[id]) {
                                return cloudUtils
                                  .updateDocument(
                                    "clauses",
                                    id,
                                    {
                                      text: form.getFieldValue(id),
                                    },
                                    "UPDATE"
                                  )
                                  .then(() => {
                                    return {
                                      ...prev,
                                      [id]: !Boolean(prev[id]),
                                    };
                                  });
                              } else {
                                return { ...prev, [id]: !Boolean(prev[id]) };
                              }
                            })
                        : () => navigate(`/${profileId}/clauses/${id}`)
                    }
                    action={text ? (edit[id] ? "Set" : "Edit") : "View"}
                    key={"clause" + id}
                    setActive={(checked) =>
                      setActive((prev) => {
                        prev[id] = checked;
                        cloudUtils.updateDocument(
                          "clauses",
                          id,
                          { default: checked },
                          "UPDATE"
                        );
                        return { ...prev };
                      })
                    }
                    active={id in active ? active[id] : included}
                    profile={profile}
                  />
                );
              })
          ) : (
            <Text>
              Track your ongoing and past clauses here! Make sure to share your
              profile on your social media, your website or with our 'Handshake
              Deal' NFC stickers (email info@soco.market to learn more).
            </Text>
          )}
        </Row>
      </Form>
    </Layout>
  );
};
