import "./App.css";

import Hero from "./Sections/Hero.tsx";
import StatusBar from "./Components/StatusBar.tsx";
import Why from "./Sections/Why.tsx";
import What from "./Sections/What.tsx";
import How from "./Sections/How.tsx";
import Reason from "./Sections/Reason.tsx";
import Contact from "./Sections/Contact.tsx";
import Footer from "./Sections/Footer.tsx";

function LandingPage({ cloudUtils }) {
  return (
    <div className="bg-SocoBlack">
      <div className="flex flex-col items-center relative z-0">
        <Hero />
        <Reason />
        <What />
        <How />
        <Contact cloudUtils={cloudUtils} />
        <Why />
        <Footer />
        <StatusBar />
      </div>
    </div>
  );
}

export default LandingPage;
