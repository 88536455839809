import React from "react";
import { BiTimer } from "react-icons/bi";
import { RiSecurePaymentFill } from "react-icons/ri";
import { TbBrandCashapp } from "react-icons/tb";

interface BenefitsCardProp {
  index: number;
}
function BenefitsCard(props: BenefitsCardProp) {
  interface cardDetails {
    title: string;
    description: React.ReactNode;
    icon: React.ReactNode;
  }

  const benefitCards: cardDetails[] = [
    {
      title: "Structured Discovery",
      description: (
        <p className="text-xl m-5">
          Industry standard pricing and contracts are at your fingertips. Stop
          scanning the internet for templates that aren't right for your
          business.
        </p>
      ),
      icon: (
        <TbBrandCashapp className=" transition-all duration-1000 absolute md:relative w-auto h-2/3 md:w-1/3 md:h-2/3 text-SocoOrangeLight fill-SocoOrangeLight bottom-0 right-0 opacity-20  md:opacity-100" />
      ),
    },
    {
      title: "Streamlined Intake",
      description: (
        <p className="text-xl m-5">
          Go from lead to signed contract in minutes. No more back and forth
          emails or phone calls with SoCo managed intake and tracking.
        </p>
      ),
      icon: (
        <BiTimer className="transition-all duration-1000 absolute md:relative w-auto h-2/3 md:w-1/3 md:h-2/3 text-SocoOrangeLight fill-SocoOrangeLight bottom-0 right-0 opacity-20  md:opacity-100" />
      ),
    },
    {
      title: "Flexible Payments",
      description: (
        <>
          <p className="text-xl m-5">
            Easily enable secure card, Google & Apple pay for your clients with
            our Stripe integration. (Cryptocurrency payments coming soon!)
          </p>
        </>
      ),
      icon: (
        <RiSecurePaymentFill className="transition-all duration-1000 absolute md:relative w-auto h-2/3 md:w-1/3 md:h-2/3 text-SocoOrangeLight fill-SocoOrangeLight bottom-0 right-0 opacity-20  md:opacity-100" />
      ),
    },
  ];
  return (
    <div className="border-2 border-SocoOrangeLight rounded-lg m-5 h-auto md:h-64 w-3/4 lg:w-1/3 lg:flex-grow lg:max-w-2xl md:flex md:flex-row-reverse md:items-center md:justify-center relative">
      {benefitCards[props.index].icon}
      <div className="md:w-2/3 ">
        <h1 className="text-3xl m-5 text-SocoOrangeLight font-bold">
          {benefitCards[props.index].title}
        </h1>
        {benefitCards[props.index].description}
      </div>
    </div>
  );
}

export default BenefitsCard;
