import { Checkbox } from "antd";
import Input from "../elements/Input";
import { useEffect } from "react";

const CheckboxInput = ({
  soquote,
  initial,
  initVal,
  total,
  setTotal,
  template,
  displayText = null,
  disabled = false,
  ...rest
}) => {
  const updatePrice = (id, checked, price, feature) => {
    if (checked) {
      total[id] = { price: parseFloat(price), count: 1, template, feature };
    } else {
      total[id] = 0;
    }
    setTotal({ ...total });
  };

  useEffect(() => {
    initVal?.forEach((qid) => {
      let item = soquote.filter(([id]) => id == qid)[0];
      updatePrice(
        qid,
        item[1].packages.includes(initial),
        item[1].price,
        item[1].feature
      );
    });
  }, [initial]);

  let boxes = soquote
    .filter(([id, { count }]) => !count)
    .map(([id, { feature, price, packages }]) => (
      <>
        <Checkbox
          disabled={!packages.includes(initial) || disabled}
          onChange={(e) => updatePrice(id, e.target.checked, price, feature)}
          value={id}
          style={{ display: "inherit!important", marginBottom: 16 }}
        >
          {disabled ? feature : feature + " - $" + price}
        </Checkbox>
        <br />
      </>
    ));

  if (!boxes.length) {
    return null;
  }

  return (
    <Input displayText={displayText} {...rest} style={{ marginBottom: 0 }}>
      <Checkbox.Group>{boxes}</Checkbox.Group>
    </Input>
  );
};

export default CheckboxInput;
