import InfoCard from "../elements/InfoCard";

export default ({
  title,
  desc,
  onClick,
  subtitle,
  profile,
  action,
  setActive,
  active,
}) => {
  return (
    <InfoCard
      onClick={onClick}
      type="clause"
      status={subtitle}
      action={action}
      setActive={setActive}
      active={active}
      desc={desc}
      theme={profile.theme}
      title={title}
    />
  );
};
