import {
  pdf,
  PDFViewer,
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import font from "../fonts/Roboto/Roboto-Regular.ttf";

Font.register({ family: "Roboto", src: font });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: "Roboto",
    fontSize: 12,
  },
  section: {
    padding: 8,
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    marginLeft: 16,
    paddingBottom: 16,
  },
  subheader: {
    marginLeft: 32,
    paddingBottom: 16,
  },
  signatures: {
    flexDirection: "row",
  },
  signature: {
    flexGrow: 1,
  },
});

const Subheader = ({ section, children }) => {
  if (section) {
    return (
      <Text style={styles.subheader}>
        {section} {children}
      </Text>
    );
  } else {
    return <Text style={styles.subheader}>{children}</Text>;
  }
};

const Header = ({ section, children }) => {
  if (section) {
    return (
      <Text style={styles.header}>
        {section} {children}
      </Text>
    );
  } else {
    return <Text style={styles.header}>{children}</Text>;
  }
};

// Create Document Component
export default ({ seller, sellerSign, buyerSign, createdDate, signedDate }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.title}>
        <Text>SCHEDULE A:</Text>
        <Text>INTELLECTUAL PROPERTY PROVISIONS</Text>
      </View>
      <View style={styles.section}>
        <Text style={styles.header}>1. RIGHTS IN THE FINAL DELIVERABLES </Text>
        <Subheader section="1.1">
          Final Works. Upon completion of the Services, and expressly
          conditioned upon full payment of all fees, expenses, and costs due,
          Agency assigns to Client all of Agency’s Copyrights in and to the
          Final Works, including Trademarks, and Agency shall deliver to Client
          all Working Files related to the Final Works. Agency shall cooperate
          with Client and shall execute any additional documents reasonably
          requested by Client to evidence such assignment, and Client shall
          reimburse Agency for Agency’s reasonable time and out-of-pocket
          expenses in connection therewith.
        </Subheader>
        <Subheader section="1.2">
          Trademarks. Client shall have sole responsibility for ensuring that
          Trademarks do not infringe the rights of third parties, and Client
          shall indemnify, save and hold harmless Agency from any and all
          damages, liabilities, costs, losses or expenses arising out of any
          claim, demand, or action by a third party alleging trademark
          infringement, or arising out of Client’s failure to obtain trademark
          clearance or permissions, for use of Trademarks.
        </Subheader>
        <Subheader section="1.3">
          Client Content. Client Content, including pre-existing Trademarks,
          shall remain the sole property of Client or its respective suppliers,
          and Client or its suppliers shall be the sole owner of all trademark,
          trade secrets, patents, Copyrights, and other rights in connection
          therewith. Client hereby grants to Agency a nonexclusive,
          nontransferable license to use, reproduce, modify, display and publish
          the Client Content solely in connection with Agency’s performance of
          the Services and promotional uses of the Deliverables as authorized in
          this Agreement.
        </Subheader>
        <Subheader section="1.4">
          Third Party Materials. Intellectual property rights in Third Party
          Materials shall be owned by the respective third parties. Agency shall
          inform Client of all Third Party Materials to be procured by Agency
          that Client may need to license at Client’s own expense, and unless
          otherwise arranged by Client, Agency shall obtain a license for Client
          to use the Third Party Materials consistent with the usage rights
          granted herein. Client shall indemnify, save and hold harmless Agency
          from any and all damages, liabilities, costs, losses or expenses
          arising out of any claim, demand, or action by a third party arising
          out of Client’s failure to obtain copyright, trademark, publicity,
          privacy, defamation or other releases or permissions with respect to
          materials included in the Final Works at Client’s request.{" "}
        </Subheader>
        <Header section="2"> RIGHTS RESERVED TO AGENCY</Header>
        <Subheader section="2.1">
          Preliminary Works/Working Files. Agency retains all proprietary
          rights, including property ownership, intellectual property rights and
          Copyrights, in and to all Preliminary Works and Working Files, and
          Client shall return to Agency all Preliminary Works and Working Files
          in Client’s possession within thirty (30) days of completion of the
          Services.
        </Subheader>
        <Subheader section="2.2">
          Original Artwork. Agency retains property ownership in any original
          artwork comprising Final Works, including all rights to display or
          sell such artwork. Client shall return all original artwork to Agency
          within thirty (30) days of completion of the Services.
        </Subheader>
        <Subheader section="2.3">
          Agency Tools. Agency Tools and all intellectual property rights
          therein, including Copyrights, shall be owned solely by Agency. Agency
          hereby grants to Client a nonexclusive, nontransferable (other than
          the right to sublicense such uses to Client’s publisher, web hosting
          or Internet service providers), perpetual, worldwide license to use
          the Agency Tools solely with the Final Deliverables for the Project.
          Client may not directly or indirectly, in any form or manner,
          decompile, reverse engineer, or otherwise disassemble or modify any
          Agency Tools comprising software or technology.
        </Subheader>
      </View>
      <View style={styles.signatures}>
        <View style={styles.signature}>
          <Header>FREELANCER: </Header>
          <Subheader>
            Name: {sellerSign ? sellerSign : "___________________________"}{" "}
          </Subheader>
          <Subheader>
            Signed: {sellerSign ? sellerSign : "___________________________"}
          </Subheader>
          <Subheader>
            Date: {createdDate ? createdDate : "___________________________"}{" "}
          </Subheader>
        </View>
        <View style={styles.signature}>
          <Header>CLIENT: </Header>
          <Subheader>
            Name: {buyerSign ? buyerSign : "___________________________"}{" "}
          </Subheader>
          <Subheader>
            Signed: {buyerSign ? buyerSign : "___________________________"}{" "}
          </Subheader>
          <Subheader>
            Date: {signedDate ? signedDate : "___________________________"}{" "}
          </Subheader>
        </View>
      </View>
    </Page>
  </Document>
);
