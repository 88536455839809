import { Row } from "antd";
import React, { useEffect, useState } from "react";
import OrderCard from "../cards/OrderCard";
import { useNavigate, useParams } from "react-router-dom";
import Info from "../elements/Info";
import Layout from "../elements/Layout";
import moment from "moment";
import { Label, Text } from "../customComponents";

export default ({ cloudUtils }) => {
  const { profileId } = useParams();

  const [profile, setProfile] = useState({
    name: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    transactional: false,
    links: {},
    theme: "dark",
  });

  const [projects, setProjects] = useState([]);
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  const goBack = () => navigate(`/${profileId}`);

  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("project", "profile", profileId, setProjects);
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, [profileId]);

  return (
    <Layout title="Projects" onBack={goBack} theme={profile.theme}>
      <Row gutter={[0, 8]}>
        {projects.length ? (
          projects.map(
            ({
              id,
              name,
              email,
              discoveryStart,
              discoveryDuration,
              services,
              status,
              due,
              price,
            }) => {
              let startTime = moment(discoveryStart);
              let endTime = moment(discoveryStart).add(
                discoveryDuration,
                "minutes"
              );

              return (
                <OrderCard
                  customer={name}
                  desc={
                    <>
                      <Label>Customer Email</Label>
                      <Text> {email}</Text>
                      <br />
                      <Label>Requested Services</Label>
                      {services.map((tempid) => (
                        <Text>
                          {templates.filter(({ id }) => id == tempid)[0]?.name}
                        </Text>
                      ))}

                      {status == "LEAD" && (
                        <>
                          <br />
                          <Label>Appointment Time</Label>
                          <Text>
                            {moment(startTime).format("MMM Do YYYY, h:mm a")} to{" "}
                            {moment(endTime).format("h:mm a")}
                          </Text>
                        </>
                      )}
                    </>
                  }
                  price={price}
                  status={status}
                  onClick={() => navigate(`/${profileId}/projects/${id}`)}
                  key={"project" + id}
                  profile={profile}
                />
              );
            }
          )
        ) : (
          <Text>
            Track your ongoing and past projects here! Make sure to share your
            profile on your social media, your website or with our 'Handshake
            Deal' NFC stickers (email info@soco.market to learn more).
          </Text>
        )}
      </Row>
    </Layout>
  );
};
