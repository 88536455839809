import { Form, TimePicker } from 'antd';
import moment from 'moment';
import { Label } from '../customComponents';

const DateRangeInput = ({ displayText, label, defaultValue=[moment('9:00', 'HH:mm'), moment('17:00', 'HH:mm')], required=true, fieldSize, validators = [], ...rest }) => {

    return (
        <Form.Item
            label={<Label>{displayText}</Label>}
            name={label}
            rules={[
                {
                    required,
                    message: `Please input a ${displayText}`,
                },
                ...validators
            ]}
            // style={{alignItems: 'center'}}
        >
            
            <TimePicker.RangePicker 
                placeholder={["Opening Time", "Closing Time"]} 
                style={{borderRadius: 3}}
                minuteStep={15} 
                defaultValue={defaultValue} 
                format='HH:mm' 
            />

            {/* <DatePicker.RangePicker
                showTime={{ format: 'HH:mm' }}
                disabledDate={(date) => date < Date.now()}
                format="YYYY-MM-DD HH:mm"
                {...rest} 
            /> */}

        </Form.Item>
    )
};

export default DateRangeInput