import { Form, Input, Select, Steps, Radio } from "antd";
import {
  RightCircleOutlined,
  LeftCircleOutlined,
  ShopOutlined,
  FileZipOutlined,
  ScheduleOutlined,
  GiftOutlined,
} from "@ant-design/icons";
import { v4 as uuidv4 } from "uuid";
import Survey from "./dynamic/Survey";
import Quote from "./dynamic/Quote";
import Bundle from "./dynamic/Bundle";
import TextInput from "../inputs/TextInput";
import ToggleInput from "../inputs/ToggleInput";
import BigTextInput from "../inputs/BigTextInput";
import { useState, useEffect } from "react";
import { Divider, SubmitButton, SubHeader, Text } from "../customComponents";
import { bundleLength, timePositive, round15 } from "../utils/validators";
import Submit from "../inputs/Submit";
import toolTips from "../utils/toolTips";
import NumberInput from "../inputs/NumberInput";
import Options from "../forms/TagSelect";

import { useNavigate, useParams } from "react-router-dom";
import { Label } from "../customComponents";
import Info from "../elements/Info";

export default ({ cloudUtils, templateId, serviceId, form, old = false }) => {
  let { profileId } = useParams();
  const navigate = useNavigate();

  const bundles = Form.useWatch("bundle", form);
  const [serviceText, setServiceName] = useState();
  const [currency, setCurrency] = useState("CAD");
  const [timeValue, setTimeValue] = useState(1);
  // const [inventory, setInventory] = useState('both');
  const [timeRange, setTimeRange] = useState(false);
  const [free, setFree] = useState(false);
  const [unit, setUnit] = useState("Days");
  const [template, setTemplate] = useState(false);
  // let [fileList, setFileList] = useState(false);
  const [selected, setSelected] = useState([]);
  const [current, setCurrent] = useState(0);
  const [type, setType] = useState("digital");
  // const fileUpload = async ({ onError, onSuccess, file }) => {
  //   if (file) {
  //     let filePath = templateId + "/" + file.name;
  //     try {
  //       const data = await cloudUtils.uploadFileWithoutOriginFileObj(
  //         filePath,
  //         file
  //       );
  //       onSuccess(null, data);
  //     } catch (e) {
  //       onError(e);
  //     }
  //   }
  // };

  // const fileDelete = async (info) => {
  //   let filePath = templateId + "/" + info.name;
  //   const data = await cloudUtils.deleteFile(filePath).then(() => {
  //     message.success(`${info.name} file deleted successfully`);
  //   });
  // };

  useEffect(() => {
    if (serviceId) {
      cloudUtils.getDocument("library", serviceId, setTemplate);
    }
    cloudUtils.getDocument("template", templateId, setTemplate);
  }, []);

  useEffect(() => {
    if (template) {
      let newTemplate = { ...template };
      setServiceName(newTemplate.name);
      setSelected(newTemplate.tags || []);
      console.log(template);
      newTemplate["free"] = newTemplate.price.free;
      setCurrency(newTemplate.price.currency);
      setFree(newTemplate.free);
      if (newTemplate.time) {
        newTemplate["timeValue"] = newTemplate.time.value;
        setTimeValue(newTemplate.time.value);
        setUnit(newTemplate.time.unit);
      }

      setType(newTemplate.type || "digital");
      // setInventory(newTemplate.inventory || "both");

      newTemplate["survey"] = newTemplate.survey
        ? Object.entries(newTemplate["survey"]).map(([id, vals]) => {
            return { id, ...vals };
          })
        : [];

      newTemplate["quote"] = newTemplate.quote
        ? Object.entries(newTemplate["quote"]).map(([id, vals]) => {
            return { id, ...vals };
          })
        : [];

      newTemplate["bundle"] = newTemplate.bundle
        ? Object.entries(newTemplate["bundle"])
            .map(([id, vals]) => {
              return { id, ...vals };
            })
            .sort((a, b) => {
              if (a.base && !b.base) {
                return -1;
              } else if (!a.base && b.base) {
                return 1;
              } else {
                return 0;
              }
            })
        : [];

      form.setFieldsValue(newTemplate);
    }
  }, [template]);

  let TemplatePage = async (raw) => {
    // setLoading(true)
    let {
      file,
      timeValue,
      timeMin,
      timeMax,
      ...values
    } = raw;

    if (values.survey) {
      let surveys = {};
      values.survey.forEach((survey) => {
        let { id, question } = survey;
        surveys[id || uuidv4()] = { question, type: "text" };
      });
      values.survey = { ...surveys };
    }

    if (values.quote) {
      let quotes = {};
      values.quote.forEach((quote) => {
        let { id, feature, price, count, packages } = quote;
        quotes[id || uuidv4()] = { feature, price, count, packages };
      });
      values.quote = { ...quotes };
    }

    if (values.bundle) {
      let bundles = {};
      values.bundle.forEach((bundle) => {
        let { id, title, price, description = "", base = false } = bundle;
        bundles[id || uuidv4()] = { title, price, description, base };
      });
      values.bundle = { ...bundles };
    }

    if (!free) {
      if (!Object.keys(values.bundle).length) {
        alert("No packages have been created!");
        return;
      }
      values.price = {
        currency,
        free: free,
      };
    } else {
      values.price = {
        currency,
        free: free,
      };
    }

    if (["digital", "product"].includes(type)) {
      if (timeRange) {
        values.time = {
          unit,
          isRange: timeRange,
          min: parseInt(timeMin),
          max: parseInt(timeMax),
        };
      } else {
        values.time = {
          unit,
          isRange: timeRange,
          value: parseInt(timeValue),
        };
      }
    }

    values.profile = profileId;

    if (file) {
      values.serviceImage =
        template +
        "/" +
        new Date().getTime() +
        "." +
        file[0].name.split(".").pop();
      await cloudUtils.uploadFile(values.serviceImage, file);
    }

    let filtered = Object.fromEntries(
      Object.entries(values).filter(([k, v]) => v)
    );

    filtered.purchase = ["digital", "product"].includes(type);
    filtered.tags = selected;
    filtered.type = type;
    if (!old) {
      filtered.show = true;
    }
    // filtered.inventory = 'both'
    await cloudUtils.updateDocument(
      "template",
      templateId,
      filtered,
      old ? "UPDATE" : "MERGE"
    );

    navigate("/" + profileId);
  };

  // const selectTime = (
  //   <Select
  //     defaultValue="Days"
  //     value={unit}
  //     bordered={false}
  //     onSelect={setUnit}
  //   >
  //     <Select.Option value="Days">Days</Select.Option>
  //     <Select.Option value="Weeks">Weeks</Select.Option>
  //     <Select.Option value="Months">Months</Select.Option>
  //   </Select>
  // );

  const selectCurrency2 = (
    <Select defaultValue="CAD" value={currency} onSelect={setCurrency}>
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
    </Select>
  );

  const charLimitValidator = (displayText, textValue, limit) => {
    return {
      validator: () => {
        if (textValue && textValue.length <= limit) {
          return Promise.resolve();
        }
        return Promise.reject();
      },
      message: `${displayText} must be between 1 and ${limit} characters`,
      validateTrigger: "onSubmit",
    };
  };

  const priceType = {
    Free: (
      <center>
        <Text>Free services have payment integrations disabled</Text>
      </center>
    ),
    Package: (
      <>
        <Text style={{ marginBottom: 24 }}>
          Currency: <span style={{ marginLeft: 16 }}> {selectCurrency2} </span>
        </Text>

        <Bundle validators={[bundleLength]} />
      </>
    ),
  };

  const typeDesc = {
    consult: {
      title: "Book Virtual Meetings",
      desc: "As you book meetings, we'll adjust your availability. Update your out-of-work times in settings!",
      examples: [
        "- Professional Services",
        "- Dating Coaches",
        "- Personal Trainers",
      ],
    },
    digital: {
      title: "License your Digital Work",
      desc: "Transfer and license your digital work to customers directly through SoCo.",
      examples: ["- Graphic Design", "- Video Editing", "- Selling Beats"],
    },
    product: {
      title: "Ship Custom Products",
      desc: "If you craft custom goods to ship to your customers, this is the option for you.",
      examples: ["- Paintings", "- Craftwork", "- Reselling"],
    },
    booking: {
      title: "Real Life Appointments",
      desc: "Whether you do house calls, have a shop or visit client sites, this is the option for collecting bookings.",
      examples: ["- Barbershops & Salons", "- Photoshoots", "- Gigs"],
    },
  };

  const typeComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        What type of service are you offering? Every business is different, we
        tailor your experience to fit your needs
      </Text>
      <br />
      <Radio.Group
        style={{ width: "100%" }}
        value={type}
        buttonStyle="solid"
        onChange={(e) => setType(e.target.value)}
      >
        <Radio.Button
          style={{
            width: "50%",
            height: "fit-content",
            padding: 16,
          }}
          value="digital"
        >
          <center>
            <FileZipOutlined style={{ fontSize: 48 }} />
            <br />
            <b>Digital Services</b>
          </center>
        </Radio.Button>
        <Radio.Button
          style={{
            width: "50%",
            height: "fit-content",
            padding: 16,
          }}
          value="consult"
        >
          <center>
            <ScheduleOutlined style={{ fontSize: 48 }} />
            <br />
            <b>Online Consults</b>
          </center>
        </Radio.Button>
        <Radio.Button
          style={{
            width: "50%",
            height: "fit-content",
            padding: 16,
          }}
          value="booking"
        >
          <center>
            <ShopOutlined style={{ fontSize: 48 }} />
            <br />
            <b>In Person Bookings</b>
          </center>
        </Radio.Button>
        <Radio.Button
          style={{
            width: "50%",
            height: "fit-content",
            padding: 16,
          }}
          value="product"
        >
          <center>
            <GiftOutlined style={{ fontSize: 48 }} />
            <br />
            <b>Shipped Products</b>
          </center>
        </Radio.Button>
      </Radio.Group>

      <br />
      <br />
      <SubHeader>{typeDesc[type].title}</SubHeader>
      <Text style={{ marginTop: -4, marginBottom: 12 }}>
        {typeDesc[type].desc}
      </Text>
      <Info messages={typeDesc[type].examples} />
    </div>
  );

  const priceComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Do you want to include an hourly rate, or expense a depreciating asset?
        Use packages and addons to guide your pricing during discovery.
      </Text>
      <br />
      <ToggleInput
        displayText="Free Service?"
        label="free"
        state={free}
        setState={setFree}
      />
      <Form.Item rules={[{ required: true }]}>
        <Input.Group>{priceType[free ? "Free" : "Package"]}</Input.Group>
      </Form.Item>
      {free ? null : (
        <>
          <Divider displayText="Addons" />
          <Quote bundles={bundles} form={form} />
          {/* <Text style={{fontStyle: 'italic'}}>Addons are only applicable to your base package</Text> */}
        </>
      )}
    </div>
  );

  const finalComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        What questions do you have for your customers before they book this
        service? Your customers will be prompted for these questions before
        booking.
      </Text>
      <br />
      <Survey />
      <Submit
        big
        style={{ position: old ? "sticky" : "relative" }}
        text={old ? "Update" : "Create"}
      />
    </div>
  );

  const detailComp = (show) => (
    <div style={{ display: show ? "initial" : "none" }}>
      <Text>
        Describe your service in detail. This will be shown to your customers on
        your SoCo home page.
      </Text>
      <br />
      <TextInput
        required
        displayText="Service Name"
        label="name"
        validators={[charLimitValidator("Service Name", serviceText, 50)]}
        onChange={(e) => {
          setServiceName(e.target.value);
        }}
      />
      {/* <center style={{ margin: "0 32px 16px" }}>
        <Upload
          accept="image/*,video/*,audio/*"
          name="file"
          listType="picture"
          customRequest={fileUpload}
          onChange={handleUploadChange}
          multiple="true"
          fileList={fileList ? fileList : null}
        >
          <RegularButton icon={<UploadOutlined />}>Add Cover Art</RegularButton>
        </Upload>
      </center> */}
      <BigTextInput
        required
        displayText="Description "
        label="longDesc"
        tooltip={toolTips.details}
      />
      <label className="ant-col ant-form-item-label">
        <Label>Search Tags</Label>
      </label>
      <Options selected={selected} setSelected={setSelected} />
      <br />
      <br />
      {["digital", "product"].includes(type) ? (
        <>
          {/* <TextInput
            required={true}
            validators={[timePositive(false, 0, 0, timeValue)]}
            label="timeValue"
            tooltip={toolTips.deliveryTime}
            displayText="Delivery Estimate "
            suffix={selectTime}
            onChange={(e) => setTimeValue(e.target.value)}
          /> */}
          {/* <Select
            defaultValue="both"
            value={inventory}
            bordered={false}
            onSelect={setInventory}
          >
            <Select.Option value="inventory">Inventory Only</Select.Option>
            <Select.Option value="custom">Custom Orders</Select.Option>
            <Select.Option value="both">Both</Select.Option>
          </Select> */}
        </>
      ) : (
        <NumberInput
          displayText="Appointment Duration (Minutes)"
          label="duration"
          step={15}
          validators={[round15()]}
          min={15}
        />
      )}
    </div>
  );

  return (
    <Form
      onFinish={TemplatePage}
      onFinishFailed={() =>
        alert("Please verify all fields have been filled correctly")
      }
      form={form}
      layout="vertical"
      requiredMark={false}
      align="left"
      style={{ paddingBottom: 48 }}
    >
      <Steps
        current={current}
        labelPlacement="vertical"
        onChange={setCurrent}
        style={{ margin: "12px 0 24px" }}
        size="small"
        responsive={false}
        items={[
          // {
          //   title: "Type",
          // },
          {
            title: "Details",
          },
          {
            title: "Pricing",
          },
          {
            title: "Finalize",
          },
        ]}
      />

      {/* {typeComp(current == 0)} */}
      {detailComp(current == 0)}
      {priceComp(current == 1)}
      {finalComp(current == 2)}
      <br />
      {current < 2 && (
        <SubmitButton onClick={() => setCurrent((cur) => cur + 1)} block>
          Next
        </SubmitButton>
      )}
    </Form>
  );
};
