import { Form, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { RegularButton } from "../customComponents";
import { v4 as uuidv4 } from "uuid";
import BigTextInput from "../inputs/BigTextInput";
import { useState, useEffect } from "react";
import qs from "query-string";
import Layout from "../elements/Layout";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import Submit from "../inputs/Submit";

export default ({ cloudUtils }) => {
  let location = useLocation();

  let { profileId } = useParams();
  const [profile, setProfile] = useState({ theme: "dark" });
  const [template, setTemplate] = useState({ name: "Loading..." });
  const [priceValue, setPriceValue] = useState();
  const [uploaded, setUploaded] = useState(false);
  const [timeValue, setTimeValue] = useState();
  const [currency, setCurrency] = useState("CAD");
  const [unit, setUnit] = useState("Days");

  let { templateId } = qs.parse(location.search);
  useEffect(() => {
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocument("template", templateId, setTemplate);
  }, [profileId]);

  useEffect(() => {
    if (profile.uploaded && profile.theme == "uploaded") {
      cloudUtils
        .getDownloadURL(profile.uploaded)
        .then((src) => setUploaded(src));
    }
  }, [profile]);

  const navigate = useNavigate();
  const goBack = () => navigate(-1);
  const [loading, setLoading] = useState(false);

  let ContractForm = async (raw) => {
    setLoading(true);
    let { file, ...values } = raw;

    let contractId = uuidv4();

    values.template = templateId;
    values.status = "UNVERIFIED";
    values.price = priceValue;
    values.time = timeValue;
    values.currency = currency;
    values.unit = unit;

    if (file) {
      let fileName =
        templateId +
        "/" +
        contractId +
        "/final/" +
        "/" +
        new Date().getTime() +
        "." +
        file[0].name.split(".").pop();
      await cloudUtils.uploadFile(fileName, file);
    }

    await cloudUtils.updateDocument("contract", contractId, values);
    navigate({
      pathname: "/" + profileId + "/details/" + templateId,
    });
  };

  const selectCurrency = (
    <Select
      defaultValue="CAD"
      value={currency}
      bordered={false}
      onSelect={setCurrency}
    >
      <Select.Option value="CAD">CAD</Select.Option>
      <Select.Option value="USD">USD</Select.Option>
    </Select>
  );

  const selectTime = (
    <Select
      defaultValue="Days"
      value={unit}
      bordered={false}
      onSelect={setUnit}
    >
      <Select.Option value="Days">Days</Select.Option>
      <Select.Option value="Weeks">Weeks</Select.Option>
      <Select.Option value="Months">Months</Select.Option>
    </Select>
  );

  return (
    <Layout title={`Add Project to ${template.name}`} onBack={goBack}>
      <div align="center">
        <Form  requiredMark={false} onFinish={ContractForm} layout="vertical">
          <BigTextInput
            displayText="Provide a short description:"
            label="desc"
          />

          <Form.Item
            name="file"
            getValueFromEvent={(e) => (Array.isArray(e) ? e : e && e.fileList)}
          >
            <Upload
              customRequest={({ onSuccess }) => onSuccess("ok")}
              className="upload-list-inline"
              accept="image/*,video/*,audio/*"
            >
              <RegularButton
                type="dashed"
                shape="round"
                icon={<UploadOutlined />}
              >
                Upload
              </RegularButton>
            </Upload>
          </Form.Item>

          {/* <TextInput 
            required={false}
            validators={[pricePositive(false, 0, 0, priceValue)]} 
            label="price" 
            displayText="Price"
            suffix={selectCurrency}
            onChange={(e) => {
              setPriceValue(e.target.value)
            }}
          />
          <TextInput 
            required={false}
            validators={[timePositive(false, 0, 0, timeValue)]} 
            label="time" 
            displayText="Time"
            suffix={selectTime}
            onChange={(e) => setTimeValue(e.target.value)}
          /> */}

          <Submit text="Add Project" />
        </Form>
      </div>
    </Layout>
  );
};
