import socoLogo from "../assets/socoLogo.png";
import { AiOutlineLogin } from "react-icons/ai";
import { HiOutlineMenuAlt2 } from "react-icons/hi";
import React from "react";

function StatusBar() {
  return (
    <div className="fixed bg-SocoWhite w-4/5 h-14 mt-5 flex rounded-full justify-between items-center">
      <img src={socoLogo} alt="Soco Logo" className="h-12 mx-5" />
      <div className="hidden sm:flex">
        <a
          href="#"
          className="ml-5 mr-5 text-SocoBlack hover:text-SocoOrangeLight"
        >
          Home
        </a>
        <a
          href="#contact"
          className="ml-5 mr-5 text-SocoBlack hover:text-SocoOrangeLight"
        >
          Contact Us
        </a>
      </div>
      <div className="ml-9 mr-1 w-[48px]">
        <a
          href="#contact"
          className="text-SocoBlack hover:text-SocoOrangeLight"
        >
          <AiOutlineLogin />
        </a>
      </div>
    </div>
  );
}

export default StatusBar;
