import { useState } from "react";
import { Label } from "../customComponents";
import Autocomplete from "react-google-autocomplete";

const LocationInput = ({
  setPlace = () => {},
  setAddress,
  setTaxLocation,
  address,
  label = "Business Address",
  global = false,
}) => {
  const [chosen, setChosen] = useState(false);

  let combine = (components) => {
    console.log(components);
    let arr = components?.map(({ short_name, types }) => {
      if (types.includes("postal_code")) {
        return { postal: short_name };
      }
      if (types.includes("administrative_area_level_1")) {
        return { state: short_name };
      }
      if (types.includes("country")) {
        return { country: short_name };
      }
      return {};
    });
    let combined = Object.assign({}, ...arr);
    return combined;
  };

  return (
    <>
      <label
        className="ant-col ant-form-item-label"
        style={{ width: "100%" }}
        align="left"
      >
        <Label colon={false} className="ant-form-item-required">
          {label}
        </Label>
      </label>

      <Autocomplete
        onPlaceSelected={(pl) => {
          setPlace(pl?.place_id);
          setTaxLocation(combine(pl?.address_components || []));
          setAddress(pl?.formatted_address);
        }}
        apiKey="AIzaSyByfE_HBTdSyY2C-ruNE0VNZYFOGTK8viQ"
        options={{
          types: ["address"],
          componentRestrictions: global ? null : { country: ["us", "ca"] },
        }}
        defaultValue={address}
        className="ant-input"
        placeholder="Address from which your business operates"
        onBlur={() => {
          if (!chosen) {
            setPlace();
            setTaxLocation();
            setAddress();
          }
        }}
        onChange={() => setChosen(false)}
        style={{ borderRadius: 3, marginBottom: 22 }}
      />
    </>
  );
};

export default LocationInput;
