import { Form, Modal, Select } from "antd";
import Loader from "../../elements/Loader";
import { useEffect, useState } from "react";
import Submit from "../../inputs/Submit";
import {
  SubHeader,
  Div,
  Text,
  Label,
  BigHeader,
  Link,
  BorderDiv,
} from "../../customComponents";
import MSA from "../../documents/MSA";
import IP from "../../documents/IP";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import Scope from "../../documents/Scope";
import TextInput from "../../inputs/TextInput";
import LocationInput from "../../inputs/LocationInput";
import moment from "moment";
const { Option } = Select;

export default ({
  cloudUtils,
  project,
  pay = false,
  profile,
  admin,
  contracts,
}) => {
  const [font, setFont] = useState("second");
  const [isCompany, setCompany] = useState("solo");
  const [loading, setLoading] = useState(false);
  const [cashModal, setCashModal] = useState(false);
  const [confirmCash, setConfirmCash] = useState(false);
  const [files, setFiles] = useState([]);
  const [clauses, setClauses] = useState([]);

  let checkPayment = cloudUtils.getFunction("checkPayment");

  useEffect(() => {
    cloudUtils.getDocumentsBy("clauses", "profile", profile.id, setClauses);
    cloudUtils.getUploadedFiles(`${project.id}/agreements`, setFiles);
    if (project.payment) {
      checkPayment({ paymentIntentId: project.payment }).then(({ data }) => {
        if (data.completed) {
          cloudUtils
            .updateDocument(
              "project",
              project.id,
              {
                status: "ACTIVE",
              },
              "UPDATE"
            )
            .then(() => window.location.reload());
        }
      });
    }
  }, []);

  let [form] = Form.useForm();

  const props = {
    seller: profile.name,
    sellerSign: project.sellerSign,
    createdDate: new Date(project.createdDate).toDateString(),
    address: profile.location,
    buyer: project.name,
    buyerAddress: project.buyerAddress,
    scope: contracts,
    clauses: clauses,
  };

  console.log(props)

  const signDocs = async ({ buyer, buyerCompany }) => {
    let signedDate = new Date().toDateString();

    if (clauses.length == 0) {
      Modal.error({
        title: "Error",
        content: "Please wait as clauses are being loaded.",
      });
      return;
    }
    setLoading(true);
    props.buyer = project.name;
    props.buyerSign = buyer;
    props.font = font;
    props.buyerCompany = isCompany == "inc" ? buyerCompany : buyer;
    props.buyerAddress = project.buyerAddress;
    props.company = isCompany;
    props.signedDate = signedDate;
    props.clauses = clauses;


    await cloudUtils.uploadBlob(
      `${project.id}/agreements/MSA.pdf`,
      await pdf(<MSA {...props} />).toBlob()
    );
    await cloudUtils.uploadBlob(
      `${project.id}/agreements/IP.pdf`,
      await pdf(<IP {...props} />).toBlob()
    );
    await cloudUtils.uploadBlob(
      `${project.id}/agreements/Scope.pdf`,
      await pdf(<Scope {...props} />).toBlob()
    );

    cloudUtils
      .updateDocument(
        "project",
        project.id,
        {
          status: "DEPOSIT",
          font,
          buyer,
          company: isCompany,
          buyerCompany: buyerCompany || buyer,
          signedDate,
        },
        "UPDATE"
      )
      .then(() => window.location.reload());
  };

  let payDeposit = cloudUtils.getFunction("payDeposit");

  const checkoutFn = () => {
    setLoading(true);
    payDeposit({
      profile: profile.id,
      project: project.id,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  const PreviewDocs = () => (
    <div align="left">
      <Label>Preview Documents</Label>
      <br />

      <PDFDownloadLink
        document={<MSA {...props} />}
        fileName="Master_Service_Agreement.pdf"
      >
        {({ loading }) =>
          loading ? "Loading document..." : "Download Master Service Agreement"
        }
      </PDFDownloadLink>
      <br />

      <PDFDownloadLink document={<IP {...props} />} fileName="IP_Agreement.pdf">
        {({ loading }) =>
          loading ? "Loading document..." : "Download IP Agreement"
        }
      </PDFDownloadLink>
      <br />

      <PDFDownloadLink
        document={<Scope {...props} />}
        fileName="Project_Order.pdf"
      >
        {({ loading }) =>
          loading ? "Loading document..." : "Download Project Order"
        }
      </PDFDownloadLink>
    </div>
  );

  const ActualDocs = ({ files = [] }) => {
    return (
      <div align="left">
        <Label>Signed Documents</Label>
        <br />

        {files.map(({ name, url }) => (
          <>
            <a href={url} target="_blank">
              Download {name}
            </a>
            <br />
          </>
        ))}
      </div>
    );
  };

  const summaryView = (
    <div align="left">
      {admin ? (
        <>
          <Label>Buyer Details</Label>
          <Text>{project.name}</Text>
          <Text>
            {project.buyerCompany ? `${project.buyerCompany} - ` : null}
            {project.buyerAddress}
          </Text>
          <br />
        </>
      ) : null}

      {contracts.map(
        ({ price, template, package: tempPackage, survey, due }) => {
          return (
            <>
              <Label>
                {template.name} - {price ? `$${price}` : "Free"}
              </Label>

              <Text>Deadline: {moment(due).format("MMM Do, YYYY")}</Text>
              <br />
              {/* {Object.values(survey).map(({ question, answer }) => (
                <>
                  <Text>{question}:</Text>
                  <Text>{answer}</Text>
                  <br />
                </>
              ))} */}
            </>
          );
        }
      )}
    </div>
  );

  if (admin) {
    return (
      <>
        <Modal
          title="Cash Payment"
          centered
          open={confirmCash}
          onCancel={() => setConfirmCash(false)}
          onOk={() =>
            cloudUtils
              .updateDocument(
                "project",
                project.id,
                {
                  status: "ACTIVE",
                },
                "UPDATE"
              )
              .then(() => window.location.reload())
          }
        >
          <Text style={{ color: "#1c1e1f" }}>
            If you've received your payment in cash, click OK to set this
            deposit to paid and proceed with your contract. If you do not have
            Stripe enabled, this is your only method to accept payments.
          </Text>
        </Modal>

        <SubHeader style={{ textAlign: "left" }}>
          {pay ? "Pending Client Payment" : "Pending Client Signature"}
        </SubHeader>
        {summaryView}
        {pay ? <ActualDocs files={files} /> : <PreviewDocs />}
        {pay && admin ? (
          <BorderDiv
            onClick={() => setConfirmCash(true)}
            style={{
              padding: "8px 16px",
              minWidth: "10rem",
              margin: "32px 0",
              minWidth: "fit-content",
              width: "30%",
            }}
          >
            Confirm Cash Payment
          </BorderDiv>
        ) : null}
      </>
    );
  }

  return (
    <div>
      {loading && <Loader />}
      {summaryView}
      <Modal
        title="Cash Payment"
        centered
        open={cashModal}
        onCancel={() => setCashModal(false)}
        footer={null}
      >
        <Text style={{ color: "#1c1e1f" }}>{cashModal}</Text>
      </Modal>

      <Form
        requiredMark={false}
        align="left"
        layout="vertical"
        form={form}
        onFinish={(values) => signDocs(values).catch(console.log)}
      >
        {pay ? null : (
          <>
            <Label>Signing Entity</Label>
            <br />
            <Select value={isCompany} onChange={setCompany} style={{paddingTop: 8}}>
              <Option value="solo">Individual</Option>
              <Option value="inc">Company</Option>
            </Select>
            <br />
            <br />
            {isCompany == "inc" ? (
              <TextInput
                displayText="Legal Name of Company"
                required={true}
                label="buyerCompany"
                placeholder="Legal name or name of your registered business"
              />
            ) : null}

            <TextInput
              displayText="Signature"
              required={true}
              label="buyer"
              placeholder="Legal name for signing representative"
            />
            {/* <label className="ant-col ant-form-item-label">
              <Label>Font</Label>
            </label>
            <br />
            <Select onSelect={setFont} style={{ width: 120 }} value={font}>
              {["first", "second", "third"].map((font) => (
                <Select.Option value={font} style={{ fontFamily: "Arial" }}>
                  {form.getFieldValue("buyer")}
                </Select.Option>
              ))}
            </Select> */}
          </>
        )}

        {pay ? (
          <>
            <ActualDocs files={files} />
            {profile.onboarding ? (
              <Submit
                text="Pay Deposit"
                onClick={() =>
                  setCashModal(
                    profile.cashMessage ||
                      `If you've paid outside of the platform, please wait for ${profile.displayName} to confirm your cash payment.`
                  )
                }
              />
            ) : (
              <>
                <Submit text="Pay Deposit" onClick={() => checkoutFn()} />
                <Link
                  onClick={() =>
                    setCashModal(
                      profile.cashMessage ||
                        `If you've paid outside of the platform, please wait for ${profile.displayName} to confirm your cash payment.`
                    )
                  }
                ></Link>
              </>
            )}
          </>
        ) : (
          <>
            <Submit text="Sign Agreements" />
            <PreviewDocs />
          </>
        )}
      </Form>
    </div>
  );
};
