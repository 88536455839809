import { Form, List, Modal, Rate, Table } from "antd";
import Info from "../../elements/Info";
import Loader from "../../elements/Loader";
import { useState, useEffect } from "react";
import Submit from "../../inputs/Submit";
import {
  SubHeader,
  Div,
  Text,
  BorderDiv,
  BigHeader,
  DangerButton,
  RegularButton,
  AccentButton,
  Label,
  OptionTag,
  Link,
  BlackLabel,
} from "../../customComponents";
import { Divider } from "../../customComponents";
import ToggleInput from "../../inputs/ToggleInput";
import moment from "moment";
import BigTextInput from "../../inputs/BigTextInput";
import Appointment from "../../inputs/Appointment";
import TextInput from "../../inputs/TextInput";
import { pricePositive } from "../../utils/validators";
import { v4 as uuidv4 } from "uuid";

const applyTax = (amount, rate, include = true) =>
  amount * (1 + (include ? rate : 0));

export default ({
  cloudUtils,
  project,
  profile,
  contracts,
  admin,
  complete,
}) => {
  const [loading, setLoading] = useState(false);
  const [confirmCash, setConfirmCash] = useState(false);
  const [cashModal, setCashModal] = useState(false);
  const [service, setService] = useState(false);
  const [adjustment, setAdjustment] = useState(false);
  const [discount, setDiscount] = useState(false);
  const [revision, setRevision] = useState(false);
  let [completed, setCompleted] = useState({});
  let [day, setDay] = useState({});

  let checkPayment = cloudUtils.getFunction("checkPayment");

  useEffect(() => {
    contracts.forEach((contract) => {
      completed[contract.id] = contract.complete;
      if (contract.payment && !contract.paid) {
        checkPayment({ paymentIntentId: contract.payment }).then(({ data }) => {
          if (data.completed) {
            cloudUtils
              .updateDocument(
                "contract",
                contract.id,
                {
                  paid: true,
                },
                "UPDATE"
              )
              .then(() => window.location.reload());
          }
        });
      }
    });
    setCompleted({ ...completed });
  }, [contracts]);

  let completeContract = async (contractId, complete) => {
    return cloudUtils.updateDocument(
      "contract",
      contractId,
      {
        complete,
      },
      "UPDATE"
    );
  };

  let completeAgreement = (values) => {
    setLoading(true);
    cloudUtils
      .updateDocument(
        "project",
        project.id,
        {
          status: "COMPLETE",
          rating: values.rating || false,
          comment: values.comment || false,
        },
        "UPDATE"
      )
      .then(() => window.location.reload());
  };

  let payDeliverable = cloudUtils.getFunction("payDeliverable");

  const checkoutFn = (contract, id) => {
    setLoading(true);
    payDeliverable({
      contract,
      schedule: id,
      profile: profile.id,
      project: project.id,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  let getData = (contract) => {
    let dataSource = Object.values(contract.addons || []).map(
      ({ count, feature }) => ({
        count,
        title: feature,
      })
    );
    console.log(contract.invoice, dataSource);
    dataSource = [
      {
        count: 1,
        title: contract.template.bundle[contract.package].title,
      },
      ...dataSource,
    ];
    return dataSource;
  };

  let totalAdjustment = (contract) =>
    Object.values(contract.changeRequests || {})
      .map((a) => (a.status == "APPROVED" ? a.amount : 0))
      .reduce((a, b) => a + b, 0);

  let [form] = Form.useForm();

  let submitRevision = (contract) => {
    cloudUtils
      .updateDocument(
        "contract",
        contract.id,
        {
          revisions: contract.revisions - 1,
          changeRequests: {
            [uuidv4()]: {
              type: "REVISION",
              status: "PENDING",
              reason: form.getFieldValue(`${contract.id}.reason`),
              created: Date.now(),
            },
            ...(contract.changeRequests || {}),
          },
        },
        "UPDATE"
      )
      .then(() => window.location.reload())
      .catch((e) => {
        console.log(e);
        alert("Please ensure all fields are filled in");
      });
  };

  let submitDiscount = (contract) => {
    let discountAmount = parseFloat(
      form.getFieldValue(`${contract.id}.amount`) || 0
    );
    if (discountAmount > contract.outstanding) {
      alert(
        "Cannot discount more than the outstanding amount of this contract"
      );
      return;
    }
    const cr = {
      type: "DISCOUNT",
      status: "APPROVED",
      reason: form.getFieldValue(`${contract.id}.reason`),
      amount: -discountAmount,
      created: Date.now(),
    };
    cloudUtils
      .updateDocument(
        "contract",
        contract.id,
        {
          changeRequests: {
            [uuidv4()]: cr,
            ...(contract.changeRequests || {}),
          },
        },
        "UPDATE"
      )
      .then(() => window.location.reload())
      .catch((e) => {
        console.log(e);
        alert("Please ensure all fields are filled in");
      });
  };

  let submitAdjustment = (contract) => {
    const cr = {
      type: "ADJUSTMENT",
      status: "PENDING",
      reason: form.getFieldValue(`${contract.id}.reason`),
      amount: parseFloat(form.getFieldValue(`${contract.id}.amount`) || 0),
      created: Date.now(),
    };
    let due = day[contract.template.id];
    if (due) {
      cr.due = due.valueOf();
    }
    cloudUtils
      .updateDocument(
        "contract",
        contract.id,
        {
          changeRequests: {
            [uuidv4()]: cr,
            ...(contract.changeRequests || {}),
          },
        },
        "UPDATE"
      )
      .then(() => window.location.reload())
      .catch((e) => {
        console.log(e);
        alert("Please ensure all fields are filled in");
      });
  };

  let revisionComp = (contract) => (
    <Modal
      title="Request Revision"
      centered
      open={revision}
      onOk={() => submitRevision(contract)}
      onCancel={() => setRevision(false)}
    >
      <BigTextInput
        black={true}
        displayText="Change Request"
        required
        label={`${contract.id}.reason`}
      />
    </Modal>
  );

  let adjustmentComp = (contract) => {
    return (
      <Modal
        title="Request Adjustment"
        centered
        open={adjustment}
        onOk={() => submitAdjustment(contract)}
        onCancel={() => setAdjustment(false)}
      >
        <BlackLabel style={{ color: "#1c1e1f" }}>Adjustment Date</BlackLabel>
        <Appointment
          day={day[contract.template.id] || moment("9:00", "HH:mm")}
          setInvalid={() => {}}
          setDay={setDay}
          template={contract.template.id}
          duration={profile.duration || 60}
          openHours={profile.openHours}
          busy={profile.busy}
          closedDays={profile.closedDays}
          showTime={false}
        />
        <br />
        <TextInput
          black={true}
          required={true}
          validators={[pricePositive(false, 0, 0)]}
          label={`${contract.id}.amount`}
          displayText="Adjustment Amount"
          price
        />
        <BlackLabel>Description</BlackLabel>
        <BigTextInput required label={`${contract.id}.reason`} />
      </Modal>
    );
  };

  let discountComp = (contract) => {
    return (
      <Modal
        title="Provide Discount"
        centered
        open={discount}
        onOk={() => submitDiscount(contract)}
        onCancel={() => setDiscount(false)}
      >
        <TextInput
          required={true}
          black={true}
          validators={[pricePositive(false, 0, 0)]}
          label={`${contract.id}.amount`}
          displayText="Discount Amount"
          price
        />
        <BlackLabel>Description</BlackLabel>
        <BigTextInput required label={`${contract.id}.reason`} />
      </Modal>
    );
  };

  const ScheduleItem = ({ contract, paid, id }) => {
    if (paid) {
      return <Text>Payment Received</Text>;
    }
    if (profile.onboarding) {
      return (
        <Submit
          text="Pay for Deliverable"
          onClick={() =>
            setCashModal(
              profile.cashMessage ||
                `If you've paid outside of the platform, please wait for ${profile.displayName} to confirm your cash payment.`
            )
          }
        />
      );
    }

    return (
      <>
        <Submit
          text="Pay for Deliverable"
          onClick={() => checkoutFn(contract, id)}
        />
        <Link
          onClick={() =>
            setCashModal(
              profile.cashMessage ||
                `If you've paid outside of the platform, please wait for ${profile.displayName} to confirm your cash payment.`
            )
          }
        ></Link>
      </>
    );
  };

  return (
    <div>
      {loading && <Loader />}
      <Modal
        title="Cash Payment"
        centered
        open={cashModal}
        onCancel={() => setCashModal(false)}
        footer={null}
      >
        <Text style={{ color: "#1c1e1f" }}>{cashModal}</Text>
      </Modal>

      {contracts.map((contract) => {
        return (
          <OptionTag
            big
            selected={contract.id == service}
            onClick={() => {
              setService(contract.id);
            }}
          >
            {contract.template.name}
          </OptionTag>
        );
      })}
      <Form
        requiredMark={false}
        layout="vertical"
        form={form}
        align="left"
        onFinish={completeAgreement}
      >
        {contracts.map((contract) => {
          if (!service) {
            setService(contract.id);
          }
          let daysUntil = moment(contract.due).diff(moment(), "days");

          return (
            <div
              style={{ display: contract.id == service ? "initial" : "none" }}
            >
              <Modal
                title="Cash Payment"
                centered
                open={confirmCash}
                onCancel={() => setConfirmCash(false)}
                onOk={() =>
                  cloudUtils
                    .updateDocument(
                      "contract",
                      contract.id,
                      {
                        paid: true,
                      },
                      "UPDATE"
                    )
                    .then(() => window.location.reload())
                }
              >
                <Text style={{ color: "#1c1e1f" }}>
                  If you've received your payment in cash, click OK to confirm
                  the full payment for this deliverable. If you do not have
                  Stripe enabled, this is your only method to accept payments.
                </Text>
              </Modal>
              {revisionComp(contract)}
              {adjustmentComp(contract)}
              {discountComp(contract)}
              <br />{" "}
              <div style={{ display: "flex" }}>
                <b>Invoice Number:</b>
                <p style={{ paddingLeft: 8 }}>{contract.invoice}</p>
              </div>
              <br />
              <Text break>{contract.template.longDesc}</Text>
              {contract.template.price.free ? null : (
                <>
                  <br />

                  <Table
                    columns={[
                      { title: "Item", dataIndex: "title" },
                      { title: "Count", dataIndex: "count" },
                      { title: "Price", dataIndex: "price" },
                    ]}
                    dataSource={getData(contract)}
                    pagination={false}
                    bordered
                    summary={() => (
                      <>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            Price
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {`$${applyTax(contract.price, contract.tax).toFixed(
                              2
                            )}`}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            Deposit Paid
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {`$${applyTax(
                              contract.price -
                                (contract.outstanding -
                                  totalAdjustment(contract)),
                              contract.tax
                            ).toFixed(2)}`}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            Adjustments
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {`$${applyTax(
                              totalAdjustment(contract),
                              contract.tax
                            ).toFixed(2)}`}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                        <Table.Summary.Row>
                          <Table.Summary.Cell index={0} colSpan={2}>
                            {contract.paid ? "Paid" : "Due"}
                          </Table.Summary.Cell>
                          <Table.Summary.Cell index={1}>
                            {`$${applyTax(
                              contract.outstanding,
                              contract.tax
                            ).toFixed(2)}`}
                          </Table.Summary.Cell>
                        </Table.Summary.Row>
                      </>
                    )}
                  />
                </>
              )}
              <br />
              {admin ? (
                <>
                  {contract.notes && (
                    <>
                      <Label>Notes:</Label>
                      <Text>{contract.notes}</Text>
                      <br />
                    </>
                  )}
                  {complete ? null : (
                    <ToggleInput
                      formless
                      displayText="
                    Have you completed this service?"
                      label={`${contract.id}.complete`}
                      state={completed[contract.id] === true}
                      setState={async (cur) => {
                        setCompleted((comp) => {
                          comp[contract.id] = cur;
                          return { ...comp };
                        });
                        await completeContract(contract.id, cur);
                      }}
                    />
                  )}
                </>
              ) : null}
              <Label>Payment Schedule</Label>
              {Object.entries(contract.schedule).map(
                ([id, { date, price, paid }]) => {
                  return (
                    <>
                      <Text>
                        <span style={{ fontWeight: "bold", marginRight: 8 }}>
                          Due Date:
                        </span>
                        {moment(date).format("MMM Do, YYYY")}
                      </Text>
                      <Text>
                        <span style={{ fontWeight: "bold", marginRight: 8 }}>
                          Amount:
                        </span>
                        ${price}
                      </Text>
                      {admin ? null : (
                        <ScheduleItem
                          contract={contract.id}
                          paid={paid}
                          id={id}
                        />
                      )}
                      <br />
                    </>
                  );
                }
              )}
              {complete ? (
                <div align="center">
                  <SubHeader>Project Complete</SubHeader>
                </div>
              ) : completed[contract.id] ? (
                contract.paid ? (
                  <div align="center">
                    <SubHeader>This deliverable has been paid for</SubHeader>
                  </div>
                ) : (
                  <div align="center">
                    <SubHeader>This deliverable is complete</SubHeader>
                    {admin && !contract.template.price.free ? (
                      <BorderDiv
                        onClick={() => setConfirmCash(true)}
                        style={{
                          padding: "8px 16px",
                          minWidth: "10rem",
                          margin: "16px 0",
                          minWidth: "fit-content",
                          width: "30%",
                        }}
                      >
                        Confirm Cash Payment
                      </BorderDiv>
                    ) : null}
                  </div>
                )
              ) : admin ? (
                <>
                  <Text>
                    The due date for this deliverable is{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {moment(contract.due).format("MMM Do, YYYY")}
                    </span>
                    .{" "}
                    {daysUntil > 0 ? (
                      <span>
                        You have {daysUntil} days to complete this deliverable.{" "}
                      </span>
                    ) : (
                      <span style={{ fontWeight: "bold" }}>
                        You are {daysUntil} days past due on this deliverable.{" "}
                      </span>
                    )}
                  </Text>
                  <br />
                  {contract.template.price.free ? null : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          "flex-wrap": "wrap",
                          "justify-content": "flex-start",
                          "row-gap": 16,
                        }}
                      >
                        <AccentButton
                          onClick={() => setAdjustment(true)}
                          style={{ marginRight: 24 }}
                        >
                          Add Adjustment
                        </AccentButton>
                        <DangerButton onClick={() => setDiscount(true)}>
                          Offer Discount
                        </DangerButton>
                      </div>
                      <br />
                    </>
                  )}
                </>
              ) : (
                <>
                  <Text>
                    The due date for this deliverable is{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {moment(contract.due).format("MMM Do, YYYY")}
                    </span>
                    .{" "}
                    {daysUntil > 0 ? (
                      <span>Expect task completion in {daysUntil} days. </span>
                    ) : (
                      <span style={{ fontWeight: "bold" }}>
                        This deliverable is {daysUntil} days past due.{" "}
                      </span>
                    )}
                  </Text>
                  <br />
                  <DangerButton
                    disabled={!contract.revisions}
                    onClick={() => setRevision(true)}
                  >
                    Request Revision ({contract.revisions || 0} left)
                  </DangerButton>
                  <br />
                  <br />
                </>
              )}
              {contract.changeRequests && (
                <>
                  <br />
                  <SubHeader>Change Requests</SubHeader>
                </>
              )}
              {Object.entries(contract.changeRequests || {})
                .sort((a, b) => {
                  if (a[1].created < b[1].created) return -1;
                  if (a[1].created > b[1].created) return 1;
                  return 0;
                })
                .map(([id, { status, amount, reason, type, created, due }]) => {
                  return type == "ADJUSTMENT" ? (
                    <BorderDiv
                      style={{
                        padding: "16px",
                        minWidth: "10rem",
                        "border-radius": "5px",
                        marginBottom: "32px",
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>
                        {type} {amount ? `- $${amount}` : null}
                      </Text>
                      <Text style={{ fontStyle: "italic" }}>
                        Requested:{" "}
                        {moment(created).format("MMM Do, YYYY (h:mm a)")}
                      </Text>
                      <br />

                      <Text>{reason}</Text>
                      <Text>
                        Updated Due Date:{" "}
                        {moment(due).format("MMM Do, YYYY (h:mm a)")}
                      </Text>

                      {admin ? (
                        <Text>{status}</Text>
                      ) : status == "PENDING" ? (
                        <div align="right">
                          <AccentButton
                            onClick={() => {
                              setLoading(true);
                              let changeRequests = {
                                ...contract.changeRequests,
                              };
                              changeRequests[id].status = "APPROVED";
                              changeRequests[id].approved = Date.now();
                              cloudUtils
                                .updateDocument(
                                  "contract",
                                  contract.id,
                                  {
                                    outstanding:
                                      contract.outstanding + (amount || 0),
                                    due,
                                    changeRequests,
                                  },
                                  "UPDATE"
                                )
                                .then(() => window.location.reload());
                            }}
                          >
                            Approve
                          </AccentButton>
                          <DangerButton
                            style={{ marginLeft: 16, marginTop: 16 }}
                            onClick={() => {
                              setLoading(true);
                              let changeRequests = {
                                ...contract.changeRequests,
                              };
                              changeRequests[id].status = "DECLINED";
                              changeRequests[id].declined = Date.now();
                              cloudUtils
                                .updateDocument(
                                  "contract",
                                  contract.id,
                                  { changeRequests },
                                  "UPDATE"
                                )
                                .then(() => window.location.reload());
                            }}
                          >
                            Decline
                          </DangerButton>
                        </div>
                      ) : (
                        <Text>{status}</Text>
                      )}
                    </BorderDiv>
                  ) : (
                    <BorderDiv
                      style={{
                        padding: "16px",
                        minWidth: "10rem",
                        "border-radius": "5px",
                        marginBottom: "32px",
                      }}
                    >
                      <Text style={{ fontWeight: "bold" }}>{type}</Text>
                      <Text style={{ fontStyle: "italic" }}>
                        Requested:{" "}
                        {moment(created).format("MMM Do, YYYY (h:mm a)")}
                      </Text>
                      <br />

                      <Text>{reason}</Text>
                    </BorderDiv>
                  );
                })}
            </div>
          );
        })}

        {contracts.every(({ template, paid }) => paid || template.price.free) &&
          !admin &&
          !complete && (
            <div align="center">
              <br />
              <BigHeader>How did I do?</BigHeader>
              <Text>
                Leave a review letting us know how your experience was working
                with {profile.displayName}.
              </Text>
              <Form.Item name="rating">
                <Rate allowHalf />
              </Form.Item>
              <BigTextInput label="comment" />
              <Submit text="Close Contract" />
            </div>
          )}
      </Form>

      {complete ? (
        <>
          <Div
            align="center"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
          >
            <Label>{project.name}'s Rating</Label>
            <Text>{project.comment}</Text>
            <Rate allowHalf disabled defaultValue={project.rating} />
          </Div>
          <br />
        </>
      ) : null}
    </div>
  );
};
