// import logo from './logo.svg';
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import "./App.css";
import UsersPage from "./pages/UsersPage";
import MenuPage from "./pages/MenuPage";
import TemplatePage from "./pages/TemplatePage";
import ContractForm from "./forms/ContractForm";
import OfferPage from "./pages/OfferPage";
import Success from "./pages/SuccessPage";
import ServicePage from "./pages/ServicePage";
import EventDetails from "./pages/RsvpPage";
import EventPage from "./pages/EventPage";
import LoginPage from "./pages/LoginPage";
import OrderTracking from "./pages/OrderTrackingPage";
import Clauses from "./pages/ClausesPage";
import LandingPage from "./web/App";
import { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import firebaseUtils from "./utils/firebaseUtils";
import ProfilePage from "./pages/ProfilePage";
import SettingPage from "./pages/SettingPage";
import {
  useLocation,
  useNavigate,
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import OrderTrackingAuthenticationPage from "./pages/OrderTrackingAuthenticationPage";
import CapabilitiesPage from "./pages/CapabilitiesPage";
import DiscoveryPage from "./pages/DiscoveryPage";
import ProjectPage from "./pages/ProjectPage";
import ClausePage from "./pages/ClausePage";
import MSA from "./documents/MSA";
import IP from "./documents/IP";
import Scope from "./documents/Scope";

const Navigate = ({ to, replace, state }) => {
  const navigate = useNavigate();
  const navigateRef = useRef(navigate);

  useEffect(() => {
    navigateRef.current = navigate;
  }, [navigate]);

  useEffect(() => {
    navigateRef.current(to, { replace, state });
  }, [to, replace, state]);

  return null;
};

function ScrollUp() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App(firebase) {
  const [user, setUser] = useState(firebase.auth().currentUser);

  firebase.auth().onAuthStateChanged((res) => setUser(res));

  const cloudUtils = new firebaseUtils(
    firebase.firestore,
    firebase.storage,
    firebase.functions
  );
  return (
    <div
      style={{
        position: "relative",
        height: "100%",
      }}
    >
      <Helmet>
        <title>SoCo: Freelancing Platform  </title>
        <meta name="description" content={`Join our freelancers community`} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Helmet>
      <Router>
        <ScrollUp />
        <Routes>
          <Route
            path="/events"
            element={
              <EventPage
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/events/:eventId"
            element={<EventDetails cloudUtils={cloudUtils} user={user} />}
          />

          <Route path="/msa" element={<MSA />} />

          <Route path="/ip" element={<IP />} />

          <Route path="/scope" element={<Scope />} />

          <Route
            path="/search"
            element={
              <UsersPage
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/track"
            element={
              <OrderTrackingAuthenticationPage
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/login"
            element={
              <LoginPage
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/:profileId/add-template"
            element={<TemplatePage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/events"
            element={
              <ProfilePage cloudUtils={cloudUtils} user={user} type="event" />
            }
          />

          <Route
            path="/:profileId/add-contract"
            element={<ContractForm cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/profile-settings"
            element={
              <ProfilePage
                cloudUtils={cloudUtils}
                user={user}
                type="settings"
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/:profileId/services"
            element={<CapabilitiesPage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/discovery"
            element={<DiscoveryPage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/settings"
            element={
              <SettingPage
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/:profileId/projects"
            element={
              <OrderTracking
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/:profileId/clauses"
            element={
              <Clauses
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          <Route
            path="/:profileId/clauses/:clauseId"
            element={<ClausePage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/projects/:projectId"
            element={<ProjectPage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/support"
            element={
              <ProfilePage cloudUtils={cloudUtils} user={user} type="support" />
            }
          />

          <Route
            path="/:profileId/details/:templateId"
            element={<ServicePage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/projects/:projectId/conversation"
            element={
              <ProfilePage cloudUtils={cloudUtils} user={user} type="chat" />
            }
          />

          <Route
            path="/:profileId/tip"
            element={
              <ProfilePage cloudUtils={cloudUtils} user={user} type="tip" />
            }
          />

          <Route
            path="/:profileId/success"
            element={<Success cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId/contract"
            element={
              <ProfilePage
                cloudUtils={cloudUtils}
                user={user}
                type="purchase"
              />
            }
          />

          <Route
            path="/:profileId/offer/:contractId"
            element={<OfferPage cloudUtils={cloudUtils} user={user} />}
          />

          <Route
            path="/:profileId"
            element={
              <MenuPage
                cloudUtils={cloudUtils}
                user={user}
                auth={firebase.auth}
              />
            }
          />

          {/* <Route path="/" element={<Navigate to="//socialcontracts.ca" />} /> */}
          <Route path="/" element={<LandingPage cloudUtils={cloudUtils} />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;

// <Route path="/:profileId/shop" element={
//   <ShopPage cloudUtils={cloudUtils} user={user} auth={firebase.auth}/>
// }/>
