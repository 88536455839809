import { Form } from "antd";
import { useEffect } from "react";
import { Label, Radio } from "../customComponents";

const RadioInput = ({
  soquote,
  setBase,
  setInitial = () => {},
  displayText,
  label,
  template,
  initVal,
  required = true,
  validators = [],
  style,
  disabled = false,
  ...rest
}) => {
  const updatePrice = (price) => {
    setBase((prev) => {
      prev[template] = parseFloat(price);
      return { ...prev };
    });
  };

  let basePackage = soquote.filter((x) => x[1].base);
  let addPackage = soquote.filter((x) => !x[1].base);

  useEffect(() => {
    let initPackage = basePackage
    if (initVal){
      initPackage = soquote.filter((x) => x[0] == initVal)
    }
    setInitial((prev) => {
      prev[template] = initPackage[0][0];
      return { ...prev };
    });
    updatePrice(initPackage[0][1].price);
  }, []);

  const inputBoxStyle = {
    borderRadius: 3,
    marginBottom: 16,
  };

  return (
    <Form.Item
      label={displayText ? <Label>{displayText}</Label> : null}
      name={label}
      initialValue={basePackage[0][0]}
      rules={[
        {
          required,
          message: `Please select a package`,
        },
        ...validators,
      ]}
      style={style}
    >
      <Radio.Group>
        {basePackage.map(([id, { title, price, description }]) => (
          <>
            <Radio
              onChange={(e) => {
                updatePrice(price);
                setInitial((prev) => {
                  prev[template] = id;
                  return { ...prev };
                });
              }}
              disabled={disabled}
              value={id}
              style={inputBoxStyle}
              {...rest}
            >
              {disabled ? title : title + " - $" + price}
              <br />
              <span style={{ whiteSpace: "pre-wrap" }}>{description}</span>
            </Radio>
            <br />
          </>
        ))}
        {addPackage.map(([id, { title, price, description }]) => (
          <>
            <Radio
              onChange={(e) => {
                updatePrice(price);
                setInitial((prev) => {
                  prev[template] = id;
                  return { ...prev };
                });
              }}
              value={id}
              disabled={disabled}
              style={inputBoxStyle}
              {...rest}
            >
              {disabled ? title : title + " - $" + price}
              <br />
              <span style={{ whiteSpace: "pre-wrap" }}>{description}</span>
            </Radio>
            <br />
          </>
        ))}
      </Radio.Group>
    </Form.Item>
  );
  // Please input a value ${displayText && ('for ' + displayText.lowerCase())}
};

export default RadioInput;
