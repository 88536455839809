import { Calendar } from "antd";

import moment from "moment";
import { useEffect, useState } from "react";
import { OptionTag } from "../customComponents";

const appointmentFn = (momentOpen, momentClose, day, duration, busy = []) => {
  const slots = moment.duration(momentClose.diff(momentOpen)).asMinutes();
  const openDay = moment(day).set({
    hour: momentOpen.hours(),
    minute: momentOpen.minutes(),
  });

  return Array(slots / 15)
    .fill()
    .map((_, index) => moment(openDay).add(index * 15, "minutes"))
    .filter((slot) => {
      const slotEnd = moment(slot).add(duration, "minutes");

      let avail = true;
      busy.forEach(({ start, end }) => {
        if (slot.valueOf() >= start && slot.valueOf() < end) {
          avail = false;
        }
        if (slotEnd.valueOf() > start && slotEnd.valueOf() <= end) {
          avail = false;
        }
        if (slot.valueOf() < Date.now()) {
          avail = false;
        }
      });

      return avail;
    });
};

const Appointment = ({
  day,
  setDay,
  setInvalid,
  template = false,
  busy = [],
  duration,
  openHours = [],
  closedDays = [],
  showTime = true,
}) => {
  let [open, close] = openHours;
  let [newApt, resetAppointment] = useState(1);
  let [bookedOut, setBookedOut] = useState([]);
  let [appointments, setAppointments] = useState(
    appointmentFn(
      moment(open, "HH:mm"),
      moment(close, "HH:mm"),
      day,
      duration,
      busy
    )
  );
  useEffect(() => {
    let tempAppointments = appointmentFn(
      moment(open, "HH:mm"),
      moment(close, "HH:mm"),
      day,
      duration,
      busy
    );
    setAppointments(tempAppointments);
    if (showTime) {
      if (tempAppointments.length) {
        setInvalid(false);
        setDay(tempAppointments[0]);
      } else {
        setInvalid(true);
        setBookedOut((prev) => [day.format("LL"), ...prev]);
      }
    }
  }, [newApt]);

  let timeDisplay = appointments.length
    ? appointments.map((apt) => (
        <OptionTag
          selected={apt.valueOf() == day.valueOf()}
          onClick={() => {
            setDay((prev) => {
              let newVal = moment(prev);
              newVal.set({ hour: apt.hours(), minute: apt.minutes() });
              return newVal;
            });
          }}
        >
          {`${apt.hours()}:${String(apt.minutes()).padStart(2, "0")}`}
        </OptionTag>
      ))
    : "There are no more appointments for this day!";

  return (
    <div key={day.valueOf()}>
      <Calendar
        style={{ borderRadius: 3, marginBottom: 16 }}
        onChange={(val) =>
          setDay((prev) => {
            console.log(prev);
            let newVal;
            if (template) {
              newVal = prev[template]
                ? moment(prev[template])
                : moment("9:00", "HH:mm");
            } else {
              newVal = moment(prev);
            }

            newVal.set({
              date: val.date(),
              month: val.month(),
              year: val.year(),
            });
            resetAppointment((p) => p + 1);

            if (template) {
              prev[template] = newVal;
              console.log(prev);
              return { ...prev };
            } else {
              return newVal;
            }
          })
        }
        fullscreen={false}
        required={false}
        defaultValue={day}
        disabledDate={(d) => {
          return (
            closedDays.includes(d.day()) || bookedOut.includes(d.format("LL"))
          );
        }}
        validRange={[moment().subtract(86400000), moment().add(315400000000)]}
      />

      <div align="center">{showTime && timeDisplay}</div>
    </div>
  );
};

export default Appointment;
