import React from 'react'
import HeroCard from "../Components/HeroCard.tsx";

function Hero() {
  return (
    <div className="flex flex-col lg:flex-row relative justify-center items-center mt-40 lg:mt-0 h-screen md:w-4/5">
      {/* Text Araa */}
      <div className="lg:w-1/2 flex-1 text-SocoWhite flex flex-col justify-center items-center">
        <h1 className="text-5xl text-SocoWhite font-bold my-5 px-2 text-center">
          Focus on your craft
        </h1>
        <h1 className="text-2xl text-SocoWhite my-5 px-2 text-center">
          We'll take care of business. Protect yourself from bad agreements with 
          <span className="text-SocoOrangeLight font-semibold">
          {" "}Social Contracts.
          </span>
        </h1>
        <div className="my-5 px-2 w-full lg:w-1/2 flex flex-row justify-evenly">
          <button onClick={() => window.location.href='#why'} className="h-14 w-24 rounded-full bg-SocoOrangeLight text-SocoBlack font-semibold">
            Discover
          </button>
          <button onClick={() => window.location.href='#contact'} className="h-14 w-24 rounded-full border-2 border-SocoOrangeLight font-semibold">
            Join
          </button>
        </div>
      </div>
      {/* Content Area */}
      <div className="lg:w-1/2 flex-1 text-SocoWhite flex flex-col justify-center items-center">
        <h1 className="text-xl text-SocoWhite font-bold my-5 px-2 mb-0 text-center">
          Built for <span className="text-SocoOrangeLight">...</span>
        </h1>
        <HeroCard />
      </div>
    </div>
  );
}

export default Hero;
