import {
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import font from "../fonts/Roboto/Roboto-Regular.ttf";

Font.register({ family: "Roboto", src: font });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: "Roboto",
    fontSize: 12,
  },
  section: {
    padding: 8,
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    marginLeft: 16,
    textIndent: -16,
    paddingBottom: 16,
  },
  subheader: {
    marginLeft: 32,
    textIndent: -16,
    marginBottom: 16,
    maxWidth: 800,
  },
  signatures: {
    flexDirection: "row",
  },
  signature: {
    flexGrow: 1,
  },
});

const Subheader = ({ section, children }) => {
  if (section) {
    return (
      <Text style={styles.subheader}>
        {section} {children}
      </Text>
    );
  } else {
    return <Text style={styles.subheader}>{children}</Text>;
  }
};

const Header = ({ section, children }) => {
  if (section) {
    return (
      <Text style={styles.header}>
        {section} {children}
      </Text>
    );
  } else {
    return <Text style={styles.header}>{children}</Text>;
  }
};

// Create Document Component
export default ({
  seller,
  sellerSign,
  buyer,
  buyerSign,
  createdDate,
  signedDate,
  address,
  buyerCompany,
  buyerAddress,
  clauses = [],
}) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.title}>
          <Text>MASTER SERVICES AGREEMENT BETWEEN</Text>

          <Text>{buyer} (“Client”)</Text>

          <Text>AND</Text>

          <Text>{seller} (“Freelancer”)</Text>
        </View>
        <View style={styles.section}>
          <Text>
            This Master Services Agreement (“Agreement”) is entered into as of
            the date last signed below (“Effective Date”) by and between
            {" " + seller} with an address at
            {" " + address} (“Freelancer”) and {buyer},
            {buyerCompany ? " a Corporation" : " an Individual"} with an address
            at {buyerAddress + " "}
            (“Client”).{" "}
          </Text>
        </View>

        {clauses
          .filter(({ section }) => section)
          .sort((a, b) => {
            if (a.order < b.order) return -1;
            if (a.order > b.order) return 1;
            return 0;
          })
          .map(({ title, id, text: mainText }, index) => (
            <View style={styles.section}>
              <Header section={`${index + 1}`}>{title.toUpperCase()}</Header>
              {mainText ? (
                <Text style={styles.subheader}>{mainText}</Text>
              ) : null}
              {clauses
                .filter((clause) => clause.root == id)
                .map(({ text }, sub_index) => (
                  <Subheader section={`${index + 1}.${sub_index + 1}`}>
                    {text}
                  </Subheader>
                ))}
            </View>
          ))}

        <View style={styles.signatures}>
          <View style={styles.signature}>
            <Header>FREELANCER: </Header>
            <Subheader>
              Name: {sellerSign ? sellerSign : "___________________________"}{" "}
            </Subheader>
            <Subheader>
              Signed: {sellerSign ? sellerSign : "___________________________"}
            </Subheader>
            <Subheader>
              Date: {createdDate ? createdDate : "___________________________"}{" "}
            </Subheader>
          </View>
          <View style={styles.signature}>
            <Header>CLIENT: </Header>
            <Subheader>
              Name: {buyerSign ? buyerSign : "___________________________"}{" "}
            </Subheader>
            <Subheader>
              Signed: {buyerSign ? buyerSign : "___________________________"}{" "}
            </Subheader>
            <Subheader>
              Date: {signedDate ? signedDate : "___________________________"}{" "}
            </Subheader>
          </View>
        </View>
      </Page>
    </Document>
  );
};
