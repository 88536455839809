import { PageHeader, BigHeader, Div, PrimaryIcon } from "../customComponents";
import { useNavigate, useParams } from "react-router-dom";
import Footer from "./Footer";
import Loader from "./Loader";
import { ThemeProvider } from "styled-components";
import themes from "../utils/themes";
import { ArrowLeftOutlined } from "@ant-design/icons";

const flat = (hex) => {
  return {
    fontColor: "#E0E4E4",
    primary: hex,
    secondary: hex,
    accent: "#EE9C65",
    danger: "#d11a2a",
    background: "#E0E4E4",
    design: "flat",
  };
};

export default ({
  headerButtons,
  footer,
  headerBody,
  children,
  background = "#1c1e1f",
  theme = "dark",
  back = false,
  loading,
  style,
  title,
  page,
  pageType,
  type,
  admin,
  ...rest
}) => {

  let newTheme = flat("#1c1e1f");
  if (theme) {
    if (theme.startsWith("#")) {
      newTheme = flat(theme);
    } else {
      newTheme = themes[theme];
    }
  }
  return (
    <ThemeProvider theme={newTheme}>
      {page == "Menu" ? (
        <Div style={{ height: "100%", ...style }}>
          {loading && <Loader />}
          <div>
            {children}
            {footer && !admin ? <Footer /> : null}
          </div>
        </Div>
      ) : (
        <div style={{ height: "100%", backgroundColor: newTheme.background, ...style }}>
          {loading && <Loader />}
          <PageHeader
            backIcon={<PrimaryIcon icon={<ArrowLeftOutlined />} />}
            onBack={back || null}
            extra={headerButtons}
            title={<BigHeader>{title}</BigHeader>}
            {...rest}
          >
            {headerBody}
          </PageHeader>
          <Div
            style={{
              minHeight: "100vh",
              maxWidth: 816,
              padding: type == "chat" ? "68px 24px 0" : "90px 24px 24px",
            }}
          >
            {children}
            {footer ? <Footer /> : null}
          </Div>
        </div>
      )}
    </ThemeProvider>
  );
};
