import { Form } from "antd";
import { EditOutlined } from "@ant-design/icons";
import Info from "../../elements/Info";
import Loader from "../../elements/Loader";
import moment from "moment";
import { useState, useEffect } from "react";
import Submit from "../../inputs/Submit";
import Appointment from "../../inputs/Appointment";
import {
  GhostButton,
  SubHeader,
  Div,
  Text,
  BigHeader,
  Label,
} from "../../customComponents";

export default ({ cloudUtils, project, profile }) => {
  let [survey, setSurvey] = useState([]);
  const [templates, setTemplates] = useState({});

  let [invalid, setInvalid] = useState(false);
  let [day, setDay] = useState(moment(project.discoveryStart));
  const [loading, setLoading] = useState(false);
  const [editTime, editTimeBtn] = useState(false);

  useEffect(() => {
    project.services.forEach((tid) => {
      cloudUtils.getDocument("template", tid, (v) =>
        setTemplates((prev) => {
          prev[tid] = v;
          return { ...prev };
        })
      );
    });

    // Object.keys(v.survey || {}).forEach((key) => {
    //   v.survey[key].answer = project.intake[key]
    // })
    // let newSurvey = [];
    // Object.entries(project.survey || {}).forEach(([sid, val]) => {
    //   let question = profile.survey[sid];
    //   if (question && question.type == "text") {
    //     newSurvey.push("Q: " + question.question);
    //     newSurvey.push("A: " + val);
    //   }
    // });
    // setSurvey([...newSurvey]);
  }, [project]);

  let projectStartTime = moment(project.discoveryStart);
  let projectEndTime = moment(project.discoveryStart).add(
    project.discoveryDuration,
    "minutes"
  );

  let submitEstimate = (values) => {
    if (invalid) {
      alert("Please select a valid date!");
      return;
    }
    const t2 = moment(day).add(project.discoveryDuration, "minutes");
    let invoice = new Date().toISOString().slice(5, 16).replace(/[T:-]/g, "");
    invoice = `${project.id.slice(0, 4)}-${invoice}`;

    setLoading(true);
    cloudUtils
      .updateDocument(
        "project",
        project.id,
        {
          busy: [day.valueOf(), t2.valueOf()],
          invoice,
          status: "DISCOVERY",
        },
        "UPDATE"
      )
      .then(() => window.location.reload());
  };

  return (
    <Div>
      {loading && <Loader />}

      <div align="left">
        {Object.entries(templates).map(([id, { survey = {}, name }]) => {
          return (
            <Div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {" "}
              <SubHeader>{name}</SubHeader>
              {Object.entries(survey).map(([key, { question }]) => {
                return (
                  <>
                    <Label>{question}</Label>
                    <Text>{project.intake[`${id}.survey.${key}`]}</Text>
                    <br />
                  </>
                );
              })}
              <Label>Questions & Comments</Label>
              <Text break>{project.intake[`${id}.notes`]}</Text>
              <br />
            </Div>
          );
        })}

        <Div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <Label>Customer Email</Label>
          <Text>{project.email}</Text>
        </Div>
      </div>

      <br />

      <Form
        requiredMark={false}
        align="left"
        initialValues={{
          time: project.discoveryStart
            ? [projectStartTime, projectEndTime]
            : null,
        }}
        onFinish={submitEstimate}
      >
        <Label>Appointment Time</Label>
        {editTime ? (
          <Appointment
            day={day}
            setInvalid={setInvalid}
            setDay={setDay}
            duration={project.discoveryDuration}
            openHours={profile.openHours}
            busy={profile.busy}
            showTime={true}
            closedDays={profile.closedDays}
          />
        ) : (
          <Text>
            {projectStartTime.format("MMM Do, YYYY (h:mm a - ")}
            {projectEndTime.format("h:mm a)")}
            <GhostButton
              icon={<EditOutlined />}
              type="text"
              onClick={() => editTimeBtn(true)}
            />{" "}
          </Text>
        )}
        <Submit text="Confirm Appointment" />
      </Form>
    </Div>
  );
};
