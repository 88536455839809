import { Form, Select } from "antd";
import { v4 as uuidv4 } from 'uuid';

const { Option } = Select;

export default ({ bundles, label, required=true }) => {
  return (
    <Form.Item label={null} required={required} name={label} style={{ flexGrow: 1, marginBottom: 0, paddingRight: 16 }}>
      <Select mode="multiple" style={{ width: "100%" }}>
        {bundles.filter(({title}) => title).map(({ id, title }) => (
          <Option value={id} >{title}</Option>
        ))}
      </Select>
    </Form.Item>
  );
};
