import React from "react";
import BenefitsCard from "../Components/BenefitsCard.tsx";

function Why() {
  return (
    <div
      id="benefits"
      className="flex flex-col relative justify-center items-center mb-20 md:w-4/5"
    >
      <h1 className="text-5xl text-SocoWhite font-bold my-5 px-2 text-center">
        Respect your Craft
      </h1>
      <h1 className="text-2xl text-SocoWhite my-5 px-5 text-center">
        Protect your work, value your time, and get paid what you deserve.
      </h1>
      <div className="lg:h-1/2 flex-1 text-SocoWhite flex flex-row justify-center items-center flex-wrap">
        <BenefitsCard index={0} />
        <BenefitsCard index={1} />
        <BenefitsCard index={2} />
      </div>
    </div>
  );
}

export default Why;
