import { useState, useEffect } from "react";
import { Form } from 'antd';
import qs from "query-string"
import {useLocation} from "react-router-dom";
import Submit from "../inputs/Submit";
import moment from 'moment'
import { Label } from "../customComponents";

export default ({ cloudUtils, setLoading, profile }) => {
    let location = useLocation();
    let [total, setTotal] = useState(0)
    let [counts, setCounts] = useState(location.state.counts)
    let [base, setBase] = useState(0)
    let [day, setDay] = useState(moment('9:00', 'HH:mm'))
    
    let { templateId } = qs.parse(location.search)
    let [template, setTemplate] = useState({ name: 'Loading...', quote: {}, bundle: {}, time: {unit: 'Days'}})
    useEffect(() => {
        cloudUtils.getDocument("template", templateId, setTemplate)
    }, [])


    const locTotal = location.state.quote ? Object.entries(template.quote).map(([key, {price}]) => {
        return location.state.quote.includes(key) ? parseFloat(price) : 0
    }).reduce((a, b) => a + b, 0) : 0

    const packageBase = location.state.bundle ? Object.entries(template.bundle).map(([key, {price}]) => {
        return location.state.bundle == key ? parseFloat(price) : 0
    }).reduce((a, b) => a + b, 0) : 0

    useEffect(() => {
        setTotal(locTotal)
        if (template.price && !template.price.free){
            setBase(packageBase)
        }
    }, [template])

    // const navigate = useNavigate()
    // const goBack = () => navigate(`/${profileId}/details/${templateId}`)

    const submitSurvey = async (values, total, cloudUtils, template, profile) => {
        setLoading(true)
        const t2 = moment(day).add(template.duration, 'minutes')
        let { name, email, bundle, quote, time, counts } = values
        let submitOffer = cloudUtils.getFunction('submitOffer')
        //Parse code for surveys
        let survey = {}
        for (const [key, value] of Object.entries(values)) {
            let splitKeys = key.split(':')
            if(splitKeys.length > 1){
                survey[splitKeys[1]] = value
            }
        }

        const timeVal = template.purchase 
            ? moment().add(template.time.value, template.time.unit.lowerCase()).format('MMM Do, YYYY (h:mm a)')
            : day.format('MMM Do, YYYY (h:mm a)') + ' to ' + t2.format('MMM Do, YYYY (h:mm a)')

        submitOffer({
            price: total ? total : 0,
            time: timeVal,
            busy: template.purchase ? false : [day.valueOf(), t2.valueOf()],
            template: {name: template.name, id: template.id},
            customer: { name, email },
            worker: profile,
            quote: quote ? quote : [],
            bundle: bundle ? bundle : {},
            counts: counts ? counts : {},
            survey,
            profile: profile.id,
        }).then(({data}) => {
            window.location.href = data.link
        })
    }

    return (
        <Form
            layout='vertical'
            align="left"
            requiredMark={false}
            initialValues={location.state}
            onFinish={
                async (values) => await submitSurvey(
                    {counts, ...values}, 
                    base + total + Object.values(counts).reduce((a, b) => a + b, 0), 
                    cloudUtils, 
                    {id: templateId, ...template}, 
                    profile
                )
            }
        >
            
            
            {(template.price && template.price.free) ? null :
                (<div style={{
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <Label>Base Price:</Label>
                        <Label style={{ fontWeight: '600' }}>{'$' + base.toFixed(2)}</Label>
                    </div>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>                    
                        <Label>Total Price:</Label>
                        <Label style={{ fontWeight: '600' }}>{'$' + (base + total + Object.values(counts).reduce((a, b) => a + b, 0)).toFixed(2)}</Label>
                    </div>
                </div>)
            }

            <Submit text="Submit Request" />

        </Form>
    )

};
