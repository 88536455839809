import { Modal } from "antd";
import {
  BigButton,
  BlackLabel,
  Link,
  RegularButton,
  Text,
} from "../customComponents";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import moment from "moment";

const MeetCard = ({
  admin,
  buyer,
  seller,
  discovery,
  meetModal,
  showMeetModal,
}) => {
  let appointmentStart = moment(buyer.discoveryStart);
  let appointmentEnd = moment(buyer.discoveryStart).add(
    buyer.discoveryDuration,
    "minutes"
  );

  return (
    <div style={{ width: "30%", minWidth: "9rem" }}>
      <RegularButton
        block={true}
        onClick={() => showMeetModal(true)}
        style={{ minWidth: "9rem" }}
      >
        Contact Info
      </RegularButton>
      <div align="center">
        <Modal
          title="Contact Info"
          centered
          open={meetModal}
          footer={null}
          onCancel={() => showMeetModal(false)}
        >
          {admin ? (
            <>
              <BlackLabel>Customer Email</BlackLabel>
              <Text style={{ color: "#1c1e1f" }}>{buyer.email}</Text>
            </>
          ) : (
            <>
              <BlackLabel>{seller.displayName} Email</BlackLabel>
              <Text style={{ color: "#1c1e1f" }}>{seller.email}</Text>
            </>
          )}
          {seller.zoomPMI ? (
            <BigButton
              style={{ marginBottom: 8 }}
              onClick={() =>
                window.open(`//zoom.us/j/${seller.zoomPMI}`, "_blank")
              }
            >
              Join Zoom
            </BigButton>
          ) : admin ? (
            <>
              <br />
              <Text style={{ color: "#1c1e1f" }}>
                You can add Zoom meeting room information in the Booking
                sections in{" "}
                <Link href={`/${seller.id}/profile-settings`}>Settings</Link>
              </Text>
            </>
          ) : null}
          {seller.zoomPMI && seller.zoomPass && (
            <Text>Passcode: {seller.zoomPass}</Text>
          )}
          <br />
          {discovery ? (
            <>
              <BlackLabel>Discovery Appointment</BlackLabel>
              <AddToCalendarButton
                align="left"
                hideBranding={true}
                buttonStyle="date"
                trigger="click"
                listStyle="modal"
                name={`Discovery: ${admin ? buyer.name : seller.displayName}`}
                description={`The agenda for this call can be found at: soco.market/${seller.id}/projects/${buyer.id}`}
                startDate={appointmentStart.format("YYYY-MM-DD")}
                startTime={appointmentStart.format("HH:MM")}
                endDate={appointmentEnd.format("YYYY-MM-DD")}
                organizer={`${seller.displayName}|${seller.email}`}
                endTime={appointmentEnd.format("HH:MM")}
                timeZone="EST"
                options={["Apple", "Google", "Yahoo", "iCal"]}
              ></AddToCalendarButton>
            </>
          ) : null}
        </Modal>
      </div>
    </div>
  );
};

export default MeetCard;
