import { Col, Row } from "antd";
import Carousel from "re-carousel";
import IndicatorDots from "../elements/IndictorDots";
import MultiMedia from "./MultiMedia";
import DefaultServiceCover from "../resources/placeholderCover.jpg";
import { Div, InvertedDiv, Paragraph, TransDiv } from "../customComponents";


export default ({
  admin,
  media,
  title,
  desc,
  price,
  free,
  onClick,
  service = false,
  profile = false
}) => {

  let shordy =
    desc.length > 140
      ? desc.substring(0, 140).split(" ").slice(0, -1).join(" ")
      : desc;

  return (
    <>
    <div style={{height: 8, width: '100%'}} />
    <Col className={`gutter-row ${service ? 'service-card-back' : 'card-back'}`} span={24} >
      <Div className="gutter-row picture-card-inner" >
        <Div className="picture-card-cover" style={{background: 'black'}}>
          {media.length ? (
            <Carousel widgets={[IndicatorDots]}>
              {media.map(({ url, name }) => (
                <MultiMedia name={name} media={url} />
              ))}
            </Carousel>
          ) : (
            <center >
              <img
                alt="Service Cover"
                src={DefaultServiceCover}
                height="200px"
              />
            </center>
          )}
        </Div>
        <TransDiv className="picture-card-info" onClick={onClick}>
          {!profile && (
            <InvertedDiv className="middle-float">
              {free ? (
                <span >Free Booking</span>
                ) : (
                  <>
                  <span style={{fontWeight: 400}}>Starting from{" "}</span>
                  ${price}
                </>
              )}
            </InvertedDiv>
          )}
          <Row>
            <Col span={24}>
              <div className={`picture-card-title ${profile && 'gap'}`}>{title}</div>
            </Col>
            <Col span={24}></Col>
            <Paragraph
              className="picture-card-desc"
              ellipsis={{ rows: 2, expandable: false, symbol: "more" }}
            >
              {shordy}
            </Paragraph>
            {!profile && (
              <InvertedDiv align="right" style={{ 
                position: 'absolute',
                padding: '8px 16px',
                right: 0,
                bottom: 0,
                'border-radius': '5px 0px 4px 0px'
              }}>
                {admin ? "Edit" : `${service} Now`}
              </InvertedDiv>
            )}
          </Row>
        </TransDiv>
      </Div>
    </Col>
    <div style={{height: 0, width: '100%'}} />
    </>
  );
};
