import { Form, InputNumber, Modal, Input } from "antd";
import Loader from "../../elements/Loader";
import { useEffect, useState } from "react";
import Submit from "../../inputs/Submit";
import { Label, Div, Text, OptionTag, SubHeader } from "../../customComponents";
import { Divider } from "../../customComponents";
import RadioInput from "../../inputs/RadioInput";
import CheckboxInput from "../../inputs/CheckboxInput";
import ToggleInput from "../../inputs/ToggleInput";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { serverTimestamp } from "firebase/firestore";
import TextInput from "../../inputs/TextInput";
import BigTextInput from "../../inputs/BigTextInput";
import Appointment from "../../inputs/Appointment";
import LocationInput from "../../inputs/LocationInput";
import Schedule from "../../forms/dynamic/Schedule";

const { confirm } = Modal;

const applyTax = (amount, rate, include = true) =>
  (amount * (1 + (include ? rate : 0))).toFixed(2);

export default ({ cloudUtils, templates, project, profile, admin }) => {
  let [included, setIncluded] = useState({});
  const [loading, setLoading] = useState(false);
  let [total, setTotal] = useState({});
  let [values, setValues] = useState(false);
  let [counts, setCounts] = useState({});
  let [base, setBase] = useState({});
  let [sign, setSign] = useState(false);
  let [service, setService] = useState(false);
  let [loaded, setLoaded] = useState(false);
  const [taxLocation, setTaxLocation] = useState({});
  const [includeTax, setIncludeTax] = useState(true);
  const [taxRate, setTaxRate] = useState(0);
  const [address, setAddress] = useState();

  let [initial, setInitial] = useState({});
  let [day, setDay] = useState({});

  let [form] = Form.useForm();

  // attempt to remove box shadow...
  // const elements = Array.from(document.getElementsByClassName('atcb-light'));
  // if (elements.length) {
  //   let actb = elements.pop()
  //   actb.shadowRoot.querySelector('#atcb-btn-1').style.boxShadow = 'none!important';
  //   console.log(actb.shadowRoot.querySelector('#atcb-btn-1').style)
  // }

  const streamRef = cloudUtils.getChildCollectionRef(
    "project",
    project.id,
    "stream"
  );
  const query = streamRef.orderBy("time");
  const [stream] = useCollectionData(query);

  const totalSchedulePrice = (template = false) => {
    if (template) {
      return (form.getFieldValue(`${template}.schedule`) || [])
        .map(({ price }) => parseFloat(price || 0))
        .reduce((a, b) => a + b, 0);
    }
    return Object.entries(form.getFieldsValue())
      .filter(([k, v]) => k.endsWith(".schedule") && v)
      .map(([k, v]) =>
        v.map(({ price }) => parseFloat(price || 0)).reduce((a, b) => a + b, 0)
      );
  };

  let totalPrice = totalSchedulePrice();

  let projectPrice = totalPrice.reduce((a, b) => a + b, 0);

  let depositPrice = totalPrice.reduce(
    (a, b) => Math.floor(a) + Math.floor(b) / 2,
    0
  );

  const setStream = async () => {
    let schedule = Object.entries(form.getFieldsValue()).filter(([k, _]) =>
      k.endsWith(".schedule")
    );

    let values = Object.entries(form.getFieldsValue())
      .filter(([k, v]) => !(k.endsWith(".schedule") || v == undefined))
      .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});

    console.log(schedule);

    schedule.forEach(([key, value]) => {
      let newSchedule = [];
      value.forEach(({ date, price }) => {
        if (date && price) {
          newSchedule.push({
            date: date.valueOf(),
            price,
          });
        }
      });
      values[key] = newSchedule;
    });

    console.log(values);

    Object.entries(day).forEach(([key, value]) => {
      values[`${key}.due`] = value.valueOf();
    });

    if (address) {
      values["address"] = address;
      values["taxRate"] = taxRate;
    }

    if (Object.keys(values).length) {
      await streamRef.doc("state").set({
        values: values,
        time: serverTimestamp(),
      });
    }
  };

  let getTax = cloudUtils.getFunction("getTax");

  useEffect(() => {
    if (taxLocation) {
      let country = taxLocation.country || null;
      let state = taxLocation.state || null;
      if (country) {
        setLoading(true);
        getTax({ origin: profile?.taxLocation?.country, country, state }).then(
          ({ data }) => {
            setTaxRate(data.rate);
            setLoading(false);
          }
        );
      }
    }
  }, [taxLocation]);

  useEffect(() => {
    if (values) {
      submitEstimate(values);
    }
  }, [values]);

  useEffect(() => {
    setStream().catch(console.log);
  }, [day]);

  useEffect(() => {
    templates.forEach((template) => {
      if (template.quote) {
        Object.entries(template.quote)
          .filter(([id, { count }]) => count)
          .forEach(([id, { feature, price, packages }]) => {
            if (
              packages.includes(initial[template.id]) &&
              form.getFieldValue(`${template.id}.count.${id}`)
            ) {
              counts[id] = {
                price: parseFloat(price),
                count: form.getFieldValue(`${template.id}.count.${id}`),
                template: template.id,
                feature,
              };
            } else if (counts[id]) {
              delete counts[id];
            }
          });
      }
    });

    setCounts({ ...counts });
  }, [initial]);

  useEffect(() => {
    if (stream && stream[0] && !loaded) {
      Object.entries(stream[0].values).forEach(([k, v]) => {
        if (k.includes("include")) {
          setIncluded((p) => {
            p[k.substring(0, k.indexOf("."))] = v;
            return { ...p };
          });
        }
        if (k.includes("due")) {
          setDay((p) => {
            p[k.substring(0, k.indexOf("."))] = moment(v);
            return { ...p };
          });
        }
      });

      for (const key in stream[0].values) {
        if (key.endsWith(".schedule")) {
          let schedule = stream[0].values[key];
          schedule.forEach((s, i) => {
            schedule[i].date = moment(s.date);
          });
          stream[0].values[key] = schedule;
        }
      }

      form.setFieldsValue(stream[0].values);
      setAddress(stream[0].values.address);
      setTaxRate(stream[0].values.taxRate || 0);
      setLoaded(true);
    }
  }, [stream]);

  if (!admin && stream && stream[0]) {
    for (const key in stream[0].values) {
      if (key.endsWith(".schedule")) {
        let schedule = stream[0].values[key];
        schedule.forEach((s, i) => {
          schedule[i].date = moment(s.date);
        });
        stream[0].values[key] = schedule;
      }
    }

    form.setFieldsValue(stream[0].values);
  }

  useEffect(() => {
    if (taxRate) {
      setStream();
    }
  }, [taxRate]);

  const inputBoxStyle = {
    borderRadius: 3,
    width: 40,
  };

  const halfWithDecimal = (v) => {
    let fl = Math.floor(v) / 2;
    let dec = v % 1;
    return fl + dec;
  };

  let submitEstimate = (values) => {
    if (!address) {
      alert("Please enter an address!");
      return;
    }
    let validDates = Object.entries(form.getFieldsValue())
      .filter(([k, v]) => k.includes("include") && v)
      .map(([key]) => {
        if (values[key]) {
          let tid = key.replace(".include", "");
          let due = day[tid];
          if (!due) {
            alert(
              `Please select a due date for service "${
                templates.filter(({ id }) => id == tid)[0]?.name
              }"`
            );
            return false;
          }
        }

        return true;
      });

    if (!validDates.length) {
      alert("No services selected!");
      return;
    }
    if (!validDates.every((d) => d)) {
      return;
    }

    setLoading(true);

    let contractPromises = Object.entries(included).map(
      async ([templateId, _]) => {
        if (values[`${templateId}.include`]) {
          // let answers = Object.entries(values).filter(([key, val]) => key.startsWith(`${templateId}.question`)).map(([key, value]) => {
          //   return {
          //     qid: key.split('.').pop(),
          //     answer: value,
          //   }
          // })
          let template = templates.filter(({ id }) => id == templateId).pop();

          let contractId = uuidv4();
          let invoice = new Date()
            .toISOString()
            .slice(5, 16)
            .replace(/[T:-]/g, "");
          invoice = `${contractId.slice(0, 4)}-${invoice}`;

          let survey = {};
          Object.entries(values).forEach(([key, answer]) => {
            if (key.includes("survey")) {
              let [templateKey, _, questionKey] = key.split(".");
              if (templateKey == templateId) {
                survey[questionKey] = {
                  answer,
                  ...template.survey[questionKey],
                };
              }
            }
          });

          let newSchedule = {};
          values[`${templateId}.schedule`].forEach(({ date, price }) => {
            newSchedule[uuidv4()] = { date: date.valueOf(), price: parseFloat(price) };
          });

          await cloudUtils.updateDocument("contract", contractId, {
            revisions: 3,
            package: template.price.free
              ? false
              : values[`${templateId}.bundle`],
            survey,
            price: template.price.free ? false : totalSchedulePrice(templateId),
            tax: taxRate,
            notes: values[`${templateId}.notes`],
            schedule: newSchedule,
            outstanding: template.price.free
              ? false
              : halfWithDecimal(totalSchedulePrice(templateId)),
            invoice,
            addons: Object.entries({ ...counts, ...total })
              .filter(([_, { template }]) => template == templateId)
              .map(([addon, { count, price, feature }]) => {
                return { addon, count, price, feature };
              }),
            template,
            project: project.id,
            due: day[templateId].valueOf(),
          });
        }
      }
    );

    Promise.all(contractPromises).then(() => {
      cloudUtils
        .updateDocument(
          "project",
          project.id,
          {
            status: "SIGNATURE",
            sellerSign: sign,
            createdDate: new Date().valueOf(),
            price: projectPrice.toFixed(2),
            taxLocation,
            buyerAddress: address,
            tax: taxRate,
            currency: "CAD",
            deposit: depositPrice,
          },
          "UPDATE"
        )
        .then(() => window.location.reload());
    });
  };

  return (
    <div>
      {loading && <Loader />}
      <Div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Label>Requested Services</Label>
        {project.services.map((tempid) => {
          if (!service) {
            setService(tempid);
          }
          return (
            <Text>{templates.filter(({ id }) => id == tempid)[0]?.name}</Text>
          );
        })}
        <br />
        {admin ? (
          <>
            <Text style={{ fontStyle: "italic" }}>
              {" "}
              Package, addon and suggested prices are hidden from your
              customer's view.
            </Text>
            <br />
          </>
        ) : null}
      </Div>
      {templates
        .filter(({ show }) => show)
        .map((template) => {
          return (
            <OptionTag
              big
              selected={template.id == service}
              onClick={() => {
                setService(template.id);
              }}
            >
              {template.name}
            </OptionTag>
          );
        })}

      <Form
        align="left"
        requiredMark={false}
        layout="vertical"
        onFinish={(values) =>
          confirm({
            title: "Are these the final requirements?",
            content: (
              <>
                The details set in this discovery session will be used to create
                your project order. Are you ready to create your contract?
                <br />
                <br />
                <Label>Signature</Label>
                <Input
                  onInput={(e) => setSign(e.target.value)}
                  required={true}
                />
              </>
            ),
            okText: "Create",
            cancelText: "Cancel",
            onOk() {
              setValues(values);
            },
            onCancel() {
              console.log("Cancel");
            },
          })
        }
        form={form}
        onFieldsChange={setStream}
      >
        {templates.map((template) => (
          <div
            style={{
              display: template.id == service ? "initial" : "none",
            }}
          >
            <br />
            <Text>{template.longDesc}</Text>
            <br />
            <ToggleInput
              displayText="Include in Project?"
              label={`${template.id}.include`}
              state={included[template.id]}
              dummy={!admin}
              setState={(cur) =>
                setIncluded((incl) => {
                  incl[template.id] = cur;
                  return { ...incl };
                })
              }
            />
            {/* 
            {form.getFieldValue(`${template.id}.include`) &&
              Object.entries(template.survey).map(
                ([sid, { type, question }]) => {
                  return type == "text" ? (
                    <TextInput
                      label={`${template.id}.question.${sid}`}
                      displayText={question}
                      required={true}
                    />
                  ) : null;
                }
              )} */}

            {form.getFieldValue(`${template.id}.include`) &&
            Object.keys(template.survey || {}).length
              ? Object.entries(template.survey).map(([key, { question }]) => {
                  return (
                    <>
                      <BigTextInput
                        lines={4}
                        required={true}
                        label={`${template.id}.survey.${key}`}
                        displayText={question}
                        disabled={!admin}
                      />
                    </>
                  );
                })
              : null}

            {form.getFieldValue(`${template.id}.include`) ? (
              <>
                <Label>Questions & Comments</Label>
                <BigTextInput
                  style={{ marginTop: 8 }}
                  label={`${template.id}.notes`}
                  disabled={!admin}
                />
              </>
            ) : null}

            {form.getFieldValue(`${template.id}.include`) &&
            !template.price.free &&
            template.bundle &&
            Object.keys(template.bundle).length ? (
              <div style={{ paddingBottom: 8 }}>
                <Label>Packages</Label>
                <RadioInput
                  soquote={Object.entries(template.bundle)}
                  setInitial={setInitial}
                  disabled={!admin}
                  setBase={setBase}
                  template={template.id}
                  label={`${template.id}.bundle`}
                  initVal={form.getFieldValue(`${template.id}.bundle`)}
                  style={{ marginBottom: 0 }}
                />
              </div>
            ) : null}
            {form.getFieldValue(`${template.id}.include`) &&
            !template.price.free &&
            template.quote &&
            Object.keys(template.quote).length ? (
              <div style={{ paddingBottom: 8 }}>
                <Label>Cost Considerations</Label>
                <CheckboxInput
                  soquote={Object.entries(template.quote)}
                  disabled={!admin}
                  style={{ display: "block" }}
                  initial={initial[template.id]}
                  initVal={form.getFieldValue(`${template.id}.quote`)}
                  template={template.id}
                  total={total}
                  setTotal={setTotal}
                  label={`${template.id}.quote`}
                />
                {Object.entries(template.quote)
                  .filter(([id, { count }]) => count)
                  .map(([id, { feature, price, packages }]) => {
                    return (
                      <div
                        style={{
                          visibility: "inherit",
                          height: "auto",
                          marginBottom: 16,
                        }}
                      >
                        <Form.Item noStyle name={`${template.id}.count.${id}`}>
                          <InputNumber
                            min={0}
                            size="small"
                            disabled={
                              !packages.includes(initial[template.id]) || !admin
                            }
                            onChange={(e) => {
                              counts[id] = {
                                price: parseFloat(price),
                                count: e,
                                template: template.id,
                                feature,
                              };
                              setCounts({ ...counts });
                            }}
                            style={inputBoxStyle}
                          ></InputNumber>
                        </Form.Item>
                        <span
                          style={{
                            paddingLeft: 8,
                            opacity:
                              admin && packages.includes(initial[template.id])
                                ? 1
                                : 0.75,
                          }}
                        >
                          {admin ? `${feature} - $${price} each` : feature}
                        </span>
                      </div>
                    );
                  })}
              </div>
            ) : null}

            {form.getFieldValue(`${template.id}.include`) &&
              !template.price.free && (
                <>
                  {admin && (
                    <>
                      <Label>Project Cost</Label>
                      <Text>
                        $
                        {(
                          base[template.id] +
                          Object.values(counts)
                            .filter(({ template: t }) => t == template.id)
                            .map(({ price, count }) => price * count)
                            .reduce((a, b) => a + b, 0) +
                          Object.values(total)
                            .filter(({ template: t }) => t == template.id)
                            .map(({ price }) => price)
                            .reduce((a, b) => a + b, 0)
                        ).toFixed(2)}
                      </Text>
                      <br />
                      <TextInput
                        required={true}
                        prefix="%"
                        label={`${template.id}.margin`}
                        displayText="Profit Margin"
                      />
                      <Label>Suggested Price</Label>
                      <Text>
                        $
                        {(
                          (base[template.id] +
                            Object.values(counts)
                              .filter(({ template: t }) => t == template.id)
                              .map(({ price, count }) => price * count)
                              .reduce((a, b) => a + b, 0) +
                            Object.values(total)
                              .filter(({ template: t }) => t == template.id)
                              .map(({ price }) => price)
                              .reduce((a, b) => a + b, 0)) *
                          (1 +
                            form.getFieldValue(`${template.id}.margin`) / 100)
                        ).toFixed(2)}
                      </Text>
                      <br />
                    </>
                  )}
                  {/* <TextInput
                    required={true}
                    validators={[pricePositive(false, 0, 0)]}
                    label={`${template.id}.price`}
                    displayText="Final Price"
                    disabled={!admin}
                    price
                  /> */}
                  <Schedule admin={admin} template={template.id} />
                  <Label>Final Price</Label>
                  <Text>${totalSchedulePrice(template.id) || 0}</Text>
                  <br />
                </>
              )}

            {form.getFieldValue(`${template.id}.include`) && (
              <>
                <Label>Due Date</Label>
                {admin ? (
                  <Appointment
                    day={day[template.id] || moment("9:00", "HH:mm")}
                    setInvalid={() => {}}
                    setDay={setDay}
                    template={template.id}
                    duration={profile.duration || 60}
                    openHours={profile.openHours}
                    busy={profile.busy}
                    closedDays={profile.closedDays}
                    showTime={false}
                  />
                ) : (
                  // <Calendar
                  //   style={{ borderRadius: 3, marginBottom: 16 }}
                  //   onChange={(d) =>
                  //     setDay((prev) => {
                  //       prev[template.id] = d;
                  //       return { ...prev };
                  //     })
                  //   }
                  //   fullscreen={false}
                  //   required={false}
                  //   validRange={[
                  //     moment().subtract(86400000),
                  //     moment().add(315400000000),
                  //   ]}
                  // />
                  <>
                    <Text>
                      {form.getFieldValue(`${template.id}.due`)
                        ? moment(
                            form.getFieldValue(`${template.id}.due`)
                          ).format("MMM Do, YYYY")
                        : "Unset"}
                    </Text>
                    <br />
                  </>
                )}
              </>
            )}
          </div>
        ))}
        <Divider displayText="Project" />

        <Label>Project Price: </Label>
        <Text>${projectPrice?.toFixed(2)}</Text>
        <br />
        <LocationInput
          setTaxLocation={setTaxLocation}
          global={true}
          address={address}
          setAddress={setAddress}
        />
        <Label>Tax Rate</Label>
        <Text>{100 * taxRate}%</Text>
        <br />
        {admin && (
          <ToggleInput
            displayText="Charge Tax"
            state={includeTax}
            setState={setIncludeTax}
          />
        )}

        {/* Onload bug, was clearing form */}
        {/* {Object.keys(form.getFieldsValue()).length > 1 && (
          <Form.Item
taxRate            label={<Label>Client Tax Rate</Label>}
            initialValue={0}
            rules={[
              {
                required: true,
                message: ``,
              },
            ]}
          >
            <InputNumber disabled={!admin} min={0} prefix="%"></InputNumber>
          </Form.Item>
        )} */}

        <Label>With Sales Tax: </Label>
        <Text>${applyTax(projectPrice, taxRate, includeTax)}</Text>
        <br />

        <Label>Total Deposit: </Label>
        <Text>${applyTax(depositPrice, taxRate, includeTax)}</Text>
        <br />

        {admin &&
          (Object.entries(included).filter(([k, v]) => v).length ? (
            <Submit big margin={0} text="Generate Agreements" />
          ) : (
            <Text style={{ textAlign: "center" }}>
              At least one service must be selected to generate agreements
            </Text>
          ))}
      </Form>
    </div>
  );
};
