import { Input } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { KeyboardAvoidingView } from "react-native";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { serverTimestamp } from "firebase/firestore";
import {
  ReceiverMessage,
  SenderMessage,
  MachineMessage,
} from "../customComponents";
import { useParams } from "react-router-dom";
import { AccentButton } from "../customComponents";

export default ({ profile, user, cloudUtils }) => {
  let { projectId } = useParams();

  document.getElementById("root").style.height = null;

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToBottomDelay = () => {
    setTimeout(() => {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  useEffect(() => {
    scrollToBottomDelay();
  }, []);

  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
  }, [profile]);

  const currentUser = admin == true ? "admin" : "customer";
  const otherUser = currentUser == "admin" ? "customer" : "admin";
  const messagesEndRef = useRef(null);

  function Chat() {
    const messagesRef = cloudUtils.getChildCollectionRef(
      "project",
      projectId,
      "messages"
    );
    const query = messagesRef.orderBy("time");
    const [messages] = useCollectionData(query, { idField: "id" });
    const [formValue, setFormValue] = useState("");

    const sendMessage = async (e) => {
      await messagesRef.add({
        sender: currentUser,
        text: formValue,
        time: serverTimestamp(),
      });

      console.log(otherUser, currentUser)
      cloudUtils.updateDocument(
        "project",
        projectId,
        {
          chatNotif: { [otherUser]: true, [currentUser]: false },
        },
        "UPDATE"
      );
      setFormValue("");
    };

    useEffect(() => {
      if (messages && !messages.length) {
        messagesRef
          .add({
            sender: "machine",
            text: "Be respectful, be kind",
            time: serverTimestamp(),
          })
          .catch(console.log);
      }
    }, []);

    useEffect(() => {
      scrollToBottom();
    }, [messages]);

    return (
      <div style={{ paddingTop: 10, paddingBottom: 70, width: "100%" }}>
        {messages &&
          messages.map((msg) => <ChatMessage key={msg.id} message={msg} />)}
        <div ref={messagesEndRef} />
        <KeyboardAvoidingView
          style={{
            bottom: 20,
            left: 20,
            position: "fixed",
            width: "calc(100% - 40px)",
          }}
        >
          <Input
            placeholder="Enter your message"
            suffix={
              <AccentButton
                disabled={!formValue}
                onClick={sendMessage}
                icon={<SendOutlined />}
              />
            }
            style={{ borderRadius: 3 }}
            value={formValue}
            onChange={(e) => setFormValue(e.target.value)}
            onClick={() => scrollToBottom()}
            onPressEnter={formValue != "" && sendMessage}
            size="large"
          />
        </KeyboardAvoidingView>
      </div>
    );
  }

  function ChatMessage({ message }) {
    const { text, sender } = message;

    let isSender = sender == currentUser;
    if (sender == "machine") {
      return <MachineMessage>{text}</MachineMessage>;
    } else {
      return isSender ? (
        <SenderMessage>{text}</SenderMessage>
      ) : (
        <ReceiverMessage>{text}</ReceiverMessage>
      );
    }
  }

  return <Chat />;
};
