import InfoCard from '../elements/InfoCard'

export default ({ tracking, title, time, customer, desc, price, status, onClick, profile }) => {
  const STATUSES = {
    'LEAD': tracking ? 'Seller Review' : 'Confirm Appointment',
    'DISCOVERY': tracking ? 'View Requirements' : 'Set Requirements',
    'ACTIVE': tracking ? 'Discovery' : 'Track Deliverables',
    'REVIEWED': tracking ? 'Review Invoice' : 'Pending Approval',
    'DECLINED': 'Cancelled',
    'ACCEPTED': 'In Progress',
    'COMPLETE': 'View Summary',
    'DEPOSIT': tracking ? 'Receipt Confirmation' : 'Awaiting Deposit',
    'SIGNATURE': tracking ? 'Receipt Confirmation' : 'Awaiting Signature'
  }

  return (
    <InfoCard 
      onClick={onClick}
      type="order"
      desc={desc}
      price={price ? price : null}
      theme={profile.theme}
      time={time}
      timeHeader={status == 'LEAD' ? 'Appointment' : 'Deadline'}
      title={customer}
      status={status}
      action={STATUSES[status]}
      subtitle={title}
    />
  )
}