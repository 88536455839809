import {
  PageHeader,
  Button,
  Divider,
  Radio,
  InputNumber,
  Row,
  Col,
  Tag,
  Switch,
  Typography,
} from "antd";
import styled from "styled-components";

import { DownCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

const BorderAccentDiv = styled.div`
  border: solid;
  border-radius: 3px;
  border-image: ${(props) => borderImages[props.theme.design]};
  border-image-slice: 1;
`;

const BorderDiv = styled.div`
  color: ${(props) => props.theme.fontColor};
  background-color: ${(props) => props.theme.background};
  border: 2px solid ${(props) => props.theme.secondary};
  border-radius: 3px;
  cursor: pointer;
  height: fit-content;
  font-size: 16px;
  line-height: 30px;
`;
//   ${(props) => (props.accent ? props.theme.accent : props.theme.fontColor)};

const Div = styled.div`
  color: ${(props) => props.theme.fontColor};
  background-color: ${(props) => props.theme.background};
`;

const TransDiv = styled.div`
  color: ${(props) => props.theme.fontColor};
`;

const InvertedDiv = styled.div`
  background-color: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.fontColor};
`;

const AccentDiv = styled.div`
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.fontColor};
`;

const ReceiverText = styled.p`
  padding: 8px;
  color: ${(props) => props.theme.fontColor};
`;

const ReceiverMessage = styled(({ children, className }) => (
  <div className={className}>
    <ReceiverText>{children}</ReceiverText>
  </div>
))`
  width: fit-content;
  background: ${(props) => props.theme.background};
  border: 2px solid ${(props) => props.theme.accent};
  margin-bottom: 8px;
  border-radius: 3px;
  margin-right: auto;
  margin-left: 0;
`;

const SenderText = styled.p`
  padding: 8px;
  color: white;
`;

// background-image: linear-gradient(
//   20deg,
//   ${props => props.theme.primary} 20%,
//   ${props => props.theme.accent} 70%,
//   ${props => props.theme.secondary} 100%
// );
// background-size: 100% 100%;
// background-attachment: fixed;

const SenderMessage = styled(({ children, className }) => (
  <div className={className}>
    <SenderText>{children}</SenderText>
  </div>
))`
  width: fit-content;
  background: linear-gradient(130deg, #ee9c65 20%, #f05e45 100%) fixed;
  margin-bottom: 8px;
  border-radius: 3px;
  margin-right: 0;
  margin-left: auto;
`;

const MachineMessage = styled(({ children, className }) => (
  <div className={className}>
    <ReceiverText>{children}</ReceiverText>
  </div>
))`
  border-radius: 3px;
  margin-right: auto;
  margin-left: auto;
`;

const Text = styled.p`
  color: ${(props) => props.theme.fontColor};
  max-width: 768px;
  white-space: ${(props) => (props.break ? "pre-wrap" : "normal")};
`;

const OptionTag = styled(({ selected, ...props }) => <Tag {...props} />)`
  color: ${(props) => (props.selected ? "white" : "#1c1e1f")};
  background-color: ${(props) =>
    props.selected ? props.theme.accent : props.theme.secondary};
  width: 50px;
  font-size: ${(props) => (props.big ? "20px" : "14px")};
  min-width: fit-content;
  border-radius: 3px;
  border-width: 0px;
  margin: 6px;
  padding: ${(props) => (props.big ? "16px 24px" : "6px")};
  cursor: pointer;
`;

const CustomRadio = styled(Radio)`
  color: ${(props) => props.theme.fontColor};
`;

const Flat = styled((props) => <div {...props} />)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background: ${(props) => props.theme.background};
`;

const Link = styled.a`
  white-space: ${(props) => (props.break ? "pre-wrap" : "normal")};
`;

const Blocks = styled((props) => <div {...props} />)`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background: ${(props) => props.theme.primary};
  transition: 1s;

  &:before {
    content: "";
    position: absolute;
    left: 75vw;
    width: 70vh;
    height: 70vh;
    top: 110vh;
    background: ${(props) => props.theme.accent};
    animation: animate reverse 60s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    left: 25vw;
    width: 90vh;
    height: 90vh;
    top: 140vh;
    background: ${(props) => props.theme.secondary};
    animation: animate 75s linear infinite;
  }
`;

const Liquid = styled((props) => <div {...props} />)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  overflow: hidden;
  background: linear-gradient(
    ${(props) => props.theme.primary},
    ${(props) => props.theme.accent} 55%,
    ${(props) => props.theme.secondary} 100%
  );
  transition: 1s;

  &:after {
    content: "";
    position: absolute;
    left: 50%;
    filter: blur(40px);
    transform: translate(-50%, -50%);
    width: 1500px;
    height: 2000px;
    top: 15%;
    border-radius: 30%;
    background: ${(props) => props.theme.primary};
    animation: animate 30s linear infinite;
  }

  &:before {
    content: "";
    position: absolute;
    left: 50%;
    filter: blur(40px);
    transform: translate(-50%, -50%);
    width: 1000px;
    height: 750px;
    top: 40%;
    border-radius: 30%;
    background: ${(props) => props.theme.accent};
    animation: animate 10s linear infinite;
  }
`;

const BackgroundProvider = styled.div`
  & > * {
    background: linear-gradient(
      130deg,
      ${(props) => props.theme.primary} 20%,
      ${(props) => props.theme.accent} 55%,
      ${(props) => props.theme.secondary} 100%
    );
  }
`;

const CustomInputNumber = styled(InputNumber)`
  color: ${(props) => props.theme.fontColor};
  border-radius: 3;
  width: 40;
`;

const Label = styled.label`
  color: ${(props) => props.theme.fontColor}!important;
  font-size: 20px !important;
  line-height: 1.3;
`;

const BlackLabel = styled.label`
  color: #1c1e1f !important;
  font-size: 20px !important;
  line-height: 1.3;
`;

// text-decoration: underline;
// text-decoration-color: ${(props) => props.theme.accent};
const SubHeader = styled.h3`
  font-size: 24px;
  font-weight: 700;
  color: ${(props) => props.theme.fontColor};
`;

const BigHeader = styled.h1`
  color: ${(props) => props.theme.fontColor};
  font-size: 24px;
  margin: 0;
  font-weight: 400;
`;

const LightText = styled.span`
  font-weight: 400;
`;

const SocialLink = styled((props) => (
  <Button type="text" className="link" {...props} />
))`
  color: ${(props) => props.theme.primary}!important;
`;

const PrimaryIcon = styled((props) => (
  <Button type="text" className="link" {...props} />
))`
  color: ${(props) => props.theme.fontColor};
`;

const SubDivider = styled(Divider)`
  border-top-color: ${(props) => props.theme.accent} !important;
  color: ${(props) => props.theme.fontColor} !important;
  font-size: 20px !important;
`;

const CustomDivider = ({ displayText }) =>
  displayText ? <SubDivider>{displayText}</SubDivider> : <SubDivider />;

const CustomParagraph = styled(Typography.Paragraph)`
  color: ${(props) => props.theme.fontColor};
`;

const borderImages = {
  liquid: `linear-gradient(
    130deg,
    ${(props) => props.theme.primary} 20%,
    ${(props) => props.theme.accent} 55%,
    ${(props) => props.theme.secondary} 100%
  );`,
  flat: `linear-gradient(
    130deg,
    #EE9C65 20%,
    #F05E45 100%
  );`,
  block: `${(props) => props.theme.primary}`,
};

const CustomPageHeader = styled(PageHeader)`
  background: ${(props) => props.theme.background};
  color: ${(props) => props.theme.fontColor}!important;
  border-image: ${(props) => borderImages[props.theme.design]};
  border-image-slice: 1;
  border-bottom: 3px solid;
  z-index: 3;
  color: ${(props) => props.theme.fontColor};
  position: fixed;
  width: 100%;
`;

const Page = styled.div`
  background: ${(props) => props.theme.background}
  color: ${(props) => props.theme.fontColor}
`;

// border: 3px solid ${(props) => props.theme.fontColor};
const RegularButton = styled((props) => <Button {...props} />)`
  border: 2px solid ${(props) => props.theme.secondary};
  background-color: ${(props) => props.theme.background};
  color: ${(props) => props.theme.fontColor};
  padding: 8px 16px;
  height: fit-content;
  font-size: 16px;
  line-height: 30px;
  width: ${(props) => (props.block ? "100%" : "fit-content")};
  border-radius: 3px;
`;

// border: 3px solid ${(props) => props.theme.accent};
const AccentButton = styled((props) => <Button {...props} />)`
  background-color: ${(props) => props.theme.accent};
  color: white;
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  border: none;
  font-size: 16px;
  border-radius: 3px;
  line-height: 30px;
`;

// border: 3px solid ${(props) => props.theme.danger};
const DangerButton = styled((props) => <Button {...props} />)`
  background-color: ${(props) => props.theme.danger};
  color: white;
  border: none;
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  font-size: 16px;
  border-radius: 3px;
  line-height: 30px;
`;

const DangerGhostButton = styled((props) => (
  <Button shape="round" type="text" {...props} />
))`
  color: ${(props) => props.theme.danger};
  padding: 0;
`;

// border: 3px solid ${(props) => props.theme.accent};
const BigButton = styled((props) => <Button size="large" block {...props} />)`
  max-width: 768px;
  background: linear-gradient(130deg, #ee9c65 20%, #f05e45 100%);
  border: none;
  color: white;
  margin: 24px 0;
  padding: 12px 16px;
  height: fit-content;
  width: fit-content;
  font-size: 24px;
  border-radius: 3px;
  line-height: 40px;
  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(130deg, #ee9c65 20%, #f05e45 100%);
    color: white;
  }
`;

const Selectable = styled(
  ({ name, longDesc, included, setIncluded, id, ...props }) => (
    <Row {...props}>
      <Col span={20}>
        <SubHeader style={{ marginBottom: 0, textAlign: "left" }}>
          {name}
        </SubHeader>
        <Text
          style={{
            textAlign: "left",
            whiteSpace: "pre-wrap",
            width: "100%",
          }}
        >
          {longDesc}
        </Text>
      </Col>
      <Col span={4}>
        <Switch
          // checked={included[id]}
          onChange={(checked) =>
            setIncluded((prev) => {
              prev[id] = checked;
              return { ...prev };
            })
          }
        />
      </Col>
    </Row>
  )
)`
  color: ${(props) => props.theme.fontColor};
  border-top: 3px solid;
  border-radius: 3px;
  border-image: ${(props) =>
    props.included[props.id] ? borderImages[props.theme.design] : null};
  border-color: #e0e4e4;
  border-image-slice: 1;
  margin: 32px 0;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  max-width: 768px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
`;

const Expandable = styled(({ open, title, children, ...props }) => (
  <div {...props}>
    <Row>
      <Col offset={4} span={16}>
        <span style={{ fontWeight: "bold", width: "100%" }}>{title}</span>
      </Col>
      <Col span={4}>
        {open ? (
          <CloseCircleOutlined style={{ fontSize: 18 }} />
        ) : (
          <DownCircleOutlined style={{ fontSize: 18 }} />
        )}
      </Col>
    </Row>
    {open && children}
  </div>
))`
  background-color: ${(props) => props.theme.secondary};
  color: ${(props) => props.theme.fontColor};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 24px 0;
  padding: 12px;
  font-size: 16px;
  width: 100%;
  max-width: 768px;
  cursor: pointer;
  position: relative;
  border-radius: 3px;
`;

const GhostButton = styled((props) => <Button ghost type="text" {...props} />)`
  color: ${(props) => props.theme.primary};
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  font-size: 16px;
  line-height: 30px;
`;

// background-color: ${(props) => props.theme.secondary};
// border: 2px solid ${(props) => props.theme.accent};
// color: ${(props) => props.theme.accent};
const SubmitButton = styled((props) => <Button {...props} />)`
  max-width: 768px;

  background: linear-gradient(130deg, #ee9c65 20%, #f05e45 100%);
  border: none;
  color: white;
  padding: 8px 16px;
  height: fit-content;
  width: fit-content;
  font-size: 16px;
  border-radius: 3px;
  line-height: 30px;
  &:hover,
  &:focus,
  &:active {
    background: linear-gradient(130deg, #ee9c65 20%, #f05e45 100%);
    color: white;
  }
`;

export {
  Div,
  InvertedDiv,
  BorderDiv,
  BorderAccentDiv,
  TransDiv,
  AccentDiv,
  OptionTag,
  PrimaryIcon,
  RegularButton,
  BigButton,
  Expandable,
  Selectable,
  GhostButton,
  DangerButton,
  AccentButton,
  DangerGhostButton,
  SubmitButton,
  CustomPageHeader as PageHeader,
  Page,
  CustomDivider as Divider,
  CustomParagraph as Paragraph,
  Label,
  BlackLabel,
  SubHeader,
  BigHeader,
  LightText,
  SocialLink,
  CustomRadio as Radio,
  CustomInputNumber as InputNumber,
  Text,
  ReceiverMessage,
  SenderMessage,
  MachineMessage,
  BackgroundProvider,
  Liquid,
  Blocks,
  Link,
  Flat,
};
