import { Form, DatePicker } from "antd";
import { Label } from "../customComponents";

const DateInput = ({
  displayText,
  label,
  required = true,
  defaultValue,
  validators = [],
  tight,
  paddingRight = 0,
  disableFunction = () => false,
  ...rest
}) => {
  return (
    <Form.Item
      className={tight ? "tight" : null}
      label={displayText ? <Label>{displayText}</Label> : null}
      style={{ paddingRight }}
      name={label}
      rules={[
        {
          required,
          message: `Please input a ${displayText}`,
        },
        ...validators,
      ]}
    >
      <DatePicker
        disabledDate={disableFunction}
        format="YYYY-MM-DD"
        style={{ width: "100%" }}
        placeholder="Enter date in YYYY-MM-DD format"
        defaultValue={defaultValue}
        {...rest}
      />
    </Form.Item>
  );
};

export default DateInput;
