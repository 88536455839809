import React from "react";
import GuideSteps from "../Components/GuideSteps.tsx";

function How() {
  return (
    <div
      id="guide"
      className="flex flex-col relative justify-center items-center w-full"
    >
      {/* Text Araa */}
      <div className="w-full text-SocoWhite flex flex-col justify-center items-center">
        <h1 className="text-5xl my-5 px-2 text-center text-SocoWhite font-bold">
          How it works
        </h1>

        <p className="text-2xl my-5 px-2 lg:w-2/3 text-center">
          <span>
            Optimize the workflow behind your creativity in a few
            simple steps.
          </span>
        </p>
      </div>
      {/* Content Area */}
      <div className="w-full text-SocoWhite flex flex-col justify-center items-center">
        <GuideSteps />
      </div>
    </div>
  );
}

export default How;
