import React from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import TextInput from "../../inputs/TextInput";
import BigTextInput from "../../inputs/BigTextInput";
import { DangerGhostButton, RegularButton } from "../../customComponents";
import { v4 as uuidv4 } from "uuid";

function Bundle({ validators = [] }) {
  return (
    <Form.List
      name="bundle"
      initialValue={[{ title: "Base Package", base: true, id: uuidv4() }]}
      rules={[...validators]}
    >
      {(fields, { add, remove }, { errors }) => {
        return (
          <div>
            {fields.map((field, index) =>
              index > 0 ? (
                <div key={field.key} style={{ marginBottom: 16 }}>
                  <Form.Item
                    name={[index, "id"]}
                    style={{ display: "none" }}
                    initialValue={uuidv4()}
                  />
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      tight
                      required={true}
                      placeholder="Package Name"
                      style={{ flexGrow: 1, paddingRight: 16, marginBottom: 0 }}
                      label={[index, "title"]}
                    />
                    <TextInput
                      tight
                      required={true}
                      price={field}
                      placeholder="Price"
                      style={{ width: 90, paddingRight: 16, marginBottom: 0 }}
                      label={[index, "price"]}
                    />
                    <DangerGhostButton
                      onClick={() => remove(field.name)}
                      icon={<CloseCircleOutlined />}
                    />
                  </div>
                  <BigTextInput
                    tight
                    required={true}
                    placeholder="Package Description"
                    style={{ flexGrow: 1, marginBottom: 0, marginTop: 5 }}
                    label={[index, "description"]}
                  />
                </div>
              ) : (
                <div key={field.key} style={{ marginBottom: 16 }}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <TextInput
                      tight
                      required={true}
                      initial="Base Package with Addons"
                      style={{ flexGrow: 1, paddingRight: 16, marginBottom: 0 }}
                      label={[index, "title"]}
                    />
                    <TextInput
                      tight
                      required={true}
                      price={field}
                      placeholder="Price"
                      style={{ width: 90, paddingRight: 16, marginBottom: 0 }}
                      label={[index, "price"]}
                    />
                    <DangerGhostButton
                      icon={<CloseCircleOutlined />}
                      style={{ visibility: "hidden" }}
                    />
                  </div>
                </div>
              )
            )}
            <Form.Item>
              <center>
                <RegularButton onClick={add} icon={<PlusOutlined />}>
                  Add Package
                </RegularButton>
                <Form.ErrorList errors={errors} />
              </center>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default Bundle;
