import { Col, Row, Switch } from "antd";
import { SubHeader, BorderDiv } from "../customComponents";
import { EditOutlined } from "@ant-design/icons";

export default ({
  title,
  time,
  setActive = false,
  active,
  desc,
  action,
  status,
  onClick,
}) => {
  let timeSplit = false;
  if (time) {
    timeSplit = typeof time == "string" ? time.split(" to ") : [time];
  }
  console.log(setActive)

  return (
    <Row
      className="gutter-row card-back info-card-inner"
      gutter={[0, 0]}
      style={{ width: "100%" }}
    >
      <Col span={setActive ? 18 : 24} style={{ textAlign: "left" }}>
        <SubHeader>
          {title}{" "}
          {status && <span style={{ fontWeight: "lighter" }}>| {status}</span>}
        </SubHeader>
      </Col>
      {setActive && (
        <Col span={6} align="right" style={{ paddingRight: 12 }}>
          <Switch
            checked={active}
            onChange={setActive}
            checkedChildren="Included"
            unCheckedChildren="Hidden"
          />
        </Col>
      )}
      <Col span={24} align="left">
        {/* {subtitle && <LightText>| {subtitle}</LightText>} */}

        {desc}

        <br />
        {/* {status && (<AccentDiv className='floating-status'>{status}</AccentDiv>)} */}
        {/* {price !== null && <InvertedDiv className='floating-action'>{price > 0 ? 'From $' + price : 'Free'}</InvertedDiv>} */}
        {onClick && (
          <BorderDiv onClick={onClick} className="floating-action">
            {action} <EditOutlined />
          </BorderDiv>
        )}
      </Col>
    </Row>
  );
};
