import React, { useEffect, useState } from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form, Checkbox } from "antd";
import TextInput from "../../inputs/TextInput";
import { DangerGhostButton, RegularButton } from "../../customComponents";
import Options from "../../forms/PackageSelect";

function Quote({ bundles, form }) {
  const [bundleCount, setBundleCount] = useState(0)
  if (bundles && bundleCount != bundles.length){
    setBundleCount(bundles.length)
  }

  useEffect(() => {
    const newQuote = (form.getFieldValue('quote') || []).map(({packages, ...rest}) => {
      let newPackages = packages || []
      if (packages){
        newPackages = packages.filter(bundle => bundles.map(({id}) => id).includes(bundle))
      }
      return {packages: newPackages, ...rest}
    })
    form.setFieldsValue({quote: newQuote})

  }, [bundleCount])


  return (
    <Form.List name="quote">
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div key={field.key} style={{ marginBottom: 16 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <TextInput
                    tight
                    required={true}
                    placeholder="Item Description"
                    style={{ flexGrow: 1, marginBottom: 0, paddingRight: 16 }}
                    label={[index, "feature"]}
                  />
                  <TextInput
                    tight
                    required={true}
                    price={true}
                    placeholder="Price"
                    style={{ width: 90, marginBottom: 0, paddingRight: 16 }}
                    label={[index, "price"]}
                  />
                  <DangerGhostButton
                    onClick={() => remove(field.name)}
                    icon={<CloseCircleOutlined />}
                  />
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Options bundles={bundles} label={[index, "packages"]}  />
                  <Form.Item
                    // label={<label style={{ color }}>Count</label>}
                    name={[index, "count"]}
                    required
                    style={{ marginTop: 8 }}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>Can user order multiple?</Checkbox>
                  </Form.Item>
                </div>
              </div>
            ))}
            <Form.Item>
              <center>
                <RegularButton onClick={add} icon={<PlusOutlined />}>
                  Add Addons
                </RegularButton>
              </center>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default Quote;
