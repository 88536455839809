import React, { useEffect, useState } from "react";
import { BsFillCameraFill, BsFillCameraReelsFill } from "react-icons/bs";
import { BiCodeBlock } from "react-icons/bi";
import { MdOutlineDraw } from "react-icons/md";
import { FaBrain } from "react-icons/fa";
import { IoPersonCircleOutline } from "react-icons/io5";

function HeroCard() {
  const [cardState, setCardState] = useState(true);
  const [cardNumber, setCardNumber] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => changeState(), 2000);
    const interval2Id = setInterval(() => changeCount(), 4000);
    return () => {
      clearInterval(intervalId);
      clearInterval(interval2Id);
    };
  }, []);

  // Add a delay of 2 seconds before the interval starts running
  useEffect(() => {
    const timerId = setTimeout(() => {
      setCardNumber(0);
    }, 3500);

    // Clear timer when the component unmounts
    return () => clearTimeout(timerId);
  }, []);

  interface cardDetails {
    title: string;
    icon: React.ReactNode;
  }

  const cards: cardDetails[] = [
    {
      title: "Photographers",
      icon: <BsFillCameraFill className="fill-SocoWhite h-16 w-16" />,
    },
    {
      title: "Videographers",
      icon: <BsFillCameraReelsFill className="fill-SocoWhite h-16 w-16" />,
    },
    {
      title: "Coders",
      icon: <BiCodeBlock className="fill-SocoWhite h-16 w-16" />,
    },
    {
      title: "Creatives",
      icon: <FaBrain className="fill-SocoWhite h-16 w-16" />,
    },
    {
      title: "Artists",
      icon: <MdOutlineDraw className="fill-SocoWhite h-16 w-16" />,
    },
    {
      title: "You",
      icon: (
        <IoPersonCircleOutline className="fill-SocoOrangeLight h-16 w-16 " />
      ),
    },
  ];

  const animationTrigger = cardState
    ? "translate-y-0 opacity-100 lg:translate-x-0 lg:translate-y-0"
    : "translate-y-20 opacity-0 lg:translate-x-20 lg:translate-y-0";

  const changeCount = () => {
    setCardNumber((prevValue) => {
      return prevValue + 1 == cards.length ? 0 : prevValue + 1;
    });
  };

  const changeState = () => {
    // console.log('coming in' + cardState)
    setCardState((prevCardState) => !prevCardState);
  };

  return (
    <div className="p-10 my-5 w-full lg:w-1/2 flex flex-row justify-evenly">
      {/* Card */}
      <div
        className={`transition-all duration-1000 overflow-hidden w-48 h-64 lg:w-64 lg:h-80 border-2 border-SocoOrangeLight rounded-lg flex flex-col place-content-center items-center ${animationTrigger}`}
      >
        {/* Icon */}
        {cards[cardNumber].icon}

        {/* Text */}
        <h1 className="text-SocoWhite capitalize lg:text-xl">
          {cards[cardNumber].title}
        </h1>
      </div>
    </div>
  );
}

export default HeroCard;
