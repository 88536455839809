import { Label, OptionTag, Text } from "../customComponents";
import InfoCard from "../elements/InfoCard";
import { useState, useEffect } from "react";

export default ({
  name,
  longDesc,
  price,
  bundle,
  onClick,
  profile,
  type,
  setActive,
  active,
  cloudUtils,
  id,
  tags,
}) => {
  let types = {
    digital: "Digital Service",
    consult: "Online Consult",
    booking: "In Person Appointment",
    product: "Shipped Product",
  };

  let [contracts, setContracts] = useState([]);

  useEffect(() => {
    cloudUtils.getDocumentsBy("contract", "template.id", id, setContracts);
  }, []);

  console.log(contracts);

  // const [uploaded, setUploaded] = useState(false)
  // const [portfolio, setPortfolio] = useState([])

  // useEffect(() => {
  //   cloudUtils.getUploadedFiles(id, setPortfolio)
  //   if (serviceImage) {
  //     cloudUtils.getDownloadURL(serviceImage).then((src) => setSrc(src))
  //   }
  //   if (profile.uploaded && profile.theme == 'uploaded') {
  //     cloudUtils.getDownloadURL(profile.uploaded).then((src) => setUploaded(src))
  //   }
  // }, [])

  const packageKeys = bundle ? Object.keys(bundle) : [];
  const packagePrices = packageKeys.length
    ? packageKeys.map((key) => {
        return bundle[key].price;
      })
    : [0];

  let desc = (
    <>
      <Text style={{marginBottom: 6}}>{longDesc}</Text>
      <div style={{ marginLeft: -6 }}>
        {tags.map((tag) => {
          const result = tag.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);

          return <OptionTag>{finalResult}</OptionTag>;
        })}
      </div>
      <br />
      <Label>Projects Used: {contracts.length}</Label>
    </>
  );
  return (
    <InfoCard
      onClick={onClick}
      desc={desc}
      action="Edit"
      price={Math.min.apply(0, packagePrices)}
      theme={profile.theme}
      setActive={setActive}
      active={active}
      title={name}
      subtitle={types[type]}
    />
  );

  // return (
  //   <PictureCard
  //     admin={admin}
  //     media={portfolio}
  //     uploaded={uploaded}
  //     title={name}
  //     desc={longDesc}
  //     price={`${Math.min.apply(null, packagePrices).toFixed(2)} ${price.currency}`}
  //     free={price.free}
  //     onClick={onClick}
  //     service={service}
  //     theme={profile.theme}
  //   />
  // )
};
