import React from "react";
import socoLogo from "../assets/socoLogo.png";

function Footer() {
  return (
    <div className=" px-10 w-full md:w-4/5 ">
      <div className="flex flex-row relative  justify-center md:justify-around items-start rounded-t-xl text-SocoWhite p-5 border-t-2 border-x-2 border-SocoOrangeLight flex-wrap">
        <div className="flex w-full mb-2 md:mx-2 md:w-fit justify-center items-center">
          <img src={socoLogo} alt="Soco Logo" className="" />
        </div>

        <div className="m-2 flex-1 md:flex-initial">
          <h3 className=" text-base mb-2 font-bold text-SocoWhite">Company</h3>
          <ul className=" text-sm text-left ml-5 font-light">
            <li>
              <a
                href="#why"
                className=" mb-1 text-SocoWhite cursor-pointer hover:text-SocoOrangeLight"
              >
                Why SoCo
              </a>
            </li>
            <li>
              <a
                href="#guide"
                className=" mb-1 text-SocoWhite cursor-pointer hover:text-SocoOrangeLight"
              >
                Walkthrough
              </a>
            </li>
          </ul>
        </div>

        <div className="m-2 flex-1 md:flex-initial">
          <h3 className=" text-base mb-2 font-bold text-SocoWhite">Socials</h3>
          <ul className=" text-sm text-left ml-5 font-light">
            <li>
              <a
                href="https://www.instagram.com/socialcontracts/"
                className=" mb-1 text-SocoWhite cursor-pointer hover:text-SocoOrangeLight"
              >
                Instagram
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/in/socialcontracts/"
                className=" mb-1 text-SocoWhite cursor-pointer hover:text-SocoOrangeLight"
              >
                LinkedIn
              </a>
            </li>
          </ul>
        </div>
        <div className="m-2 flex-1 md:flex-initial">
          <h3 className=" text-base mb-2 font-bold text-SocoWhite">Contact</h3>
          <ul className=" text-sm text-left ml-5 font-light">
            <li>
              <a
                href="mailto: info@soco.market"
                className=" mb-1 text-SocoWhite cursor-pointer hover:text-SocoOrangeLight"
              >
                info@soco.market
              </a>
            </li>
            <li>
              <a
                href="tel:647-929-7059"
                className=" mb-1 text-SocoWhite cursor-pointer hover:text-SocoOrangeLight"
              >
                +1 (647) 929-7059
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
