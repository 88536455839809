import {
  pdf,
  PDFViewer,
  Page,
  Text,
  Font,
  View,
  Document,
  StyleSheet,
} from "@react-pdf/renderer";

import font from "../fonts/Roboto/Roboto-Regular.ttf";

Font.register({ family: "Roboto", src: font });

// Create styles
const styles = StyleSheet.create({
  page: {
    padding: 32,
    fontFamily: "Roboto",
    fontSize: 12,
  },
  section: {
    padding: 8,
  },
  title: {
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
  },
  header: {
    marginLeft: 16,
    paddingBottom: 16,
  },
  subheader: {
    marginLeft: 32,
    paddingBottom: 16,
  },
  signatures: {
    flexDirection: "row",
  },
  signature: {
    flexGrow: 1,
  },
});

const Subheader = ({ section, children }) => {
  if (section) {
    return (
      <Text style={styles.subheader}>
        {section} {children}
      </Text>
    );
  } else {
    return <Text style={styles.subheader}>{children}</Text>;
  }
};

const Header = ({ section, children }) => {
  if (section) {
    return (
      <Text style={styles.header}>
        {section} {children}
      </Text>
    );
  } else {
    return <Text style={styles.header}>{children}</Text>;
  }
};

// Create Document Component
export default ({
  signedDate,
  seller,
  sellerSign,
  createdDate,
  buyer,
  buyerSign,
  buyerCompany,
  scope = [],
  price,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.title}>
        <Text>SCHEDULE B: </Text>

        <Text>PROJECT ORDER </Text>
      </View>
      <View style={styles.section}>
        <Text>
          This Project Order is entered into as of the date last signed below
          (the “Project Order Effective Date”) by and between
          {seller} (“Freelancer”), and {buyerCompany} (“Service Client”),
          pursuant to and in accordance with the Master Creative Services
          Agreement signed between Freelancer and Client with an effective date
          of
          {buyerSign} (the “Agreement”). This Project Order defines the
          Services, Schedule, Fees and other specifications for work to be
          completed by Freelancer for Client.
        </Text>
      </View>
      <View style={styles.section}>
        <Header section="1">
          TERM OF PROJECT ORDER. The period of performance for this Project
          Order shall begin on {buyerSign} and shall be completed no later than{" "}
          {buyerSign} (the “Expiration Date”). Any extension beyond the
          Expiration Date must be in writing and signed by both parties.{" "}
        </Header>

        <Header section="2">
          {" "}
          SCOPE OF SERVICES AND DELIVERABLES. Freelancer shall provide the
          following Services and Deliverables to Client:{" "}
        </Header>

        {scope.map((values, index) => {
          let { package: pck, template, due, price, addons } = values;
          return (
            <>
              <Subheader section={`2.${index}`}>{template.name}</Subheader>
              {template.longDesc && (
                <>
                  <Subheader section={`2.${index}.1`}>
                    Description: {template.longDesc}
                  </Subheader>
                  <Subheader section={`2.${index}.2`}>
                    {template.bundle[pck].desciption || ""}
                  </Subheader>
                </>
              )}
              {(addons || []).map(({ feature, count }, subindex) => {
                return (
                  <Subheader section={`2.${index}.2.${subindex}`}>
                    {feature} {count > 1 ? `: ${count} ` : null}
                  </Subheader>
                );
              })}
              <Subheader section={`2.${index}.3`}>
                Revisions: Allows for initial design and three rounds of
                revisions
              </Subheader>
              <Subheader section={`2.${index}.4`}>
                Fee for Service and Deliverable: ${price}.
              </Subheader>
              <Subheader section={`2.${index}.5`}>
                Due Date for Deliverables: {new Date(due).toDateString()}
              </Subheader>
            </>
          );
        })}

        <Header section="3"> PAYMENTS </Header>

        <Subheader section="3.1">
          Compensation. For all of the services described above, Client shall
          pay Freelancer a fixed price total of {price}. Any additional
          deliverables outside of the scope of this document will incur
          additional fees. Payments will be made according to the following
          schedule.
        </Subheader>

        <Subheader>
          50% of the total due upon signing this Project Order (or upon
          commencement of the Services under this Project Order, whichever
          occurs first); the remaining 50% of each deliverable will be due on
          each deliverables due date.
        </Subheader>

        <Subheader section="3.2">
          Expense Reimbursement: In addition to the compensation in 3.1, Client
          shall reimburse Freelancer for the actual cost of: (a) incidental and
          out-of- pocket expenses including but not limited to costs for
          postage, shipping, overnight courier, service bureaus, typesetting,
          blueprints, models, presentation materials, photocopies, computer
          expenses, parking fees and tolls, and taxis at cost plus Freelancer’s
          standard markup of ten percent (10%); and (b) travel expenses
          including transportation, meals, and lodging, incurred by Freelancer
          with Client’s prior approval. Expenses will be reimbursed to the
          extent incurred by Freelancer in the performance of the services
          hereunder, provided that total reimbursable expenses shall not exceed
          $5,000.00 without the prior written approval of Client.
        </Subheader>

        <Subheader section="3.3">
          Expense Reimbursement Documentation: Expenses submitted by Freelancer
          for reimbursement by Client shall be substantiated by proper and
          adequate documentation and receipts. Such expenses must be reasonable
          in amount, related to and in furtherance of the contract.
        </Subheader>

        <Subheader section="3.4">
          Advance for Expenses. Freelancer may request that you provide an
          advance to Freelancer to cover anticipated expenses, and will provide
          the Expense Reimbursement Documentation required above. Any such funds
          advanced shall be used only for the purpose for which the funds were
          requested, unless otherwise agreed in writing. Alternatively,
          Freelancer may arrange that bills in amounts over $500.00 for expenses
          incurred on Client’s behalf be sent or forwarded directly to Client
          for payment, and will notify Client in writing before doing so.
        </Subheader>

        <Subheader section="3.5">
          Terms of Payment: Unless a mutually agreed Advance for Expenses,
          receipts for authorized expenses and invoices for services must be
          presented to Client before payment can be made. Client shall pay
          Freelancer in accordance with Section 3.4 of the Agreement. Payment
          will be sent either by check or, or, upon Freelancer’s request and
          payment of the wire transfer or Federal Express fees, by wire transfer
          or Federal Express.
        </Subheader>

        <Header section="4">
          {" "}
          CLIENT MANAGER AND DIRECT REPORT. Freelancer shall report to and
          receive direction from {buyer} at Client.
          {seller} shall be Client‘s primary contact at Freelancer.
        </Header>

        <Header section="5">
          {" "}
          TIMELINE. Client agrees to deliver feedback and/or approval within
          three (3) business days after Freelancer requests a review. If any
          milestone and/or delivery is delayed due to late feedback or
          approvals, overages will incur. In the event that Client notifies
          Freelancer in writing of delays in its project timeline, Freelancer
          may slow or stop work accordingly, and Freelancer will initiate
          scheduling a conference with Client to coordinate and determine new
          due dates and timelines, which shall be memorialized in writing. If
          Client fails to respond or places the project on hold for longer than
          ten (10) business days without prior notice and agreement, a new
          Project Order will be required to continue.
        </Header>

        <Header section="6">ADDITIONAL TERMS </Header>

        <Subheader section="6.1">
          Client Content: Unless otherwise specified, Client agrees to provide
          all copywriting, written materials and photography relating to and
          required to produce the deliverables listed in 2.0.
        </Subheader>

        <Header section="7">
          {" "}
          AUTHORIZATION. This Project Order is authorized and incorporated fully
          by reference in the above-identified Agreement through the signatures
          below. By their signatures below, each party agrees to be bound by
          these terms and conditions.{" "}
        </Header>
      </View>
      <View style={styles.signatures}>
        <View style={styles.signature}>
          <Header>FREELANCER: </Header>
          <Subheader>
            Name: {sellerSign ? sellerSign : "___________________________"}{" "}
          </Subheader>
          <Subheader>
            Signed: {sellerSign ? sellerSign : "___________________________"}
          </Subheader>
          <Subheader>
            Date: {createdDate ? createdDate : "___________________________"}{" "}
          </Subheader>
        </View>
        <View style={styles.signature}>
          <Header>CLIENT: </Header>
          <Subheader>
            Name: {buyerSign ? buyerSign : "___________________________"}{" "}
          </Subheader>
          <Subheader>
            Signed: {buyerSign ? buyerSign : "___________________________"}{" "}
          </Subheader>
          <Subheader>
            Date: {signedDate ? signedDate : "___________________________"}{" "}
          </Subheader>
        </View>
      </View>
    </Page>
  </Document>
);
