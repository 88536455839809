import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import EventCard from '../cards/EventCard'
import { Row, Col } from "antd"
import Info from '../elements/Info'
import { PlusCircleOutlined } from "@ant-design/icons";
import { PrimaryIcon } from "../customComponents"

export default ({cloudUtils, profile, cities, dates, setEventHosting}) => {
    let navigate = useNavigate()

    const [events, setEvents] = useState([])

    const servicesButton = {
        width: "100%",
        borderRadius: 3,
        border: "2px dashed",
        padding: "48px 0",
        margin: "16px 0 12px"
    };

    const servicesTitle = {
        "font-size": "24px",
        "line-height": "2.6rem",
    };
    

    useEffect(() => {
        if (profile && profile.events){
            setEvents([...new Map(profile.events.map(item => [item.id, item])).values()])
        } else {
            cloudUtils.getAllDocuments("event", setEvents)
        }
    }, [profile])

    let filteredEvents = events.filter(({city, date, cancelled}) => 
        !cancelled && (!cities || !cities.length || cities.includes(city)) && (!dates || !dates.length || (date[0] > dates[0] && date[1] < dates[1]))
    )

    return (
        <Row gutter={[0, 8]} style={{ marginTop: 24 }} >
            {filteredEvents.length ? filteredEvents.map(({id}) => 
                (<EventCard
                    onClick={() => navigate(`/events/${id}`)}
                    eventId={id}
                    cloudUtils={cloudUtils}
                    key={'event' + id}
                    profile={profile}
                />)
            ) : ( <Info messages={[
                "Use this page to track events you are attending, hosting or have saved for later.",
                // "Once you've RSVP'd and paid for an event, your QR code will grant you access at the door.",
                "You must add your billing account to host a paid event. This is required for money to be transferred to into your account.",
                "You can create an event using the Host Event button below. You can choose to have your event shared with the SoCo community on our events board, or keep them private and share the link yourself. As a host, you can scan users QR codes on entry.",
                ]}/>
            )}
            {setEventHosting && (
                <div
                style={servicesButton}
                onClick={() => setEventHosting(true)}
                >
                <Row gutter={[0, 8]} style={{cursor: 'pointer'}}>
                    <Col span={24}>
                    <PrimaryIcon
                        style={{ fontSize: 24 }}
                        icon={<PlusCircleOutlined/>}
                    />
                    </Col>
                    <Col span={24} style={servicesTitle}>
                        Host Event
                    </Col>
                </Row>
                </div>
            )}
        </Row>
    )
}
























{/* <h3 style={{color, margin: '16px 16px 0'}}>My Events</h3> */}

{/* <br/>
<Divider displayText="Host Event"/> */}
// return <h3 style={{color, margin: '16px 16px 0'}}>Host Event</h3>

// let events = []
// Object.values(eventBooks).forEach((data) => {
//   if (!data.cancelled){
//     events.push((
//       <EventCard
//         eventId={data.id}
//         cloudUtils={cloudUtils}
//         onClick={() => navigate(`/events/${data.id}`)}
//         key={'event' + data.id}
//       />
//       ))
//     }
//   })
// setEvents(events)