import { Rate, Modal, Form } from "antd";
import { DangerButton } from "../customComponents";
import { useEffect, useState } from "react";
import MultimediaSupport from "../elements/MultiMedia";
import TemplateForm from "../forms/TemplateForm";
import { useNavigate, useParams } from "react-router-dom";

import {
  CalendarOutlined,
  DeleteOutlined,
  SaveOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { GhostButton, DangerGhostButton } from "../customComponents";
import Layout from "../elements/Layout";
const { confirm } = Modal;

const CarouselItem = ({ template, contract, cloudUtils, admin, goBack }) => {
  let [media, setMedia] = useState("");
  let [mediaName, setMediaName] = useState("");

  const deleteItem = async () => {
    await cloudUtils.deleteContract(contract.id, template);
    //TODO: better component refresh needed
    window.location.reload();
  };

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure delete this review?",
      icon: <ExclamationCircleOutlined />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteItem();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  useEffect(() => {
    cloudUtils.listUrls(template + "/" + contract.id + "/final").then((res) => {
      if (res.items.length) {
        const item = res.items.pop();
        return item.getDownloadURL().then((url) => {
          setMediaName(item.name);
          setMedia(url);
        });
      }
    });
  }, [contract.id, template]);

  let { desc, customer } = contract;

  return (
    <div align="center" style={{ overflow: "visible", height: "fit-content" }}>
      {customer && customer.rating && (
        <Rate allowHalf disabled defaultValue={customer.rating} />
      )}
      {customer && customer.comment && <p>{customer.comment}</p>}
      {customer && customer.name && (customer.comment || customer.rating) && (
        <p style={{ fontStyle: "italic" }}>{"   - " + customer.name}</p>
      )}

      {media && (
        <>
          <MultimediaSupport media={media} name={mediaName} />
          <p align="center">{desc}</p>
        </>
      )}

      {admin && (
        <DangerButton onClick={showDeleteConfirm} icon={<DeleteOutlined />}>
          Remove Project
        </DangerButton>
      )}
    </div>
  );
};

export default ({ cloudUtils, user }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { profileId, templateId } = useParams();
  const goBack = () => navigate(-1);
  const [template, setTemplate] = useState({
    price: {
      min: 1,
      max: 2,
      value: 0,
      currency: "CAD",
    },
    time: {
      min: 1,
      max: 2,
      value: 1,
      unit: "Days",
    },
  });
  const [profile, setProfile] = useState({});

  // Object.values(total).reduce((a, b) => a + b, 0) + Object.values(counts).reduce((a, b) => a + b, 0)

  const [form] = Form.useForm();
  const [form2] = Form.useForm();

  useEffect(() => {
    cloudUtils.getDocument("template", templateId, setTemplate);
    cloudUtils.getDocument("profile", profileId, setProfile);
  }, [profileId, templateId]);

  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
    if (admin >= 0) {
      setLoading(false);
    }
  }, [profile]);

  const deleteItem = async () => {
    await cloudUtils.deleteTemplate(templateId);
    goBack();
  };

  let adminButtons = admin
    ? [
        <GhostButton icon={<SaveOutlined />} onClick={form2.submit} />,
        <DangerGhostButton
          icon={<DeleteOutlined />}
          onClick={showDeleteConfirm}
        />,
      ]
    : [<GhostButton icon={<CalendarOutlined />} onClick={form.submit} />];

  // let previewButton = admin
  //   ? <Button onClick={() => setCustomerPreview(!customerPreview)} shape='round'>Customer Preview:&nbsp;{customerPreview? <span style={{color: 'green'}}>ON</span>:<span style={{color: accentColor}}>OFF</span>}</Button>
  //   : null

  function showDeleteConfirm() {
    confirm({
      title: "Are you sure delete this service?",
      icon: <ExclamationCircleOutlined />,
      content: "Deleting service: " + template.name,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteItem();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  return (
    <Layout
      title={template.name}
      onBack={goBack}
      extra={adminButtons}
      theme={profile.theme}
    >
      <TemplateForm
        cloudUtils={cloudUtils}
        setLoading={setLoading}
        form={form2}
        templateId={templateId}
        old={true}
      />
    </Layout>
  );
};
