import { Select, Form } from "antd";
import { useState } from "react";
import BigTextInput from "../inputs/BigTextInput";
import Submit from "../inputs/Submit";
import { v4 as uuidv4 } from "uuid";
import { SubHeader } from "../customComponents";

export default ({ cloudUtils, setLoading, profile, profileId }) => {
  const [complete, setComplete] = useState(false)
  const [type, setType] = useState('featureRequest')

  const checkoutFn = (values) => {
    setLoading(true);
    
    cloudUtils.updateDocument("feedback", uuidv4(), {
      note: values.note,
      type,
      profile: profileId
    }).then(() => {
      setComplete(true)
      setLoading(false);
    });
  };

  const requestTypes = [
    {value: 'featureRequest', label: 'Request a feature?'},
    {value: 'bugReport', label: 'Report an issue?'},
    {value: 'supportRequest', label: 'Have a question?'}
  ]
  return complete ? (
    <SubHeader>Thanks for your inquiry! We'll get back to you as soon as we can!</SubHeader>
  ) : (
    <Form onFinish={checkoutFn} align="left" 
    layout="vertical"
    
    >
      <Select onSelect={setType} value={type}>
        {requestTypes.map(({value, label}) => (
          <Select.Option value={value}>{label}</Select.Option>
        ))}
      </Select><br/><br/>
      <BigTextInput label="note" required />
      <Submit big text="Send" />
    </Form>
  );
};
