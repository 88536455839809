import {
  PhoneOutlined,
  MessageOutlined,
  MessageFilled,
  UploadOutlined,
} from "@ant-design/icons";
import Info from "../elements/Info";
import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Layout from "../elements/Layout";
import Pending from "./StatusPages/Pending";
import Active from "./StatusPages/Active";
import Discovery from "./StatusPages/Discovery";
import Sign from "./StatusPages/Sign";
import MeetCard from "../elements/MeetCard";
import FileCard from "../elements/FileCard";
import { Divider, RegularButton } from "../customComponents";

const StatusPage = ({ message }) => {
  return Array.isArray(message) ? (
    <Info messages={message} />
  ) : (
    <Info messages={[message]} />
  );
};

export default ({ cloudUtils, user }) => {
  let { profileId, projectId } = useParams();
  const navigate = useNavigate();
  let [templates, setTemplates] = useState([]);
  let [showFiles, setShowFiles] = useState(false);
  let [meet, setMeet] = useState(false);
  let [contracts, setContracts] = useState([]);

  const [profile, setProfile] = useState({
    name: "Loading...",
    handle: "Loading...",
    pic: "https://firebasestorage.googleapis.com/v0/b/soco-aa9d5.appspot.com/o/user_empty.png?alt=media&token=f717cfa0-4de8-49db-9e40-0abe4cc94ce7",
    tags: [],
    theme: "dark",
  });

  const [project, setProject] = useState({
    name: "Loading...",
    email: "Loading...",
  });

  let [admin, setAdmin] = useState();
  useEffect(() => {
    setAdmin(user && user.uid === profile.authId);
  }, [profile]);

  const [pic, setPic] = useState(profile.pic);

  useEffect(() => {
    cloudUtils.getDocument("project", projectId, setProject);
    cloudUtils.getDocumentsBy("contract", "project", projectId, setContracts);
    cloudUtils.getDocument("profile", profileId, setProfile);
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, []);

  const ChatIcon = () => {
    const toConversation = () =>
      navigate({
        pathname: "/" + profileId + "/projects/" + projectId + "/conversation",
        state: { ...profile },
      });

    if (
      (admin && project?.chatNotif?.admin) ||
      (!admin && project?.chatNotif?.customer)
    ) {
      return <MessageFilled style={{color: "#F05E45"}} onClick={toConversation} />;
    }
    return <MessageOutlined onClick={toConversation} />;
  };

  useEffect(() => {
    if (profile.pic) {
      cloudUtils.getDownloadURL(profile.pic).then((src) => setPic(src));
    }
  }, [profile]);

  const goBack = admin ? () => navigate(`/${profileId}/projects`) : null;

  let viewer = admin ? "worker" : "customer";

  let stateMap = {
    worker: {
      LEAD: (
        <Pending
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          cloudUtils={cloudUtils}
        />
      ),
      DISCOVERY: (
        <Discovery
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          templates={templates}
          admin
          cloudUtils={cloudUtils}
        />
      ),
      DENIED: <StatusPage message="This offer has been declined." />,
      SIGNATURE: (
        <Sign
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          admin
          cloudUtils={cloudUtils}
        />
      ),
      DEPOSIT: (
        <Sign
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          pay
          admin
          cloudUtils={cloudUtils}
        />
      ),
      ACTIVE: (
        <Active
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          admin
          cloudUtils={cloudUtils}
        />
      ),
      COMPLETE: (
        <Active
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          admin
          complete
          cloudUtils={cloudUtils}
        />
      ),
    },
    customer: {
      LEAD: (
        <StatusPage
          message={[
            `Revisit this page to participate in your discovery call once ${profile.displayName} has accepted your booking`,
            `You can chat with ${profile.displayName} by clicking the icon on the top right.`,
          ]}
        />
      ),
      DISCOVERY: (
        <Discovery
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          templates={templates}
          cloudUtils={cloudUtils}
        />
      ),
      DENIED: (
        <StatusPage
          message={`This offer has been declined. Visit soco.market/${profile.handle} to start another order with ${profile.displayName}.`}
        />
      ),
      SIGNATURE: (
        <Sign
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          cloudUtils={cloudUtils}
        />
      ),
      DEPOSIT: (
        <Sign
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          pay
          cloudUtils={cloudUtils}
        />
      ),
      ACTIVE: (
        <Active
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          cloudUtils={cloudUtils}
        />
      ),
      COMPLETE: (
        <Active
          profile={{ id: profileId, ...profile }}
          project={{ id: projectId, ...project }}
          contracts={contracts}
          complete
          cloudUtils={cloudUtils}
        />
      ),
    },
  };

  return (
    project &&
    profile && (
      <Layout
        title={admin ? project.name : profile.displayName}
        onBack={goBack}
        avatar={{ src: pic }}
        extra={[
          <PhoneOutlined onClick={() => setMeet(true)} />,
          <ChatIcon />,
          <UploadOutlined onClick={() => setShowFiles(true)} />,
        ]}
        theme={profile.theme}
      >
        {project ? stateMap[viewer][project.status] : null}

        <Divider />
        <div
          style={{
            marginTop: 32,
            display: "flex",
            "justify-content": "space-around",
            "flex-wrap": "wrap",
            "row-gap": 24,
          }}
        >
          <FileCard
            showFiles={showFiles}
            setShowFiles={setShowFiles}
            cloudUtils={cloudUtils}
            projectId={profileId}
          />

          <RegularButton
            onClick={() => (window.location = `${projectId}/conversation`)}
            style={{ width: "30%", minWidth: "9rem" }}
          >
            Chat
          </RegularButton>

          <MeetCard
            admin={admin}
            buyer={project}
            seller={profile}
            meetModal={meet}
            showMeetModal={setMeet}
          />
        </div>
      </Layout>
    )
  );
};
