import React from "react";
import { PlusOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import TextInput from "../../inputs/TextInput";
import { RegularButton, DangerGhostButton } from "../../customComponents";
import DateInput from "../../inputs/DateInput";
import { pricePositive } from "../../utils/validators";

function Schedule({ admin, template }) {
  return (
    <Form.List name={`${template}.schedule`}>
      {(fields, { add, remove }) => {
        return (
          <div>
            {fields.map((field, index) => (
              <div
                key={field.key}
                style={{
                  marginBottom: 16,
                  display: "flex",
                  "align-items": "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    "flex-direction": "column",
                    "flex-grow": "1",
                  }}
                >
                  <DateInput
                    tight
                    required={true}
                    label={[index, "date"]}
                    paddingRight={16}
                    disabled={!admin}
                  />
                  <TextInput
                    required={true}
                    tight
                    validators={[pricePositive(false, 0, 0)]}
                    label={[index, "price"]}
                    disabled={!admin}
                    style={{ paddingRight: 16 }}
                    price
                  />
                </div>
                {fields.length > 1 && (
                  <DangerGhostButton
                    onClick={() => remove(field.name)}
                    icon={<CloseCircleOutlined />}
                  />
                )}
              </div>
            ))}
            <Form.Item>
              <center>
                <RegularButton onClick={add} icon={<PlusOutlined />}>
                  Add Payment
                </RegularButton>
              </center>
            </Form.Item>
          </div>
        );
      }}
    </Form.List>
  );
}

export default Schedule;
