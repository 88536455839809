import { uploadBytes } from "firebase/storage";

class firestoreUtils {
  constructor(firestore, storage, functions) {
    this.firestore = firestore;
    this.storage = storage;
    this.functions = functions;
  }

  getFunction = (name) => this.functions.httpsCallable(name);

  getChildCollectionRef = (type, id, childCollection) =>
    this.firestore.collection(type).doc(id).collection(childCollection);

  getDocument = (type, id, setter) =>
    this.firestore
      .collection(type)
      .doc(id)
      .get()
      .then((doc) => setter(doc.data()))
      .catch(() => setter(false));

  getDocumentB = (type, id) => this.firestore.collection(type).doc(id).get();

  getAllDocuments = (type, setter) =>
    this.firestore
      .collection(type)
      .get()
      .then((snap) =>
        setter(
          snap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        )
      )
      .catch(() => setter(false));

  getDocumentsBy = (type, by, id, setter) =>
    this.firestore
      .collection(type)
      .where(by, "==", id)
      .get()
      .then((snap) =>
        setter(
          snap.docs.map((doc) => {
            return { id: doc.id, ...doc.data() };
          })
        )
      )
      .catch(() => setter(false));

  uploadFile = async (name, file) => {
    await this.storage.ref(name).put(file[0].originFileObj);
  };

  uploadFileWithoutOriginFileObj = async (name, file) => {
    await this.storage.ref(name).put(file);
  };

  uploadBlob = async (name, file) => {
    await uploadBytes(this.storage.ref(name), file);
  };

  deleteFile = async (name) => {
    await this.storage.ref(name).delete();
  };

  updateDocument = async (table, id, values, type = "CREATE") => {
    let ref = this.firestore.collection(table).doc(id);
    if (type === "MERGE") {
      await ref.set(values, { merge: true });
    } else if (type === "UPDATE") {
      await ref.update(values);
    } else {
      await ref.set(values, { merge: false });
    }
  };

  login = async (id, name, email) => {
    let ref = this.firestore.collection("profile").doc(id);
    let doc = await ref.get();
    if (doc.exists) {
      if (doc.data().handle) {
        return doc.data().handle;
      } else {
        return id;
      }
    } else {
      await ref.set({ name, email, transactional: true });
      return false;
    }
  };

  deleteContract = async (contractId, template) => {
    // let items = await this.storage.ref(`${template}/${contractId}/final`).listAll().then((res) => res.items)
    let items = (
      await this.storage
        .ref(`${template}/${contractId}/final`)
        .listAll()
        .then((res) => res.items)
    ).map((item) => item.delete());
    await Promise.all(items);
    await this.firestore.collection("contract").doc(contractId).delete();
  };

  deleteTemplate = async (templateId) =>
    this.firestore.collection("template").doc(templateId).delete();

  deleteProfile = async (profileId, user) =>
    this.firestore
      .collection("profile")
      .doc(profileId)
      .delete()
      .then(() => {
        this.firestore
          .collection("template")
          .where("profile", "==", profileId)
          .get()
          .then((querySnapshot) => {
            var batch = this.firestore.batch();

            querySnapshot.forEach(function (doc) {
              batch.delete(doc.ref);
            });
            return batch.commit();
          })
          .then(() => {
            user.delete();
          });
      });

  getDownloadURL = (url) => this.storage.ref().child(url).getDownloadURL();

  getDownloadURLByRef = async (itemRef) =>
    itemRef.getDownloadURL().then((res) => {
      return res;
    });

  getMetaDataByRef = async (itemRef) =>
    itemRef.getMetadata().then((metadata) => {
      return metadata.name;
    });

  getFirstDownloadURL = (url) =>
    this.storage
      .ref(url)
      .listAll()
      .then((res) => {
        if (res.items.length) {
          return res.items[0].getDownloadURL().then((res) => res);
        }
      });

  listUrls = (url) => this.storage.ref(url).listAll();

  getPortfolioFiles = async (url, setter) => {
    const prefixes = await this.listUrls(url).then((res) => {
      return res.prefixes;
    });

    let filesUploaded = prefixes.map(async (prefix) => {
      let refList = await this.listUrls(prefix.child("final").fullPath).then(
        (res) => {
          return res.items;
        }
      );
      for (const item of refList) {
        const fileData = {
          status: "done",
        };
        fileData.url = await this.getDownloadURLByRef(item);
        fileData.name = await this.getMetaDataByRef(item);
        return fileData;
      }
      return false;
    });

    filesUploaded = (await Promise.all(filesUploaded)).filter((file) => file);
    setter(filesUploaded);
  };

  getUploadedFiles = async (url, setter) => {
    const refList = await this.listUrls(url).then((res) => {
      return res.items;
    });
    const filesUploaded = [];

    for (const item of refList) {
      const fileData = {
        status: "done",
      };
      fileData.url = await this.getDownloadURLByRef(item);
      fileData.name = await this.getMetaDataByRef(item);

      filesUploaded.push(fileData);
    }

    setter(filesUploaded);
  };

  getUploadedSync = async (url) => {
    const refList = await this.listUrls(url).then((res) => {
      return res.items.slice(0, 3);
    });
    const filesUploaded = [];

    for (const item of refList) {
      const fileData = {
        status: "done",
      };
      fileData.url = await this.getDownloadURLByRef(item);
      fileData.name = await this.getMetaDataByRef(item);

      filesUploaded.push(fileData);
    }

    return filesUploaded;
  };
}

export default firestoreUtils;
