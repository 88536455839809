import { Row, Col, Avatar, notification, Modal } from "antd";
import Carousel from "re-carousel";
import IndicatorDots from "../elements/IndictorDots";
import AdminBar from "./AdminBar";
import { QRCode } from "react-qrcode-logo";
import {
  GhostButton,
  Liquid,
  Blocks,
  Flat,
  SubHeader,
  Text,
  Selectable,
  BigHeader,
  BigButton,
  BorderDiv,
  Divider,
} from "../customComponents";
import React, { useEffect } from "react";
import Div100vh from "react-div-100vh";
import {
  ShopOutlined,
  GithubOutlined,
  InstagramOutlined,
  CreditCardOutlined,
  GlobalOutlined,
  LinkedinOutlined,
  FacebookOutlined,
  YoutubeOutlined,
  TwitterOutlined,
  PhoneOutlined,
  MailOutlined,
  DollarCircleOutlined,
  LinkOutlined,
} from "@ant-design/icons";
import { FaTiktok, FaSpotify, FaShareSquare, FaQrcode } from "react-icons/fa";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logo from "../icons/logo.png";
import { SocialLink } from "../customComponents";
import LinkContent from "./LinkContent";
import { useTheme } from "styled-components";
import MultiMedia from "./MultiMedia";

export default ({ profile, pic, admin, cloudUtils }) => {
  let { profileId } = useParams();
  const theme = useTheme();

  let [included, setIncluded] = useState({});

  const [templates, setTemplates] = useState([]);
  const [qr, setQr] = useState(false);

  const [portfolio, setPortfolio] = useState([]);

  useEffect(() => {
    cloudUtils.getUploadedFiles(profileId, setPortfolio);
  }, []);

  let onboard = cloudUtils.getFunction("onboard");
  const toOnboard = () => {
    onboard({
      email: profile.email,
      id: profileId,
    }).then(({ data }) => {
      window.location.href = data.url;
    });
  };

  const shareProfile = () => {
    if (navigator.share) {
      navigator
        .share({
          title: `${profile.displayName}'s SoCo Profile`,
          url: `soco.market/${profileId}`,
        })
        .then(() => {
          console.log("Thanks for sharing!");
        })
        .catch(console.error);
    } else {
      navigator.clipboard.writeText(`soco.market/${profileId}`);
      notification.info({
        message: "Profile copied to clipboard",
        placement: "bottomRight",
      });
    }
  };

  useEffect(() => {
    cloudUtils.getDocumentsBy("template", "profile", profileId, setTemplates);
  }, [profileId]);

  const flip = "flip";
  const navigate = useNavigate();

  const iconMap = {
    share: <SocialLink icon={<FaShareSquare style={{ fontSize: 28 }} />} />,
    qr: (
      <SocialLink
        style={{ marginTop: -6 }}
        icon={<FaQrcode style={{ fontSize: 30 }} />}
      />
    ),
    stripe: (
      <SocialLink icon={<CreditCardOutlined style={{ fontSize: 32 }} />} />
    ),
    instagram: (
      <SocialLink icon={<InstagramOutlined style={{ fontSize: 32 }} />} />
    ),
    website: <SocialLink icon={<GlobalOutlined style={{ fontSize: 32 }} />} />,
    linkedin: (
      <SocialLink icon={<LinkedinOutlined style={{ fontSize: 32 }} />} />
    ),
    facebook: (
      <SocialLink icon={<FacebookOutlined style={{ fontSize: 32 }} />} />
    ),
    spotify: <SocialLink icon={<FaSpotify style={{ fontSize: 32 }} />} />,
    tiktok: <SocialLink icon={<FaTiktok style={{ fontSize: 32 }} />} />,
    github: <SocialLink icon={<GithubOutlined style={{ fontSize: 32 }} />} />,
    youtube: <SocialLink icon={<YoutubeOutlined style={{ fontSize: 32 }} />} />,
    twitter: <SocialLink icon={<TwitterOutlined style={{ fontSize: 32 }} />} />,
    email: (
      <SocialLink
        icon={<MailOutlined style={{ fontSize: 30, paddingTop: 2 }} />}
      />
    ),
    payment: (
      <SocialLink icon={<DollarCircleOutlined style={{ fontSize: 32 }} />} />
    ),
    phone: <SocialLink icon={<PhoneOutlined style={{ fontSize: 32 }} />} />,
    view: <ShopOutlined />,
    link: (
      <SocialLink icon={<LinkOutlined />} style={{ padding: "3px 0 9px" }} />
    ),
  };

  let spans = {
    0: 24,
    1: 24,
    2: 12,
    3: 8,
    4: 6,
    5: 4,
    6: 4,
    7: 6,
    8: 6,
  };

  let socialItems = [];

  let iconItems = { ...(profile.socials || {}) };
  iconItems.share = "qr";
  if (profile.phone) {
    iconItems.phone = profile.phone;
  }
  if (profile.shareEmail) {
    iconItems.email = profile.email;
  }
  if (profile.directPayment && profile.cardPayment && !profile.onboarding) {
    iconItems.payment = profile.directPayment;
  }
  socialItems = Object.entries(iconItems)
    .map(([site, link]) => {
      if (site == "phone") {
        return (
          <Col
            span={spans[Object.values(iconItems).filter((x) => x).length]}
            align="center"
          >
            <GhostButton
              href={`tel:+1${link}`}
              target="_blank"
              icon={iconMap[site]}
            />
          </Col>
        );
      }
      if (site == "email") {
        return (
          <Col
            span={spans[Object.values(iconItems).filter((x) => x).length]}
            align="center"
          >
            <GhostButton
              href={`mailto:${profile.email}`}
              target="_blank"
              icon={iconMap[site]}
            />
          </Col>
        );
      }
      if (site == "payment") {
        return (
          <Col
            span={spans[Object.values(iconItems).filter((x) => x).length]}
            align="center"
          >
            <GhostButton href={`/${profileId}/tip`} icon={iconMap[site]} />
          </Col>
        );
      }
      if (site == "share") {
        return (
          <Col
            span={spans[Object.values(iconItems).filter((x) => x).length]}
            align="center"
          >
            <GhostButton
              onClick={() => setQr(true)}
              target="_blank"
              icon={iconMap[link]}
            />
          </Col>
        );
      }

      return link ? (
        <Col
          span={spans[Object.values(iconItems).filter((x) => x).length]}
          align="center"
        >
          <GhostButton
            href={`//${link}`}
            target="_blank"
            icon={iconMap[site]}
          />
        </Col>
      ) : null;
    })
    .filter((x) => x);

  const mainCard = {
    position: "relative",
    paddingTop: admin || profile.onboarding ? 28 : 16,
    zIndex: 1,
    "justify-content": "space-evenly",
  };

  const main2 = {
    position: "relative",
    maxWidth: 768,
    width: "100%",
  };

  const main3 = {
    overflow: "scroll",
    height: "100%",
    color: theme.fontColor,
    display: "flex",
    "flex-direction": "column",
    "justify-content": "flex-start",
  };

  const mainInfo2 = {
    "font-size": 36,
    "overflow-wrap": "break-word",
    "line-height": 48,
    "text-align": "start",
    padding: "0px 16px 16px",
    display: "flex",
    overflow: "hidden",
    "flex-direction": "row",
    "align-items": "center",
    "justify-content": "center",
  };

  const avatarIcon = {
    "flex-grow": 1,
    height: "auto",
    "max-width": 112,
  };

  const jobInfo = {
    "font-size": "24px",
    "justify-content": "center",
  };

  const locInfo = {
    "font-size": "16px",
    "justify-content": "center",
  };

  const links = {
    position: "relative",
    maxWidth: 768,
  };

  const socials = {
    padding: "16px 16px 0",
  };

  let topButton = null;
  if (admin) {
    topButton = (
      <>
        <br />

        <AdminBar current="preview" />
        <br />
        <div style={{ position: "relative", zIndex: 100, maxWidth: 768 }}>
          <Divider displayText="Book for your Client" />
        </div>
      </>
    );
  } else {
    topButton = <br />;
  }

  const designs = {
    liquid: <Liquid />,
    block: <Blocks />,
    flat: <Flat />,
  };
  return (
    <Div100vh>
      <div
        style={{
          height: "fit-content",
          minHeight: "100%",
          position: "relative",
          padding: "0 16px 64px",
          "overflow-y": "scroll",
        }}
      >
        {topButton}

        {designs[theme.design]}

        <Modal
          title="Profile Code"
          centered
          open={qr}
          okText="Share"
          onOk={() => {
            setQr(false);
            shareProfile();
          }}
          onCancel={() => setQr(false)}
        >
          <div
            style={{
              padding: "0 16px",
              display: "flex",
              "justify-content": "center",
              "flex-direction": "column",
              "align-items": "center",
            }}
          >
            <QRCode
              value={`https://soco.market/${profileId}`}
              qrStyle="dots"
              bgColor="transparent"
              fgColor={"#1c1e1f"}
              size={250}
            />
            <SubHeader style={{ color: "#1c1e1f" }}>{`soco.market/${profileId}  `}</SubHeader>
          </div>
        </Modal>

        <div style={mainCard} className={flip}>
          <div style={main2}>
            <div style={main3}>
              <div style={mainInfo2}>
                <Avatar src={pic} style={avatarIcon} />
                <div
                  style={{
                    paddingLeft: "1rem",
                    width: "min-content",
                    "max-width": "66%",
                    // maxWidth: "calc(100% - 128px)",
                  }}
                >
                  {profile.displayName}
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  "flex-direction": "column",
                  "justify-content": "flex-start",
                }}
              >
                {profile.job ? (
                  <SubHeader style={{ marginBottom: 0 }}>
                    {profile.job}
                  </SubHeader>
                ) : null}

                {profile.location ? (
                  <div style={locInfo}>{profile.location}</div>
                ) : null}
              </div>
              <br />
              {socialItems.length ? (
                <>
                  <div style={socials}>
                    <Row style={{ justifyContent: "center" }} gutter={[8, 16]}>
                      {socialItems}
                    </Row>
                  </div>
                </>
              ) : null}
            </div>
          </div>

          {portfolio.length ? (
            <BorderDiv
              style={{
                "max-width": 768,
                margin: "32px 0",
                background: "transparent",
                height: 200,
                "border-radius": "5px",
                "min-width": "fit-content",
                "z-index": 1,
              }}
            >
              <Carousel widgets={[IndicatorDots]}>
                {portfolio.map(({ url, name }) => (
                  <MultiMedia name={name} media={url} />
                ))}
              </Carousel>
            </BorderDiv>
          ) : null}

          {(templates || []).length ? (
            templates
              .filter(({ show }) => show)
              .map(({ id, name, longDesc }) => (
                <Selectable
                  name={name}
                  longDesc={longDesc}
                  included={included}
                  setIncluded={setIncluded}
                  id={id}
                />
              ))
          ) : admin ? (
            <div
              style={{
                width: "100%",
                // borderRadius: 3,
                padding: "0 16px 16px",
                color: theme.fontColor,
                margin: "32px 0 12px",
              }}
            >
              <BigButton onClick={() => navigate("services")}>
                Add Services
              </BigButton>
              <br />
              <br />
              <Text>
                {" "}
                Create your first service and start getting hired! The services
                your clients select will be highlighted in your discovery call
                agenda.
              </Text>
              {/* <Text style={{ fontStyle: "italic" }}>
                {" "}
                All payments secured by Stripe TM
              </Text> */}
            </div>
          ) : null}

          {profile.links && profile.links.length ? (
            <div style={links}>
              {(profile.links || []).map(({ text, url }) => (
                <LinkContent title={text} link={url} />
              ))}
              <br />
            </div>
          ) : null}
          <br />
          <br />
          {Object.values(included).some((x) => x) && (
            <div
              style={{
                zIndex: 100,
                bottom: 0,
                left: 0,
                right: 0,
                position: "fixed",
                padding: "64px 16px",
                background: `linear-gradient(0deg, ${theme.background}, rgba(0,0,0,0) 100%)`,
              }}
            >
              <BigButton
                block
                style={{ padding: 16 }}
                onClick={() =>
                  navigate(`/${profileId}/discovery`, {
                    state: {
                      services: Object.entries(included)
                        .filter(([k, v]) => v)
                        .map(([k, v]) => k),
                    },
                  })
                }
              >
                Get in Touch
              </BigButton>
            </div>
          )}
        </div>

        <div
          onClick={() => (window.location.href = "//socialcontracts.ca")}
          style={{
            display: "flex",
            justifyContent: "space-between",
            "align-items": "flex-end",
            zIndex: 50,
            position: "absolute",
            bottom: 16,
            right: 16,
            left: 16,
          }}
        >
          <p style={{ zIndex: 50, color: theme.fontColor, margin: "5px 0px" }}>
            Hosted by SoCo
          </p>
          <img
            src={logo}
            style={{ maxWidth: 48, maxHeight: 48, zIndex: 50 }}
            alt="HomeIcon"
          />
        </div>
      </div>
    </Div100vh>
  );
};
