import React, { useState } from 'react'

interface PhoneProps {
    trigger:boolean
}

function Phone1Base() {
    return (
        <svg height="100%" preserveAspectRatio='1/1' viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Account Setup">
                <rect width="100%" height="852" rx="30" fill="white" />
                <g id="skeleton" className="animate-pulse">
                    <rect id="next" x="269" y="753" width="73" height="73" rx="36.5" fill="#D9D9D9" />
                    <rect id="text3" x="51" y="570" width="291" height="56" fill="#D9D9D9" />
                    <rect id="text2" x="51" y="470" width="291" height="56" fill="#D9D9D9" />
                    <rect id="text1" x="51" y="370" width="291" height="56" fill="#D9D9D9" />
                    <rect id="profile" x="113" y="102" width="168" height="168" rx="84" fill="#D9D9D9" />
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_61_275" x1="305.5" y1="753" x2="305.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>
    )
}

function Phone1Layer() {
    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 500)

    const animate = trigger 
    ? "translate-y-0 opacity-100"
    : "-translate-y-10 opacity-0"

    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute transition-all top-0 ${animate}`}>
            <g id="nextActive">
                <rect id="checkmark" x="269" y="753" width="73" height="73" rx="36.5" fill="url(#paint0_linear_61_275)" />
                <g id="CheckOutlined">
                    <path id="Vector" d="M320.286 778.5H317.79C317.44 778.5 317.107 778.661 316.893 778.936L302.168 797.589L295.107 788.643C295.001 788.507 294.864 788.398 294.709 788.322C294.554 788.247 294.384 788.207 294.211 788.207H291.715C291.475 788.207 291.343 788.482 291.49 788.668L301.272 801.061C301.729 801.639 302.607 801.639 303.068 801.061L320.511 778.957C320.657 778.775 320.525 778.5 320.286 778.5Z" fill="#D9D9D9" />
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_61_275" x1="305.5" y1="753" x2="305.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>

    )
}

function Phone2Base() {
    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Service Setup">
                <rect width="100%" height="852" rx="30" fill="white" />
                <g id="skeleton" className="animate-pulse">
                    <rect id="book" x="51" y="753" width="291" height="73" rx="36.5" fill="#D9D9D9" />
                    <rect id="service3" x="51" y="470" width="291" height="101" rx="20" fill="#D9D9D9" />
                    <rect id="service2" x="51" y="325" width="291" height="101" rx="20" fill="#D9D9D9" />
                    <rect id="service1" x="51" y="180" width="291" height="101" rx="20" fill="#D9D9D9" />
                    <rect id="profile" x="51" y="63" width="73" height="73" rx="36.5" fill="#D9D9D9" />
                    <text id="Book Discovery" fill="black" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Ubuntu" font-size="20" letter-spacing="0em"><tspan x="99.2344" y="797">Book Discovery</tspan></text>
                    <rect id="countBubble" x="269.5" y="776.5" width="29" height="29" rx="14.5" stroke="black" stroke-dasharray="2 2" />

                </g>
            </g>
        </svg>
    )
}

function Phone2Layer1() {
    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 500)

    const animate = trigger 
    ? "translate-y-0 opacity-100"
    : "-translate-y-10 opacity-0"
    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute transition-all top-0 ${animate}`}>
            <g id="active1">
                <rect id="checkmark" x="51" y="753" width="291" height="73" rx="36.5" fill="url(#paint0_linear_61_287)" />
                <text id="Book Discovery" fill="#D9D9D9" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Ubuntu" font-size="20" letter-spacing="0em"><tspan x="99.2344" y="797">Book Discovery</tspan></text>
                <rect id="countBubble" x="269.5" y="776.5" width="29" height="29" rx="14.5" stroke="black" stroke-dasharray="2 2" />
                <rect id="service1Active" x="51" y="180" width="291" height="101" rx="20" fill="url(#paint1_linear_61_287)" />

            </g>
            <defs>
                <linearGradient id="paint0_linear_61_287" x1="196.5" y1="753" x2="196.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
                <linearGradient id="paint1_linear_61_287" x1="196.5" y1="180" x2="196.5" y2="281" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>

    )
}

function Phone2Layer2() {
    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 500)

    const animate = trigger 
    ? "opacity-100"
    : "opacity-0"

    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute transition-all top-0 ${animate}`}>

            <text id="count1" fill="white" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Ubuntu" font-size="20" letter-spacing="0em"><tspan x="279.352" y="798.273">1</tspan></text>

        </svg>
    )
}

function Phone2Layer3() {
    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 1500)

    const animate = trigger 
    ? "translate-y-0 opacity-100"
    : "-translate-y-10 opacity-0"

    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute transition-all top-0 ${animate}`}>
            <g id="active2">
                <rect id="service2Active" x="51" y="325" width="291" height="101" rx="20" fill="url(#paint0_linear_61_287)" />
                <text id="count1" fill="white" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Ubuntu" font-size="20" letter-spacing="0em"><tspan x="278.445" y="798.273">2</tspan></text>
            </g>
            <defs>
                <linearGradient id="paint0_linear_61_287" x1="196.5" y1="753" x2="196.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
                <linearGradient id="paint1_linear_61_287" x1="196.5" y1="180" x2="196.5" y2="281" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>

    )
}

function Phone3Base() {
    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Contract Setup">
                <rect width="100%" height="852" rx="30" fill="white" />
                <g id="skeleton" className="animate-pulse">
                    <rect id="book" x="51" y="753" width="291" height="73" rx="36.5" fill="#D9D9D9" />
                    <text id="Sign" fill="black" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Ubuntu" font-size="20" letter-spacing="0em"><tspan x="177.225" y="797">Sign</tspan></text>
                    <rect id="profile" x="269" y="63" width="73" height="73" rx="36.5" fill="#D9D9D9" />
                    <rect id="profile_2" x="52" y="63" width="73" height="73" rx="36.5" fill="#D9D9D9" />
                    <g id="plain soco 1" clip-path="url(#clip0_62_280)">
                        <g id="Layer 2">
                            <g id="Layer 1">
                                <path id="Vector" d="M162.085 212.546V310.335L135.106 338.083H61.5375C60.2746 338.085 59.0236 337.837 57.8563 337.354C56.6889 336.871 55.628 336.162 54.7343 335.268C53.8406 334.374 53.1315 333.312 52.6478 332.143C52.164 330.973 51.915 329.72 51.915 328.454V231.674L79.1135 202.917H152.477C155.025 202.917 157.469 203.931 159.271 205.737C161.073 207.543 162.085 209.992 162.085 212.546Z" fill="#D9D9D9" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_2" d="M79.1135 202.917L51.915 231.674" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_3" d="M162.085 310.334L135.106 338.083" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                            </g>
                        </g>
                    </g>
                    <g id="plain soco 2" clip-path="url(#clip1_62_280)">
                        <g id="Layer 2_2">
                            <g id="Layer 1_2">
                                <path id="Vector_4" d="M162.085 487.546V585.335L135.106 613.083H61.5375C60.2746 613.085 59.0236 612.837 57.8563 612.354C56.6889 611.871 55.628 611.162 54.7343 610.268C53.8406 609.374 53.1315 608.312 52.6478 607.143C52.164 605.973 51.915 604.72 51.915 603.454V506.674L79.1135 477.917H152.477C155.025 477.917 157.469 478.931 159.271 480.737C161.073 482.543 162.085 484.992 162.085 487.546Z" fill="#E0E4E4" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_5" d="M79.1135 477.917L51.915 506.674" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_6" d="M162.085 585.334L135.106 613.083" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                            </g>
                        </g>
                    </g>
                    <g id="PlusCircleTwoTone" clip-path="url(#clip2_62_280)">
                        <path id="Vector_7" d="M107 394C98.1643 394 91 401.164 91 410C91 418.836 98.1643 426 107 426C115.836 426 123 418.836 123 410C123 401.164 115.836 394 107 394ZM107 423.286C99.6643 423.286 93.7143 417.336 93.7143 410C93.7143 402.664 99.6643 396.714 107 396.714C114.336 396.714 120.286 402.664 120.286 410C120.286 417.336 114.336 423.286 107 423.286Z" fill="#333333" />
                        <path id="Vector_8" d="M107 396.714C99.6639 396.714 93.7139 402.664 93.7139 410C93.7139 417.336 99.6639 423.286 107 423.286C114.335 423.286 120.285 417.336 120.285 410C120.285 402.664 114.335 396.714 107 396.714ZM113.857 410.857C113.857 411.014 113.728 411.143 113.571 411.143H108.142V416.571C108.142 416.729 108.014 416.857 107.857 416.857H106.142C105.985 416.857 105.857 416.729 105.857 416.571V411.143H100.428C100.271 411.143 100.142 411.014 100.142 410.857V409.143C100.142 408.986 100.271 408.857 100.428 408.857H105.857V403.429C105.857 403.271 105.985 403.143 106.142 403.143H107.857C108.014 403.143 108.142 403.271 108.142 403.429V408.857H113.571C113.728 408.857 113.857 408.986 113.857 409.143V410.857Z" fill="#E6E6E6" />
                        <path id="Vector_9" d="M113.571 408.857H108.143V403.429C108.143 403.271 108.014 403.143 107.857 403.143H106.143C105.985 403.143 105.857 403.271 105.857 403.429V408.857H100.428C100.271 408.857 100.143 408.986 100.143 409.143V410.857C100.143 411.014 100.271 411.143 100.428 411.143H105.857V416.571C105.857 416.729 105.985 416.857 106.143 416.857H107.857C108.014 416.857 108.143 416.729 108.143 416.571V411.143H113.571C113.728 411.143 113.857 411.014 113.857 410.857V409.143C113.857 408.986 113.728 408.857 113.571 408.857Z" fill="#333333" />
                    </g>
                    <g id="right arrow">
                        <path id="Vector_10" d="M209.369 94.4218L197.469 84.1593C197.025 83.7781 196.375 84.1218 196.375 84.7375V105.262C196.375 105.878 197.025 106.222 197.469 105.841L209.369 95.5781C209.709 95.2843 209.709 94.7156 209.369 94.4218Z" fill="url(#paint0_linear_62_280)" />
                    </g>
                    <g id="left arrow">
                        <path id="Vector_11" d="M195.531 94.1593L183.631 104.422C183.291 104.716 183.291 105.281 183.631 105.578L195.531 115.841C195.975 116.222 196.625 115.878 196.625 115.262V94.7375C196.625 94.1218 195.975 93.7781 195.531 94.1593Z" fill="#D9D9D9" />
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_62_280" x1="203" y1="83.9995" x2="203" y2="106" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
                <clipPath id="clip0_62_280">
                    <rect width="112" height="137" fill="white" transform="translate(51 202)" />
                </clipPath>
                <clipPath id="clip1_62_280">
                    <rect width="112" height="137" fill="white" transform="translate(51 477)" />
                </clipPath>
                <clipPath id="clip2_62_280">
                    <rect width="32" height="32" fill="white" transform="translate(91 394)" />
                </clipPath>
            </defs>
        </svg>
    )
}

function Phone3Layer1() {

    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 1500)

    const animate = trigger 
    ? "opacity-100"
    : "opacity-0"


    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg" className={` transition-opacity absolute top-0 ${animate}`}>
            <g id="signActive">
                <rect id="colorbook" x="51" y="753" width="291" height="73" rx="36.5" fill="url(#paint0_linear_62_280)" />
                <text id="Sign" fill="#D9D9D9" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Ubuntu" font-size="20" letter-spacing="0em"><tspan x="177.225" y="797">Sign</tspan></text>
            </g>
            <g id="fill1">
                <rect id="text1" x="182" y="203" width="160" height="20" rx="6" fill="#D9D9D9" />
                <rect id="text2" x="182" y="232" width="160" height="20" rx="6" fill="#D9D9D9" />
                <rect id="text3" x="182" y="261" width="160" height="20" rx="6" fill="#D9D9D9" />
                <rect id="text4" x="183" y="292" width="123" height="20" rx="6" fill="#D9D9D9" />
            </g>
            <g id="fill2">
                <rect id="text1_2" x="182" y="478" width="160" height="20" rx="6" fill="#D9D9D9" />
                <rect id="text2_2" x="182" y="507" width="160" height="20" rx="6" fill="#D9D9D9" />
                <rect id="text3_2" x="182" y="536" width="160" height="20" rx="6" fill="#D9D9D9" />
                <rect id="text4_2" x="183" y="567" width="123" height="20" rx="6" fill="#D9D9D9" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_62_280" x1="196.5" y1="753" x2="196.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>
    )
}


function Phone4Base() {
    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Payment Setup">
                <rect width="100%" height="852" rx="30" fill="white" />
                <g id="skeleton" className="animate-pulse">
                    <rect id="book" x="199.5" y="753.5" width="142" height="72" rx="36" stroke="black" stroke-dasharray="2 2" />
                    <rect id="check1" x="286.5" y="590.5" width="55" height="23" rx="11.5" stroke="black" stroke-dasharray="2 2" />
                    <rect id="check1_2" x="284.5" y="315.5" width="55" height="23" rx="11.5" stroke="black" stroke-dasharray="2 2" />
                    <g id="plain soco 1" clip-path="url(#clip0_62_323)">
                        <g id="Layer 2">
                            <g id="Layer 1">
                                <path id="Vector" d="M162.085 212.546V310.335L135.106 338.083H61.5375C60.2746 338.085 59.0236 337.837 57.8563 337.354C56.6889 336.871 55.628 336.162 54.7343 335.268C53.8406 334.374 53.1315 333.312 52.6478 332.143C52.164 330.973 51.915 329.72 51.915 328.454V231.674L79.1135 202.917H152.477C155.025 202.917 157.469 203.931 159.271 205.737C161.073 207.543 162.085 209.992 162.085 212.546Z" fill="#D9D9D9" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_2" d="M79.1135 202.917L51.915 231.674" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_3" d="M162.085 310.334L135.106 338.083" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                            </g>
                        </g>
                    </g>
                    <g id="plain soco 2" clip-path="url(#clip1_62_323)">
                        <g id="Layer 2_2">
                            <g id="Layer 1_2">
                                <path id="Vector_4" d="M162.085 487.546V585.335L135.106 613.083H61.5375C60.2746 613.085 59.0236 612.837 57.8563 612.354C56.6889 611.871 55.628 611.162 54.7343 610.268C53.8406 609.374 53.1315 608.312 52.6478 607.143C52.164 605.973 51.915 604.72 51.915 603.454V506.674L79.1135 477.917H152.477C155.025 477.917 157.469 478.931 159.271 480.737C161.073 482.543 162.085 484.992 162.085 487.546Z" fill="#E0E4E4" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_5" d="M79.1135 477.917L51.915 506.674" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                                <path id="Vector_6" d="M162.085 585.334L135.106 613.083" stroke="#1C1E1F" stroke-opacity="0.5" stroke-width="5" stroke-miterlimit="10" />
                            </g>
                        </g>
                    </g>
                    <rect id="profile" x="269" y="63" width="73" height="73" rx="36.5" fill="#D9D9D9" />
                    <rect id="profile_2" x="51" y="63" width="73" height="73" rx="36.5" fill="#D9D9D9" />
                    <g id="PlusCircleTwoTone" clip-path="url(#clip2_62_323)">
                        <path id="Vector_7" d="M107 394C98.1643 394 91 401.164 91 410C91 418.836 98.1643 426 107 426C115.836 426 123 418.836 123 410C123 401.164 115.836 394 107 394ZM107 423.286C99.6643 423.286 93.7143 417.336 93.7143 410C93.7143 402.664 99.6643 396.714 107 396.714C114.336 396.714 120.286 402.664 120.286 410C120.286 417.336 114.336 423.286 107 423.286Z" fill="#333333" />
                        <path id="Vector_8" d="M107 396.714C99.6639 396.714 93.7139 402.664 93.7139 410C93.7139 417.336 99.6639 423.286 107 423.286C114.335 423.286 120.285 417.336 120.285 410C120.285 402.664 114.335 396.714 107 396.714ZM113.857 410.857C113.857 411.014 113.728 411.143 113.571 411.143H108.142V416.571C108.142 416.729 108.014 416.857 107.857 416.857H106.142C105.985 416.857 105.857 416.729 105.857 416.571V411.143H100.428C100.271 411.143 100.142 411.014 100.142 410.857V409.143C100.142 408.986 100.271 408.857 100.428 408.857H105.857V403.429C105.857 403.271 105.985 403.143 106.142 403.143H107.857C108.014 403.143 108.142 403.271 108.142 403.429V408.857H113.571C113.728 408.857 113.857 408.986 113.857 409.143V410.857Z" fill="#E6E6E6" />
                        <path id="Vector_9" d="M113.571 408.857H108.143V403.429C108.143 403.271 108.014 403.143 107.857 403.143H106.143C105.985 403.143 105.857 403.271 105.857 403.429V408.857H100.428C100.271 408.857 100.143 408.986 100.143 409.143V410.857C100.143 411.014 100.271 411.143 100.428 411.143H105.857V416.571C105.857 416.729 105.985 416.857 106.143 416.857H107.857C108.014 416.857 108.143 416.729 108.143 416.571V411.143H113.571C113.728 411.143 113.857 411.014 113.857 410.857V409.143C113.857 408.986 113.728 408.857 113.571 408.857Z" fill="#333333" />
                    </g>
                    <g id="right arrow">
                        <path id="Vector_10" d="M209.369 94.4218L197.469 84.1593C197.025 83.7781 196.375 84.1218 196.375 84.7375V105.262C196.375 105.878 197.025 106.222 197.469 105.841L209.369 95.5781C209.709 95.2843 209.709 94.7156 209.369 94.4218Z" fill="#D9D9D9" />
                    </g>
                    <g id="left arrow">
                        <path id="Vector_11" d="M195.531 94.1593L183.631 104.422C183.291 104.716 183.291 105.281 183.631 105.578L195.531 115.841C195.975 116.222 196.625 115.878 196.625 115.262V94.7375C196.625 94.1218 195.975 93.7781 195.531 94.1593Z" fill="url(#paint0_linear_62_323)" />
                    </g>
                </g>
            </g>
            <defs>
                <linearGradient id="paint0_linear_62_323" x1="190.001" y1="93.9995" x2="190.001" y2="116" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
                <clipPath id="clip0_62_323">
                    <rect width="112" height="137" fill="white" transform="translate(51 202)" />
                </clipPath>
                <clipPath id="clip1_62_323">
                    <rect width="112" height="137" fill="white" transform="translate(51 477)" />
                </clipPath>
                <clipPath id="clip2_62_323">
                    <rect width="32" height="32" fill="white" transform="translate(91 394)" />
                </clipPath>
            </defs>
        </svg>
    )
}

function Phone4Layer1() {
    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 500)

    const animate = trigger 
    ? "translate-y-0 opacity-100"
    : "-translate-y-10 opacity-0"

    return (
        <svg preserveAspectRatio='1/1' width="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg" className={`absolute transition-all top-0 ${animate}`}>
            <g id="confirm1">
                <rect id="colorbook" x="199" y="753" width="143" height="73" rx="36.5" fill="url(#paint0_linear_62_323)" />
                <text id="dollar1" fill="#D9D9D9" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Quicksand" font-size="20" letter-spacing="0em"><tspan x="265.385" y="797">$</tspan></text>
                <rect id="check1" x="284" y="315" width="56" height="24" rx="12" fill="url(#paint1_linear_62_323)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_62_323" x1="270.5" y1="753" x2="270.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
                <linearGradient id="paint1_linear_62_323" x1="312" y1="315" x2="312" y2="339" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>
    )
}

function Phone4Layer2() {

    const [trigger, setTrigger] = useState(false);
    setTimeout(() => {
        setTrigger(true)
    }, 1500)

    const animate = trigger 
    ? "opacity-100"
    : "opacity-0"


    return (
        <svg preserveAspectRatio='1/1' height="100%" viewBox="0 0 393 852" fill="none" xmlns="http://www.w3.org/2000/svg"  className={`absolute transition-all top-0 ${animate}`}>
            <g id="confirm2">
                <rect id="colorbook" x="199" y="753" width="143" height="73" rx="36.5" fill="url(#paint0_linear_62_323)" />
                <text id="dollar2" fill="#D9D9D9" xmlSpace="preserve" style={{ "whiteSpace": "pre" }} font-family="Quicksand" font-size="20" letter-spacing="0em"><tspan x="254.301" y="797">$  $</tspan></text>
                <rect id="check2" x="286" y="590" width="56" height="24" rx="12" fill="url(#paint1_linear_62_323)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_62_323" x1="270.5" y1="753" x2="270.5" y2="826" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
                <linearGradient id="paint1_linear_62_323" x1="314" y1="590" x2="314" y2="614" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#EE9C65" />
                    <stop offset="1" stop-color="#F05E45" />
                </linearGradient>
            </defs>
        </svg>
    )
}



export {
    Phone1Base,
    Phone1Layer,
    Phone2Base,
    Phone2Layer1,
    Phone2Layer2,
    Phone2Layer3,
    Phone3Base,
    Phone3Layer1,
    Phone4Base,
    Phone4Layer1,
    Phone4Layer2,
}