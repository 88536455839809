import { useRef, useState } from "react";
import { SiMinutemailer } from "react-icons/si";
import React from "react";
import { v4 as uuidv4 } from "uuid";

function Contact({ cloudUtils }: any) {
  console.log(cloudUtils.updateDocument);
  const [joinTrigger, setJoinTrigger] = useState(false);

  const Name = useRef<any>();
  const Email = useRef<any>();

  interface personDetails {
    name: string;
    email: string;
  }
  const [details, setDetails] = useState<personDetails>();

  const sendInvite = () => {
    if (
      Name.current &&
      Email.current &&
      Name.current.value != "" &&
      Email.current.value != ""
    ) {
      const details = { name: Name.current.value, email: Email.current.value };
      cloudUtils.updateDocument("waitlist", uuidv4(), details).then(() => {
        setJoinTrigger(true);
        setDetails(details);
      });
    }
  };

  function FormComponent() {
    return (
      <>
        <h1 className=" text-2xl mt-10 text-center text-SocoWhite p-0">
          Join our Waitlist
        </h1>
        <label htmlFor="name" className="mb-2 text-start">
          Name
        </label>
        <input
          type="text"
          name="fullname"
          id="name"
          className=" rounded-full h-10 p-5 text-center text-SocoBlack"
          ref={Name}
        />

        <label htmlFor="email" className=" mt-5 mb-2 text-start ">
          Email
        </label>
        <input
          type="email"
          name="email"
          id="email"
          className=" rounded-full h-10 p-5 text-center text-SocoBlack"
          ref={Email}
        />

        <div className="p-5">
          <button
            className="h-10 w-24 rounded-full text-SocoBlack bg-SocoWhite font-semibold z-20"
            onClick={() => sendInvite()}
          >
            Join
          </button>
        </div>
      </>
    );
  }

  function DisplayComponent() {
    return (
      <>
        <h1 className=" text-4xl font-semibold mt-12 mb-5 text-center text-SocoBlack">
          Welcome
        </h1>
        <h2 className="mb-2 text-center text-xl text-SocoBlack">
          {details?.name}
        </h2>
        <p className="mb-2 px-2 md:w-1/3 xl:w-1/3 text-center text-SocoBlack">
          We'll be sending you an invite link to your email within 24 hours:{" "}
        </p>
        <span className="text-SocoBlack font-bold">{details?.email}</span>
        <SiMinutemailer className="fill-SocoWhite my-5 px-2 h-16 w-16" />
      </>
    );
  }

  const ComponentSwitch = joinTrigger ? (
    <>
      <DisplayComponent />
    </>
  ) : (
    <>
      <FormComponent />
    </>
  );

  const backgroundSwitch = joinTrigger ? "bg-Soco" : "bg-SocoOutline";

  return (
    <div
      className="flex flex-col relative justify-center items-center w-full mb-40"
      id="contact"
    >
      {/* testimonials /reviews */}

      {/* contact form */}
      <div
        className={`p-10 relative flex flex-col items-center text-SocoWhite ${backgroundSwitch} bg-contain bg-no-repeat bg-center`}
      >
        {ComponentSwitch}
      </div>
    </div>
  );
}

export default Contact;
