export default ({ name, media }) => {
    const MediaType = {
        image: '\.apng|\.avif|\.gif|\.jpeg|\.jpg|\.jfif|\.pjpeg|\.pjp|\.png|\.svg|\.webp',
        video: '\.mp4|\.ogg',
        audio: '\.mp3|\.wav'
    }

    const InnerComp = () => {
        if (!media || media == ''){
        return (<>
            <p>Content is being loaded, if this takes too long the content might be corrupted</p>
        </>)
        }
        let imageMatch = new RegExp(`${MediaType.image}`,'gi')
        let videoMatch = new RegExp(`${MediaType.video}`,'gi')
        let audioMatch = new RegExp(`${MediaType.audio}`,'gi')

        if(media.match(imageMatch)) {
            return (
            <img
                alt={name}
                src={media}
                style={{maxHeight: 200}}
            />)
                
        } else if(media.match(videoMatch)) {
            return(<>
                <video style={{'width': '100%'}} controls>
                <source src={media} />
                Your browser does not support this video
                </video>
            </>)
        } else if(media.match(audioMatch)) {
            return(<>
                <audio controls>
                <source src={media} />
                Your browser does not support this audio
                </audio>
            </>)
        } else {
            return (<>
                <a href={media}>{name}</a>
            </>)
        }
    }
    return <div align="center" ><InnerComp /></div>
}